
export const createInitials = contactName => {
  const parts = contactName.split(" ");
  if (parts.length > 1) {
    return parts[0].charAt(0) + "" + parts[parts.length - 1].charAt(0);
  }
  return parts[0].charAt(0);
};
export const resolveShowMailNotification = (studyInvites, rejected) => {
  if (!studyInvites.length) {
    return false;
  }
  if (rejected.length !== studyInvites.length) {
    return true;
  }
  return false;
}