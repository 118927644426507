import { API } from 'aws-amplify';
export default class APIService {

    async get(_endpoint) {
        const apiName = "MetawebserviceCore"

        let myInit = {
            headers: {
                "Content-Type": "application/json;",
                'Accept': 'application/json'
            },
        };

        //if (conf) {
        //    myInit["response"] = { isAllResponse: true }
        //}

        try {
            const result = await API.get(apiName, _endpoint, myInit)

            //if (conf) {
            //    const resultAndHeaders = { data: result.data, pagination: result.headers.get("x-pagination") }
            //  return resultAndHeaders
            //}
            return result
        }

        catch (e) {
            return { ...undefined, status: e.response.status };
        }
    }
    async post(_endpoint, _body) {
        const apiName = "MetawebserviceCore"
        const myInit = {
            body: _body,
            headers: {
                "Content-Type": "application/json;",
                'Accept': 'application/json',
                /*'Accept-Encoding': 'gzip, deflate, br',*/
            }
        };
        try {
            const result = await API.post(apiName, _endpoint, myInit);
            return result
        }
        catch (e) {
            return { ...undefined, status: e.response.status };
        }
        /*
        return window.fetch(getBaseUrl() + _endpoint, {
            method: 'POST',
            headers: new Headers({
                "Content-Type": "application/json;",
                'Accept': 'application/json',
                'Accept-Encoding': 'gzip, deflate, br',
                'Authorization': 'Bearer ' + getIdToken()

            }),
            body: JSON.stringify(_body)
        })
            .then(res => {
                const contentType = res.headers.get('content-type');
                if (contentType && contentType.includes('application/json')) {
                    return res.json();
                }
                if (res.status === 200) {
                    return res;
                }
                if (res.status === 204) {
                    return undefined;
                }
                return Promise.reject('Oops, we haven\'t got JSON!');
            })
            .catch(error => { throw new Error(error) })
        */
    }
    async put(_endpoint, _body) {
        const apiName = "MetawebserviceCore"
        const myInit = {
            body: _body,
            headers: {
                "Content-Type": "application/json;",
                'Accept': 'application/json',
                /*'Accept-Encoding': 'gzip, deflate, br',*/
            }
        };
        try {
            const result = await API.put(apiName, _endpoint, myInit);
            return result
        }
        catch (e) {
            return { ...undefined, status: e.response.status };
        }

        /*
        return window.fetch(getBaseUrl() + _endpoint, {
            method: 'PUT',
            headers: new Headers({
                "Content-Type": "application/json;",
                'Accept': 'application/json',
                'Accept-Encoding': 'gzip, deflate, br',
                'Authorization': 'Bearer ' + getIdToken()
            }),
            body: JSON.stringify(_body)
        })
            .then(res => {
                const contentType = res.headers.get('content-type');
                if (contentType && contentType.includes('application/json')) {
                    return {
                        ...res.json(),
                        status: res.status
                    }
                }
                if (res.status === 204) {
                    return undefined;
                }
                if (res.status === 404) {
                    return res.status
                }
                return Promise.reject('Oops, we haven\'t got JSON!');
            })
            .catch(error => { throw new Error(error) })
        */
    }
    async delete(_endpoint, _body) {
        const apiName = "MetawebserviceCore"
        const myInit = {
            headers: {
                "Content-Type": "application/json;",
                'Accept': 'application/json',
                /*'Accept-Encoding': 'gzip, deflate, br',*/
            }
        };

        try {
            const result = await API.del(apiName, _endpoint, myInit);
            return result
        }
        catch (e) {
            return { ...undefined, status: e.response.status };
        }
        /*
        return window.fetch(getBaseUrl() + _endpoint, {
            method: 'DELETE',
            headers: new Headers({
                "Content-Type": "application/json;",
                'Accept': 'application/json',
                'Accept-Encoding': 'gzip, deflate, br',
                'Authorization': 'Bearer ' + getIdToken()
            }),
            body: JSON.stringify(_body)
        })
            .then(res => {
                const contentType = res.headers.get('content-type');
                if (contentType && contentType.includes('application/json')) {
                    return res.json();
                }
                if (res.status === 204) {
                    return undefined;
                }
                return Promise.reject('Oops, we haven\'t got JSON!');
            })
            .catch(error => { throw new Error(error) })
        */
    }
};
