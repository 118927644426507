import "./Navigation.scss";
import PropTypes from "prop-types";
import React from "react";
import { withStyles } from "@material-ui/core";
import classNames from "classnames";
import UserMenu from "./UserMenu";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as actions from "../actions/";
import EmailIcon from '@material-ui/icons/Email';
import Badge from "@material-ui/core/Badge";
import Tooltip from "@material-ui/core/Tooltip";
import PointingArrow from "./PointingArrow";
import AssignmentIcon from '@material-ui/icons/Assignment';
import AddIcon from '@material-ui/icons/Add';

const styles = theme => ({
  secure: {
    color: "#fff"
  },
  twofactorButton: {
    display: "inline-block",
    marginRight: "2rem",
    position: "relative",
    top: "0.3rem",
    cursor: "pointer",
    "&:after": {
      content: '""',
      position: "absolute",
      top: "0.3rem",
      right: "-1rem",
      height: "1rem",
      width: "1px",
      background: "#ffffff"
    }
  },
  emptyMail: {
    position: "relative",
    top: "0.2rem"
  },
  noClick: {
    cursor: "default"
  },
  btnOverWriteSignin: {
    height: "1.5rem",
    padding: "0 1.3rem",
    background: "#2196F3",
    color: "white",
    "&:hover": {
      background: "black"
    }
  },
  button: {
    margin: theme.spacing(1),
    color: "#fff",
    "&:hover": {
      cursor: "pointer",
      color: "#0273b5"
    }
  },
});

const canShowCount = (initials, showMailNotificationBadgde) => {
  if (initials) {
    return showMailNotificationBadgde;
  }
  return false;
};
const invitesCount = invites => {
  const pending =
    invites && invites.length
      ? invites.filter(item => !item.invitationRejectedDateTime)
      : [];
  return pending.length;
};
const showNavBar = (pathname, isAuthenticated, visible) => {
  if (isAuthenticated) {
    return false;
  }
  if (
    pathname === "/auth"
  ) {
    return true;
  }
  return !visible;
};

class Navigation extends React.PureComponent {
  state = {
    visible: true
  };

  handleScroll = () => {
    const { isAuthenticated } = this.props;
    if (isAuthenticated) {
      return;
    }
    const visible = window.pageYOffset < 100;

    this.setState({
      visible
    });
  };

  handleLogoClick = () => {
    const { pathname, actions } = this.props;
    actions.resetStudyDetail();
    if (pathname !== "/") {
      this.props.history.push("/");
    }
  };

  handleNewClick = () => {
    const { pathname, actions } = this.props;
    actions.resetStudyDetail();
    if (pathname !== "/") {
      this.props.history.push("/new");
    }
  };

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
  }
  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  render() {
    const { visible } = this.state;
    const {
      isAuthenticated,
      signout,
      classes,
      actions,
      initials,
      emailConfirmed,
      studyInvites,
      showMailNotificationBadgde,
      pathname
    } = this.props;

    const showBagde = canShowCount(initials, showMailNotificationBadgde);

    return (
      <nav
        className={classNames(
          "nav",
          "nav__transparent",
          { "nav--white": showNavBar(pathname, isAuthenticated, visible) }
        )}
      >
        <img
          alt="logo"
          src="assets/ME-TA_logo_NEG.png"
          className="nav__logo"
          onClick={this.handleLogoClick}
        />

        {initials && (

          <div className="nav__user-section">

            {!showBagde && initials !== "?" && emailConfirmed && (
              <div
                className="nav__studies"
                onClick={this.handleNewClick}
              >
                <div className={classes.twofactorButton}>
                  <Tooltip
                    title="New project"
                    aria-label="New project"
                    classes={{ tooltip: classes.lightTooltip }}
                  >
                    <AddIcon />
                  </Tooltip>
                </div>
              </div>

            )}



            {!showBagde && initials !== "?" && emailConfirmed && (
              <div
                className="nav__studies"
                onClick={this.handleLogoClick}
              >
                <div className={classes.twofactorButton}>
                  <Tooltip
                    title="My studies"
                    aria-label="My studies"
                    classes={{ tooltip: classes.lightTooltip }}
                  >
                    <AssignmentIcon />
                  </Tooltip>
                </div>
              </div>

            )}


            {!showBagde && initials !== "?" && emailConfirmed && (
              <div
                className="nav__inbox"
                onClick={actions.userProfileToggleInbox}
              >
                <div className={classes.emptyMail}>
                  <Tooltip
                    title="Notifications"
                    aria-label="Notifications"
                    classes={{ tooltip: classes.lightTooltip }}
                  >
                    <EmailIcon />
                  </Tooltip>
                </div>
              </div>
            )}

            {showBagde && initials !== "?" && emailConfirmed && (
              <div
                className="nav__inbox"
                onClick={actions.userProfileToggleInbox}
              >
                <Badge
                  color="error"
                  badgeContent={invitesCount(studyInvites)}
                  id="inviteCount"
                >
                  <Tooltip
                    title="Notifications"
                    aria-label="Notifications"
                    classes={{ tooltip: classes.lightTooltip }}
                  >
                    <EmailIcon
                    />
                  </Tooltip>
                </Badge>
              </div>
            )}
            {(!initials || initials === "" || initials === "?") &&
              <PointingArrow />
            }
            {initials &&
              <UserMenu actions={actions} emailConfirmed={emailConfirmed} initials={initials} signout={signout} />
            }
          </div>
        )}
      </nav>
    );
  }
}

Navigation.propTypes = {
  classes: PropTypes.object.isRequired,
  initials: PropTypes.string,
  showMailNotificationBadgde: PropTypes.bool,
  emailConfirmed: PropTypes.bool,
  studyInvites: PropTypes.array,
  signout: PropTypes.func,
  isAuthenticated: PropTypes.bool,
  pathname: PropTypes.string
};

function mapStateToProps(state) {
  return {
    initials: state.userProfile?.initials,
    showMailNotificationBadgde: state.userProfile?.showMailNotificationBadgde,
    emailConfirmed: state.userProfile?.emailConfirmed,
    studyInvites: state.userProfile?.studyInvites
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Navigation));