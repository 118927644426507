export const UTILITY_ACTIONS_RECEIVED = 'UTILITY_ACTIONS_RECEIVED';
export const UTILITY_ACTIONS_STARTED = 'UTILITY_ACTIONS_STARTED';
export const UTILITY_ACTIONS_ENDED = 'UTILITY_ACTIONS_ENDED';

export const utilityActionsReceived = actions => ({
    type: UTILITY_ACTIONS_RECEIVED,
    payload: { actions }
});

export const utilityActionsStarted = () => ({
    type: UTILITY_ACTIONS_STARTED,
    payload: {}
});

export const utilityActionsEnded = () => ({
    type: UTILITY_ACTIONS_ENDED,
    payload: {}
})