import * as actions from "../actions";
import uniqueId from "lodash/uniqueId";
const DEFAULT_STATE = {
  toasts: []
};


export const root = (state = DEFAULT_STATE, action) => {
  switch (action.type) {

    case actions.TOASTS_CLOSE: {
      const { id } = action.payload;
      return {
        ...state,
        toasts: state.toasts.length === 1 ? [] : state.toasts.filter(item => item.id !== id)
      };
    }

    case actions.UPLOAD_INITIATED: {
      const { id, studyId, type } = action.payload;
      const item = {
        id: id,
        studyId: studyId,
        type: type,
        state: "progress",
        variant: "info"
      };
      return {
        ...state,
        toasts:
          state.toasts.length === 0
            ? [...state.toasts, item]
            : state.toasts.concat(item)
      };
    }

    case actions.UPLOAD_SUCCESS: {
      const { id } = action.payload;
      if (state.toasts.length) {
        return {
          ...state,
          toasts: state.toasts.map(item => {
            let m = { ...item };
            if (m.id === id) {
              m.state = "finished";
              m.variant = "success";
            }
            return m;
          })
        };
      }
      return state;
    }

    case actions.UPLOAD_PROGRESS: {
      const { id, progress } = action.payload;
      if (state.toasts.length) {

        return {
          ...state,
          toasts: state.toasts.map(item => {
            let m = { ...item };
            if (m.id === id) {
              m.progress = progress;
            }
            return m;
          })
        };
      }

      return state;
    }

    case actions.API_ERROR: {
      return {
        ...state,
        toasts: state.toasts.concat(
          {
            id: uniqueId('_error_'),
            type: 'error',
            state: "finished",
            variant: "error"
          })
      }
    }

    case actions.INITIATE_MESSAGE: {
      const { id, studyId, message } = action.payload;

      const item = {
        id: id,
        studyId: studyId,
        message: message,
        state: "initiate",
        variant: "info"
      };
      return {
        ...state,
        toasts:
          state.toasts.length === 0
            ? [...state.toasts, item]
            : state.toasts.concat(item)
      };
    }

    case actions.INITIATE_AUTH_MESSAGE: {
      const { id, message, variant } = action.payload;

      const item = {
        id: id,
        message: message,
        state: "initiateAuthError",
        variant: variant
      };

      return {
        ...state,
        toasts:
          state.toasts.length === 0
            ? [...state.toasts, item]
            : state.toasts.concat(item)
      };
    }


    case actions.UPDATE_MESSAGE: {
      const { id, message } = action.payload;
      if (state.toasts.length) {

        return {
          ...state,
          toasts: state.toasts.map(item => {
            let m = { ...item };
            if (m.id === id) {
              m.message = message;
              m.state = "update";
            }
            return m;
          })
        };
      }

      return state;
    }

    case actions.FINAL_MESSAGE: {
      const { id, message, variant } = action.payload;
      if (state.toasts.length) {

        return {
          ...state,
          toasts: state.toasts.map(item => {
            let m = { ...item };
            if (m.id === id) {
              m.message = message;
              m.state = "final";
              m.variant = variant;
            }
            return m;
          })
        };
      }
      return state;
    }

    case actions.DELETE_SUCCESS: {
      const { id } = action.payload;
      return {
        ...state,
        toasts: state.toasts.concat({ id: uniqueId(id), type: 'delete', state: "finished" })
      };
    }
    case actions.SET_SASJOB_REFRESH_REQUIRED: {
      const { id } = action.payload;
      return {
        ...state,
        toasts: state.toasts.concat({ id: uniqueId(id), type: 'refresh', state: "finished" })
      };
    }

    default:
      return state;
  }
};
