import * as actions from "../actions";
import { getStudyFileStatusByState } from "../utils/studyFileHelpers";
import { formatDateTime } from "../utils/date";
import { resolveTableSort } from '../utils/dataSorting';
import { viewModes } from "../constants";

export const DEFAULT_STATE = {
  loading: true,
  open: false,
  studyFileId: undefined,
  studyId: undefined,
  viewMode: undefined,
  cnt: 0,
  allSelected: false,
  files: []
};

export const root = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case actions.FILE_VERSIONS_RESET: {
      return DEFAULT_STATE;
    }

    case actions.FILE_VERSIONS_OPEN_MODAL: {
      const { studyId, studyFileId, viewMode } = action.payload;

      return {
        ...state,
        open: true,
        studyFileId,
        studyId,
        viewMode,
        tableSort: ['created', 'asc']
      };
    }

    case actions.FILE_VERSIONS_TABLE_SORT: {
      return {
        ...state,
        tableSort: resolveTableSort(state.tableSort, action.payload.prop)
      }
    }
    case actions.FILE_VERSIONS_FILE_TOGGLED: {
      const { id } = action.payload;
      let cnt = 0;
      // move to helper function
      const transformedFiles = state.files.map(item => {
        let base = { ...item };
        if (item.id === id) {
          if (item.checkBox === 1) {
            base.checkBox = 0;
          } else {
            base.checkBox = 1;
          }
        }
        if (base.checkBox === 1) {
          cnt++;
        }
        return base;
      });
      // on route change reset selectedDate to new Date
      return {
        ...state,
        files: transformedFiles,
        cnt: cnt,
        allSelected: cnt === transformedFiles.length
      };
    }

    case actions.FILE_VERSIONS_ALL_TOGGLED: {
      const resolveSelection = () => {
        if (state.allSelected) {
          return false;
        }
        if (state.cnt === 0) {
          return true;
        }
        return true;
      };

      const transformedFiles = state.files.map(item => {
        let base = { ...item, checkBox: resolveSelection() ? 1 : 0 };
        return base;
      });

      return {
        ...state,
        files: transformedFiles,
        cnt: resolveSelection() ? transformedFiles.length : 0,
        allSelected: resolveSelection()
      };
    }
    case actions.FILE_VERSIONS_RECEIVED: {
      // make sure we dont render the wrong table, due to long response time
      const { studyFileId, files, viewMode } = action.payload;

      if (studyFileId !== state.studyFileId) {
        return state;
      }

      if (viewMode !== viewModes.SASJOBS) {
        // maybee id should be a concat of version and studyFileId
        const transformedStudyFileVersions = files.map(item => {
          return {
            id: item.studyFileId,
            fileName: item.fileName,
            folder: item.folder,
            displayDate: formatDateTime(item.uploadDateTime),
            created: item.uploadDateTime,
            version: item.version,
            checkBox: 0,
            download: 0,
            delete: 0,
            s3BucketName: item.s3BucketName,
            s3Key: item.s3Key,
            status: getStudyFileStatusByState(item)
          };
        });
        return {
          ...state,
          loading: false,
          files: transformedStudyFileVersions,
          tableSort: ['created', 'asc']
        };
      }
      else {
        // maybee id should be a concat of version and studyFileId
        const transformedSasFileVersions = files.map(item => {
          return {
            id: item.sasJobId,
            createddate: formatDateTime(item.sasJobCreatedDate),
            version: item.version,
            checkBox: 0,
            error: "-",
            warning: "-",
            status: ""
          };
        });
        return {
          ...state,
          loading: false,
          files: transformedSasFileVersions,
          tableSort: ['created', 'asc']
        };
      }

    }

    default:
      return state;
  }
};
