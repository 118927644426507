import './Bottombar.scss';
import React from 'react';
//import { version } from "../../package.json";

var pjson = require('../../package.json');
function Bottombar(props) {
  return (
    <div className="bottom-bar">
      <p>© 2020, ME-TA Aps. Metawebservice {pjson.version}. All rights reserved.</p>
    </div>
  );
}

export default Bottombar;