
export const STUDY_INVITE_FETCH_DATA = 'STUDY_INVITE_FETCH_DATA';
export const STUDY_INVITE_UPDATE_INPUT = 'STUDY_INVITE_UPDATE_INPUT';
export const STUDY_INVITE_SUBMIT = 'STUDY_INVITE_SUBMIT';
export const STUDY_INVITE_RESET = 'STUDY_INVITE_RESET';
export const STUDY_INVITE_VALIDATION_ERROR = 'STUDY_INVITE_VALIDATION_ERROR';
export const STUDY_INVITE_STUDY_RECEIVED = 'STUDY_INVITE_STUDY_RECEIVED';
export const STUDY_INVITE_SENT = 'STUDY_INVITE_SENT';
export const STUDY_INVITE_PERMISSIONS_LOADED = 'STUDY_INVITE_PERMISSIONS_LOADED';
export const STUDY_INVITE_PERMISSIONS_TOGGLE = 'STUDY_INVITE_PERMISSIONS_TOGGLE';
export const STUDY_INVITE_PERMISSIONS_TOGGLE_ALL = 'STUDY_INVITE_PERMISSIONS_TOGGLE_ALL';
export const STUDY_INVITE_TOGGLE_MODAL = 'STUDY_INVITE_TOGGLE_MODAL';
export const STUDY_INVITE_SHOW_PERMISSIONS = 'STUDY_INVITE_SHOW_PERMISSIONS';
export const STUDY_INVITE_SHOW_USERS = 'STUDY_INVITE_SHOW_USERS';

export const studyInviteShowUsers = () => ({
    type: STUDY_INVITE_SHOW_USERS,
    payload: {}
});
export const studyInviteShowPermissions = () => ({
    type: STUDY_INVITE_SHOW_PERMISSIONS,
    payload: {}
});

export const studyInviteToggleModal = studyId => ({
    type: STUDY_INVITE_TOGGLE_MODAL,
    payload: {studyId}
});

export const studyInviteFetchData = studyId => ({
    type: STUDY_INVITE_FETCH_DATA,
    payload: {studyId}
});
export const studyInviteUpdateInput = (prop, value) =>  ({
    type: STUDY_INVITE_UPDATE_INPUT,
    payload: {prop, value}
});
export const studyInviteReset = () =>  ({
    type: STUDY_INVITE_RESET,
    payload: {}
});
export const studyInviteStudyReceived = (study, userEmails, email) => ({
    type: STUDY_INVITE_STUDY_RECEIVED,
    payload: {study, userEmails, email}
});
export const studyInvitePermissionsReceived = permissions => ({
    type: STUDY_INVITE_PERMISSIONS_LOADED,
    payload: {permissions}
});
export const studyInviteValidationError = errorMessage => ({
    type: STUDY_INVITE_VALIDATION_ERROR,
    payload: {errorMessage}
});
export const studyInviteSent = () => ({
    type: STUDY_INVITE_SENT,
    payload: {}
});
export const studyInviteSubmit = () => ({
    type: STUDY_INVITE_SUBMIT,
    payload: {}
});
export const studyInvitePermissionsToggleAll = () => ({
    type: STUDY_INVITE_PERMISSIONS_TOGGLE_ALL,
    payload: {}
});
export const studyInvitePermissionsToggle = id => ({
    type: STUDY_INVITE_PERMISSIONS_TOGGLE,
    payload: {id}
});