import React from "react";
import PropTypes from "prop-types";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import ModalTitle from "../components/ModalTitle";
import { withRouter } from "react-router-dom";
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import LockIcon from '@material-ui/icons/Lock';
import DynamicFeedIcon from '@material-ui/icons/DynamicFeed';
import CreateIcon from '@material-ui/icons/Create';
class Modal extends React.PureComponent {
  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      const { onClose } = this.props;
      if (onClose) {
        onClose();
      }

    }
  }
  render() {
    const {
      value,
      classes,
      onClose,
      fullScreen,
      maxWidth,
      titleBorder,
      titleBackground,
      titleDark,
      title,
      staticContext,
      ...other
    } = this.props;
    return (
      <Dialog
        fullScreen={fullScreen}
        /*disableBackdropClick={false}*/
        disableEscapeKeyDown={false}
        /*onBackdropClick={onClose}*/
        onClose={onClose}
        aria-labelledby="confirmation-dialog-title"
        maxWidth={maxWidth}
        classes={{
          paper: classes.paper
        }}
        {...other}
      >
        {!title && <ModalTitle onClose={onClose} />}
        {title && (
          <ModalTitle onClose={onClose} border={titleBorder} background={titleBackground}
            dark={titleDark} className={classes.modalHeader}>
            {/*<h3 className={classes.modalHeader}>{title}</h3>*/}
            {title.toUpperCase() === "UPLOAD FILES" &&
              <CloudUploadIcon style={{ color: "#004884", top: "5px", position: "relative", marginRight: "4px" }} />
            }
            {((title.toUpperCase() === "VERIFY PHONE NUMBER") || (title.toUpperCase() === "VERIFY EMAIL")) &&
              <CheckBoxIcon style={{ color: "#004884", top: "5px", position: "relative", marginRight: "4px" }} />
            }
            {(title.toUpperCase() === "PASSWORD CHANGE") &&
              <LockIcon style={{ color: "#004884", top: "5px", position: "relative", marginRight: "4px" }} />
            }
            {(title.toUpperCase() === "FILE VERSIONS") &&
              <DynamicFeedIcon style={{ color: "#004884", top: "5px", position: "relative", marginRight: "4px" }} />
            }
            {(title.toUpperCase() === "CREATE NEW PROJECT") &&
              <CreateIcon style={{ color: "#004884", top: "5px", position: "relative", marginRight: "4px" }} />
            }
            {title}
          </ModalTitle>
        )}
        <DialogContent classes={{ root: classes.dialog }}>
          {this.props.children}
        </DialogContent>
      </Dialog>
    );
  }
}

Modal.propTypes = {
  classes: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired
};

export default withRouter(Modal);
