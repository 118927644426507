import "./Sidebar.scss";
import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as actions from "../actions/";
import PropTypes from "prop-types";
import classNames from "classnames";
import AddIcon from "@material-ui/icons/Add";
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { withStyles } from "@material-ui/core"; // maybe try to do it in dif import
import Tooltip from "@material-ui/core/Tooltip";
import Box from "@material-ui/core/Box";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import IconButton from "@material-ui/core/IconButton";
import UploadModal from "../pages/modals/UploadModal";
import StudyInviteUser from "../pages/invite/StudyInviteUser";

const styles = theme => ({
  root: {
    color: theme.palette.text.primary
  },
  lightTooltip: {
    pointerEvents: "none",
    cursor: "pointer"
  },
  tooltipStudy: {},
});

export class Sidebar extends React.PureComponent {
  state = { open: false, selected: '' };

  ToggleMenu = value => {
    this.setState({ open: !this.state.open });
  };

  handleLinkClick = (value) => {
    const { history, id } = this.props;
    const { location } = history;
    const { pathname } = location;

    this.setState({ selected: value });

    if (value === "studies" && pathname !== "/studies") {
      history.push("/studies");
    }

    if (value === "dashboard" && pathname !== `/study:${id}`) {
      history.push(`/study:${id}`);
    }
    else if (value === "uploads" && pathname !== `/study:${id}/uploads`) {
      history.push(`/study:${id}/uploads`);
    } else if (
      value === "downloads" &&
      pathname !== `/study:${id}/downloads`
    ) {
      history.push(`/study:${id}/downloads`);
    } else if (
      value === "release" &&
      pathname !== `/study:${id}/release`
    ) {
      history.push(`/study:${id}/release`);
    } else if (
      value === "sasjobs" &&
      pathname !== `/study:${id}/sasjobs`
    ) {
      history.push(`/study:${id}/sasjobs`);
    }
    else if (
      value === "sasexecution" &&
      pathname !== `/study:${id}/sasexecution`
    ) {
      history.push(`/study:${id}/sasexecution`);
    }
    else if (
      value === "permissions" &&
      pathname !== `/study:${id}/permissions`
    ) {
      history.push(`/study:${id}/permissions`);
    }
    else if (
      value === "utilities" &&
      pathname !== `/study:${id}/utilities`
    ) {
      history.push(`/study:${id}/utilities`);
    }
  };

  showOnboarding = () => {
    const { actions } = this.props
    actions.showOnboarding()
    this.props.history.push("/");
  }

  renderUploadModal = () => {
    const {
      uploadModal,
      viewMode,
      uploading,
      uploadSucces,
      dashboardPermissions,
      potentialUploads,
      potentialUploadsWithStatus,
      selectedUploadDownloadfolder,
      uploadFolders,
      actions } = this.props;

    if (uploadModal) {
      return (
        <UploadModal
          actions={actions}
          viewMode={viewMode}
          uploading={uploading}
          uploadSucces={uploadSucces}
          permissions={dashboardPermissions}
          open={true}
          onClose={actions.studyCloseUploadModal}
          value={0}
          potentialUploads={potentialUploads}
          potentialUploadsWithStatus={potentialUploadsWithStatus}
          selectedUploadDownloadfolder={selectedUploadDownloadfolder}
          uploadFolders={uploadFolders}
        />
      );
    }
  };

  renderInvitesModal = () => {
    const { actions, studyInvite } = this.props;
    if (studyInvite && studyInvite.open) {
      return (
        <StudyInviteUser
          {...studyInvite}
          actions={actions}
          open={true}
          onClose={actions.studyInviteToggleModal}
          value={0}
        />
      );
    }
  };

  render() {
    const { open, selected } = this.state;

    const {
      classes,
      studiesSelected,
      history,
      studies,
      id,
      dashboardPermissions,
      actions,
      userProfile
    } = this.props;

    const { location } = history;
    const { pathname } = location;

    const studySelected = pathname.indexOf("/study") === -1 ? false : true;

    const permissionList =
      id &&
        dashboardPermissions &&
        dashboardPermissions.length > 0
        ? dashboardPermissions.filter(item => item.selected === true).map(item => item.permission)
        : [];


    const canRenderUploadBtn = permissionList.includes("UPLOAD");
    const hasAdminRights = permissionList.includes("ADMIN");
    return (
      <React.Fragment>
        {(studySelected) &&
          <React.Fragment>
            {this.renderUploadModal()}
            {this.renderInvitesModal()}
            <Box display={{ xs: 'none', sm: 'none', md: 'block', lg: 'block', xl: 'block' }}>
              <nav className="side-bar-big">
                {(permissionList) &&
                  <React.Fragment>
                    <Typography variant="h6" component="h6" className={classNames(
                      "side-bar-big__list_heading"
                    )}>
                      NAVIGATION
                    </Typography>
                    <List component="nav" aria-label="navigation to pages" style={{ marginTop: "0.4rem" }}>
                      <ListItem id="navDashboard" className={selected === 'dashboard' ? "selected" : null} button key="overview" onClick={() => this.handleLinkClick('dashboard')}>
                        <ListItemText primary="Overview" />
                        {selected === 'dashboard' &&
                          <IconButton edge="end" aria-label="selected">
                            <ArrowBackIosIcon />
                          </IconButton>
                        }
                      </ListItem>
                      {permissionList.includes("UPLOAD") && (
                        <ListItem id="navUploads" className={selected === 'uploads' ? "selected" : null} button key="uploads" onClick={() => this.handleLinkClick('uploads')}>
                          <ListItemText primary="Uploads" />
                          {selected === 'uploads' &&
                            <IconButton edge="end" aria-label="selected">
                              <ArrowBackIosIcon />
                            </IconButton>
                          }
                        </ListItem>
                      )}
                      {permissionList.includes("DOWNLOAD") && (
                        <ListItem id="navDownloads" className={selected === 'downloads' ? "selected" : null} button key="downloads" onClick={() => this.handleLinkClick('downloads')}>
                          <ListItemText primary="Downloads" />
                          {selected === 'downloads' &&
                            <IconButton edge="end" aria-label="selected">
                              <ArrowBackIosIcon />
                            </IconButton>
                          }
                        </ListItem>
                      )}
                      {permissionList.includes("RELEASE") && (
                        <ListItem id="navRelease" className={selected === 'Released' ? "selected" : null} button key="released" onClick={() => this.handleLinkClick('release')}>
                          <ListItemText primary="Released" />
                          {selected === 'release' &&
                            <IconButton edge="end" aria-label="selected">
                              <ArrowBackIosIcon />
                            </IconButton>
                          }
                        </ListItem>
                      )}
                      {permissionList.includes("SASUPLOAD") && (
                        <ListItem id="navSasjobs" className={selected === 'sasjobs' ? "selected" : null} button key="sasjobs" onClick={() => this.handleLinkClick('sasjobs')}>
                          <ListItemText primary="Sas jobs" />
                          {selected === 'sasjobs' &&
                            <IconButton edge="end" aria-label="selected">
                              <ArrowBackIosIcon />
                            </IconButton>
                          }
                        </ListItem>
                      )}
                      {permissionList.includes("SASUPLOAD") && (
                        <ListItem id="navSasexecution" className={selected === 'sasexecution' ? "selected" : null} button key="sasexecution" onClick={() => this.handleLinkClick('sasexecution')}>
                          <ListItemText primary="Sas execution" />
                          {selected === 'sasexecution' &&
                            <IconButton edge="end" aria-label="selected">
                              <ArrowBackIosIcon />
                            </IconButton>
                          }
                        </ListItem>
                      )}
                      {permissionList.includes("ADMIN") && (
                        <ListItem id="navPermissions" className={selected === 'permissions' ? "selected" : null} button key="permissions" onClick={() => this.handleLinkClick('permissions')}>
                          <ListItemText primary="Permissions" />
                          {selected === 'permissions' &&
                            <IconButton edge="end" aria-label="selected">
                              <ArrowBackIosIcon />
                            </IconButton>
                          }
                        </ListItem>
                      )}
                      <ListItem id="navUtilities" className={selected === 'utilities' ? "selected" : null} button key="Utilities" onClick={() => this.handleLinkClick('utilities')}>
                        <ListItemText primary="Utilities" />
                        {selected === 'utilities' &&
                          <IconButton edge="end" aria-label="selected">
                            <ArrowBackIosIcon />
                          </IconButton>
                        }
                      </ListItem>
                    </List>
                    <Divider />
                    <Typography variant="h6" component="h6" className={classNames(
                      "side-bar-big__list_heading"
                    )}>
                      ACTIONS
                    </Typography>
                    <List component="nav" aria-label="upload invite">
                      {canRenderUploadBtn && (
                        <ListItem id="actionUpload" button key="upload" onClick={actions.studyOpenUploadModal}>
                          <ListItemIcon style={{ minWidth: "40px" }}>
                            <CloudUploadIcon />
                          </ListItemIcon>
                          <ListItemText primary="Upload" />
                        </ListItem>
                      )}
                      {hasAdminRights && (
                        <ListItem id="actionInviteUser" button key="invite" onClick={() => actions.studyInviteToggleModal(id)}>
                          <ListItemIcon style={{ minWidth: "40px" }}>
                            <GroupAddIcon />
                          </ListItemIcon>
                          <ListItemText primary="Invite user" />
                        </ListItem>
                      )}
                    </List>
                  </React.Fragment>
                }
              </nav>
            </Box>

            <Box display={{ xs: 'block', sm: 'block', md: 'none', lg: 'none', xl: 'none' }}>
              <nav className={open === true ? "side-bar-big" : "side-bar-small"}>
                {!open ?
                  <React.Fragment>
                    <List id="navMenuOpen" component="nav" aria-label="menu upload invite create" style={{ marginTop: "0.4rem" }}>
                      <ListItem button key="menu" onClick={() => this.ToggleMenu()}>
                        <Tooltip
                          title="Toggle menu"
                          aria-label="Toggle Menu"
                          classes={{ tooltip: classes.lightTooltip }}
                        >
                          <ListItemIcon style={{ minWidth: "40px", color: "#fff" }}>
                            <MoreVertIcon />
                          </ListItemIcon>
                        </Tooltip>
                      </ListItem>
                    </List>
                    <Divider />
                    <List component="nav" aria-label="upload invite">
                      {canRenderUploadBtn && (
                        <ListItem id="navUpload" button key="upload" onClick={actions.studyOpenUploadModal}>
                          <Tooltip
                            title="Upload"
                            aria-label="Upload"
                            classes={{ tooltip: classes.lightTooltip }}
                          >
                            <ListItemIcon style={{ minWidth: "40px" }}>
                              <CloudUploadIcon />
                            </ListItemIcon>
                          </Tooltip>
                        </ListItem>
                      )}
                      {hasAdminRights && (
                        <ListItem id="navInviteUser" button key="invite" onClick={() => actions.studyInviteToggleModal(id)}>
                          <Tooltip
                            title="Invite user"
                            aria-label="Invite user"
                            classes={{ tooltip: classes.lightTooltip }}
                          >
                            <ListItemIcon style={{ minWidth: "40px" }}>
                              <GroupAddIcon />
                            </ListItemIcon>
                          </Tooltip>
                        </ListItem>
                      )}
                    </List>
                  </React.Fragment>
                  :
                  <React.Fragment>
                    {(permissionList) &&
                      <React.Fragment>
                        <List id="menuClose" component="nav" aria-label="menu upload invite create" style={{ marginTop: "0.4rem" }}>
                          <ListItem button key="menu" onClick={() => this.ToggleMenu()}>
                            <Tooltip
                              title="Toggle menu"
                              aria-label="Toggle Menu"
                              classes={{ tooltip: classes.lightTooltip }}
                            >
                              <ListItemIcon style={{ minWidth: "40px", color: "#fff" }}>
                                <MoreVertIcon />
                              </ListItemIcon>
                            </Tooltip>
                          </ListItem>
                        </List>
                        <Divider />
                        <List component="nav" aria-label="navigation to pages" style={{ marginTop: "0.4rem" }}>
                          <ListItem id="navDashboard" className={selected === 'dashboard' ? "selected" : null} button key="overview" onClick={() => this.handleLinkClick('dashboard')}>
                            <ListItemText primary="Overview" />
                          </ListItem>
                          {permissionList.includes("UPLOAD") && (
                            <ListItem id="navUploads" className={selected === 'uploads' ? "selected" : null} button key="uploads" onClick={() => this.handleLinkClick('uploads')}>
                              <ListItemText primary="Uploads" />
                            </ListItem>
                          )}
                          {permissionList.includes("DOWNLOAD") && (
                            <ListItem id="navDownloads" className={selected === 'downloads' ? "selected" : null} button key="downloads" onClick={() => this.handleLinkClick('downloads')}>
                              <ListItemText primary="Downloads" />
                            </ListItem>
                          )}
                          {permissionList.includes("RELEASE") && (
                            <ListItem id="navReleased" className={selected === 'release' ? "selected" : null} button key="released" onClick={() => this.handleLinkClick('release')}>
                              <ListItemText primary="Released" />
                            </ListItem>
                          )}
                          {permissionList.includes("SASUPLOAD") && (
                            <ListItem id="navSasjobs" className={selected === 'sasjobs' ? "selected" : null} button key="sasjobs" onClick={() => this.handleLinkClick('sasjobs')}>
                              <ListItemText primary="Sas jobs" />
                            </ListItem>
                          )}
                          {permissionList.includes("SASUPLOAD") && (
                            <ListItem id="navSasexecution" className={selected === 'sasexecution' ? "selected" : null} button key="sasexecution" onClick={() => this.handleLinkClick('sasexecution')}>
                              <ListItemText primary="Sas execution" />
                            </ListItem>
                          )}
                          {permissionList.includes("ADMIN") && (
                            <ListItem id="navPermissions" className={selected === 'permissions' ? "selected" : null} button key="permissions" onClick={() => this.handleLinkClick('permissions')}>
                              <ListItemText primary="Permissions" />
                            </ListItem>
                          )}
                          <ListItem id="navUtilities" className={selected === 'utilities' ? "selected" : null} button key="Utilities" onClick={() => this.handleLinkClick('utilities')}>
                            <ListItemText primary="Utilities" />
                          </ListItem>
                        </List>
                        <Divider />
                        <Typography variant="h6" component="h6" className={classNames(
                          "side-bar-big__list_heading"
                        )}>
                          ACTIONS
                        </Typography>
                        <List component="nav" aria-label="upload invite">
                          {canRenderUploadBtn && (
                            <ListItem id="actionUpload" button key="upload" onClick={actions.studyOpenUploadModal}>
                              <ListItemIcon style={{ minWidth: "40px" }}>
                                <CloudUploadIcon />
                              </ListItemIcon>
                              <ListItemText primary="Upload" />
                            </ListItem>
                          )}
                          {hasAdminRights && (
                            <ListItem id="actionInvite" button key="invite" onClick={() => actions.studyInviteToggleModal(id)}>
                              <ListItemIcon style={{ minWidth: "40px" }}>
                                <GroupAddIcon />
                              </ListItemIcon>
                              <ListItemText primary="Invite user" />
                            </ListItem>
                          )}
                          {userProfile &&
                            userProfile.initials &&
                            studiesSelected &&
                            studies &&
                            studies.length && (
                              <ListItem id="actionCreate" button key="create" onClick={/*actions.createStudyToggleModal*/ this.showOnboarding}>
                                <ListItemIcon style={{ minWidth: "40px" }}>
                                  <AddIcon />
                                </ListItemIcon>
                                <ListItemText primary="Create study" />
                              </ListItem>
                            )}
                        </List>
                      </React.Fragment>
                    }
                  </React.Fragment>
                }
              </nav>
            </Box>
          </React.Fragment>
        }
      </React.Fragment>
    );
  }
}

Sidebar.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object,
  studiesSelected: PropTypes.bool,
  viewMode: PropTypes.string,
  uploading: PropTypes.bool,
  uploadSucces: PropTypes.bool,
  userProfile: PropTypes.object,
  studies: PropTypes.array,
  uploadModal: PropTypes.bool,
  studyInvite: PropTypes.object,
  id: PropTypes.number,
  dashboardPermissions: PropTypes.array,
  selectedUploadDownloadfolder: PropTypes.array,
  uploadFolders: PropTypes.array
};


function mapStateToProps(state) {
  return {
    id: state.root.studyDetail?.id,
    viewMode: state.root.studyDetail?.viewMode,
    uploading: state.root.studyDetail?.uploading,
    uploadSucces: state.root.studyDetail?.uploadSucces,
    userProfile: state.userProfile,
    studies: state.root.studies,
    uploadModal: state.root.studyDetail?.uploadModal,
    studyInvite: state.studyInvite,
    dashboardPermissions: state.root.studyDetail?.dashboardPermissions,
    potentialUploads: state.root.studyDetail?.potentialUploads,
    potentialUploadsWithStatus: state.root.studyDetail?.potentialUploadsWithStatus,
    selectedUploadDownloadfolder: state.root.studyDetail?.selectedUploadDownloadfolder,
    uploadFolders: state.root.studyDetail?.uploadFolders
  }
}
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Sidebar));



