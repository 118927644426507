//Create iso date
export const formatIsoDate = (date) => {

  let d = (date !== "") ? new Date(date) : new Date(),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear(),
    hours = '' + d.getHours(),
    minutes = '' + d.getMinutes(),
    seconds = '' + d.getSeconds();

  if (month.length < 2) month = `0${month}`;
  if (day.length < 2) day = `0${day}`;
  if (hours.length < 2) hours = `0${hours}`;
  if (minutes.length < 2) minutes = `0${minutes}`;
  if (seconds.length < 2) seconds = `0${seconds}`

  return [year, month, day].join('-') + "T" + hours + ':' + minutes + ':' + seconds;
}

export const timeDifference = (date) => {

  var msPerMinute = 60 * 1000
  var msPerHour = msPerMinute * 60
  var msPerDay = msPerHour * 24
  var msPerMonth = msPerDay * 30
  var msPerYear = msPerDay * 365.5
  var elapsed = new Date() - new Date(date)
  if (elapsed < msPerMinute) {
    return Math.floor(elapsed / 1000) + " seconds ago"
  }
  else if (elapsed < msPerHour) {
    return Math.floor(elapsed / msPerMinute) + " minutes ago"
  }
  else if (elapsed < msPerDay) {
    return Math.floor(elapsed / msPerHour) + " hours ago"
  }
  else if (elapsed < msPerMonth) {
    return Math.floor(elapsed / msPerDay) + " days ago"
  }
  else if (elapsed < msPerYear) {
    return Math.floor(elapsed / msPerMonth) + " months ago"
  }
  else {
    return Math.floor(elapsed / msPerYear) + " years ago"
  }
}

export const timeDifferenceTwoDates = (startDate, endDate) => {

  if (startDate === undefined || endDate === undefined || startDate === null || endDate === null) return ""
  
  var msPerMinute = 60 * 1000
  var msPerHour = msPerMinute * 60
  var msPerDay = msPerHour * 24
  var msPerMonth = msPerDay * 30
  //var msPerYear = msPerDay * 365.5
  var elapsed = new Date(endDate) - new Date(startDate)
  if (elapsed < msPerMinute) {
    return /*Math.round(*/ (elapsed / 1000).toFixed(2)/*)*/ + " sec"
  }
  else if (elapsed < msPerHour) {
    return /*Math.round*/(elapsed / msPerMinute).toFixed(2) + " min"
  }
  else if (elapsed < msPerDay) {
    return /*Math.round*/(elapsed / msPerHour).toFixed(2) + " hour"
  }
  else if (elapsed < msPerMonth) {
    return Math.round(elapsed / msPerDay) + " d"
  }
  /*else if (elapsed < msPerYear) {
    return Math.floor(elapsed / msPerMonth) + " months ago"
  }
  else {
    return Math.floor(elapsed / msPerYear) + " years ago"
  }*/
}

/*
function localizeDateStr(d) {

  let convertedDate = new Date(d);
  let offset = convertedDate.getTimezoneOffset();

  if (offset === 0) {
    return convertedDate;
  }
  //const hrs = -(new Date(d).getTimezoneOffset() / 60)
  let offsetStr = offset.toString();
  let num = parseInt(offsetStr.substring(1, offsetStr.length), 0) / 60;

  if (offset > 0) {
    convertedDate.setHours(convertedDate.getHours() - num);
  }
  else {
    convertedDate.setHours(convertedDate.getHours() + num);
  }
  return convertedDate;
}
*/
export const formatDateTime = d => {
  if (d === null) return "";

  //const _date = localizeDateStr(d);
  //return formatIsoDate(_date);
  return formatIsoDate(d);
};
