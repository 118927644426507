import * as actions from "../actions/studyInvite";


export const DEFAULT_STATE = {
  loading: true,
  studyId: undefined,
  userEmail: undefined,
  permissions: [],
  users: [],
  userEmails: [],
  isAdmin: true,
  cnt: 0,
  allSelected: false,
  showPermissions: false,
  open: false,
  step: 0
};

export const root = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case actions.STUDY_INVITE_RESET: {
      return DEFAULT_STATE;
    }
    case actions.STUDY_INVITE_SHOW_USERS: {
      return {
        ...state,
        showPermissions: false,
        step: 0
      }
    }
    case actions.STUDY_INVITE_SHOW_PERMISSIONS: {
      return {
        ...state,
        step: 1,
        showPermissions: state.userEmail ? true : false,
        errorMessage: !state.userEmail ? 'You need to select a user' : state.errorMessage
      }
    }
    /*case actions.STUDY_INVITE_SUBMIT: {
      return {
        ...state,
        loading: true,
        step: 2
      };
    }*/
    case actions.STUDY_INVITE_VALIDATION_ERROR: {
      const { errorMessage } = action.payload;
      return {
        ...state,
        loading: false,
        errorMessage
      };
    }
    case actions.STUDY_INVITE_STUDY_RECEIVED: {
      const { study, userEmails, email } = action.payload;
      return {
        ...state,
        loading: false,
        studyId: study.studyId,
        isAdmin: study.isAdminFlag,
        users: study.users,
        userEmails: userEmails.filter(item => item !== email)
      };
    }
    case actions.STUDY_INVITE_PERMISSIONS_LOADED: {
      const { permissions } = action.payload;
      return {
        ...state,
        permissions: permissions ? permissions.map(item => {
          return { ...item, selected: false };
        }) : []
      };
    }
    case actions.STUDY_INVITE_SENT: {
      // safe guard slow endpoint
      if (state.step === 2) {
        return {
          ...state,
          loading: false,
          errorMessage: undefined
        };
      }

      return state;
    }
    case actions.STUDY_INVITE_UPDATE_INPUT: {
      const { prop, value } = action.payload;
      return {
        ...state,
        [prop]: value
      };
    }
    case actions.STUDY_INVITE_SUBMIT: {
      return {
        ...state,
        loading: true,
        step: 2,
        errorMessage: undefined
      };
    }
    case actions.STUDY_INVITE_PERMISSIONS_TOGGLE: {
      // make generic to also work for downloads
      const { id } = action.payload;
      let cnt = 0;
      const transformedPermissions = state.permissions.map(item => {
        let base = { ...item };
        if (item.permission === id) {
          if (item.selected) {
            base.selected = false;
          } else {
            base.selected = true;
          }
        }

        if (base.selected === true) {
          cnt++;
        }
        return base;
      });
      // on route change reset selectedDate to new Date
      return {
        ...state,
        permissions: transformedPermissions,
        cnt: cnt,
        allSelected: cnt === transformedPermissions.length
      };
    }
    case actions.STUDY_INVITE_PERMISSIONS_TOGGLE_ALL: {
      const resolveSelection = () => {
        if (state.allSelected) {
          return false;
        }
        if (state.cnt === 0) {
          return true;
        }
        return true;
      };

      const transformedPermissions = state.permissions.map(item => {
        let base = { ...item, selected: resolveSelection() ? true : false };
        return base;
      });
      return {
        ...state,
        permissions: transformedPermissions,
        cnt: resolveSelection() ? transformedPermissions.length : 0,
        allSelected: resolveSelection()
      };
    }
    case actions.STUDY_INVITE_TOGGLE_MODAL: {
      return {
        ...state,
        studyId: action.payload.studyId,
        open: !state.open
      }
    }
    default:
      return state;
  }
};
