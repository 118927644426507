import * as actions from "../actions/createStudy";

export const DEFAULT_STATE = {
  studyName: undefined,
  studyProject: undefined,
  studyMetaPackage: undefined,
  loading: false,
  id: undefined,
  errorMessage: [],
  projectLabels: [],
  open: false
};

export const root = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case actions.CREATE_STUDY_RESET: {
      return DEFAULT_STATE;
    }
    case actions.CREATE_STUDY_TOGGLE_MODAL: {
      const { metaPackage } = action.payload;
      return {
        ...state,
        open: !state.open,
        studyMetaPackage: metaPackage
      };
    }
    case actions.CREATE_STUDY_PROJECT_LABELS_LOAD: {
      return {
        ...state,
        loading: false,
        fetching: true
      };
    }
    case actions.CREATE_STUDY_VALIDATION_ERROR: {
      const { errorMessage } = action.payload;
      return {
        ...state,
        loading: false,
        errorMessage,
        validateErrors: true
      };
    }
    case actions.CREATE_STUDY_PROJECT_LABELS_RECEIVED: {
      const { labels } = action.payload;
      return {
        ...state,
        loading: false,
        projectLabels: labels
      };
    }
    case actions.CREATE_STUDY_STUDY_CREATED: {
      const { res } = action.payload;
      return {
        ...state,
        loading: false,
        errorMessage: undefined,
        id: res.studyId,
        studiesRefresh: true
      };
    }
    case actions.CREATE_STUDY_UPDATE_INPUT: {
      const { prop, value } = action.payload;

      let errorMessage = state.errorMessage;
      let isValid = true;
      if (errorMessage.length || state.validateErrors) {
        if (!value || value.length < 2) {
          isValid = false;
        }
        if (isValid) {
          errorMessage = errorMessage.filter(item => item !== prop);
        } else {
          if (errorMessage.length === 0 || !errorMessage.includes(prop)) {
            errorMessage.push(prop);
          }
        }
      }
      return {
        ...state,
        [prop]: value,
        errorMessage: errorMessage
      };
    }
    case actions.CREATE_STUDY_SUBMIT: {
      return {
        ...state,
        loading: true
      };
    }
    default:
      return state;
  }
};
