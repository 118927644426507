export const FILE_REVIEW_OPEN_MODAL = 'FILE_REVIEW_OPEN_MODAL';
//export const FILE_VERSIONS_FETCH_DATA = 'FILE_VERSIONS_FETCH_DATA';
//export const FILE_VERSIONS_RECEIVED = 'FILE_VERSIONS_RECEIVED';
export const FILE_REVIEW_RESET = 'FILE_REVIEW_RESET';
//export const FILE_VERSIONS_FILE_TOGGLED = 'FILE_VERSIONS_FILE_TOGGLED';
//export const FILE_VERSIONS_ALL_TOGGLED = 'FILE_VERSIONS_ALL_TOGGLED';
//export const FILE_VERSIONS_DOWNLOAD_ALL = 'FILE_VERSIONS_DOWNLOAD_ALL';
//export const FILE_VERSIONS_TABLE_SORT = 'FILE_VERSIONS_TABLE_SORT';
/*
export const fileVersionsTableSort = prop => ({
    type: FILE_VERSIONS_TABLE_SORT,
    payload: {prop}
});

export const fileVersionsDownloadAll = (id = undefined) => ({
    type: FILE_VERSIONS_DOWNLOAD_ALL,
    payload: {id: id || undefined}
});
*/
export const fileReviewOpenModal = (studyId, studyFileId, viewMode) => ({
    type: FILE_REVIEW_OPEN_MODAL,
    payload: { studyId, studyFileId, viewMode }
});

export const fileReviewReset = () => ({
    type: FILE_REVIEW_RESET,
    payload: {}
});
/*
export const fileVersionsFetchData = () => ({
    type: FILE_VERSIONS_FETCH_DATA,
    payload: {}
});

export const fileVersionsAllToggled = () => ({
    type: FILE_VERSIONS_ALL_TOGGLED,
    payload: {}
});

export const fileVersionsFileToggled = id => ({
    type: FILE_VERSIONS_FILE_TOGGLED,
    payload: {id}
});
*/