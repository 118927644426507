import { createSelector } from "reselect";

const isOpen = state => state.permissionDetail.open;
const permissisonDetail = state => state.permissionDetail;
export const isAdminPermissionModalOpen = createSelector(
  [isOpen],
  open => {
    return open;
  }
);
export const getPermissionDetail = createSelector(
  [permissisonDetail],
  permissisonDetail => {
    return permissisonDetail;
  }
);
export const signupUserName = state => state.signup.userName;
export const signupPassword = state => state.signup.password;
export const signupPhone = state => state.signup.phone;


export const getSignup = createSelector(
  [signupUserName, signupPassword, signupPhone],
  (userName, password, phone) => {
    return { userName, password, phone };
  }
);

export const resetPasswordPassword = state => state.userProfile.confirmUserAttributesModal.password;
export const resetPasswordOldPassword = state => state.userProfile.confirmUserAttributesModal.oldPassword;
export const userProfilePhone = state => state.userProfile.confirmUserAttributesModal.phoneNumber;

export const getUserProfilePhoneNumber = state => state.userProfile.confirmUserAttributesModal.phone;

export const getVerificationCode = state => state.userProfile.confirmUserAttributesModal.verificationCode;


export const getRestPasswordFields = createSelector(
  [resetPasswordPassword, resetPasswordOldPassword],
  (password, oldPassword) => {
    return { password, oldPassword };
  }
);

const getStudies = state => state.root.studies;
const getStudiesLoaded = state => state.root.studiesLoaded;

export const getStudiesAndStudiesLoaded = createSelector(
  [getStudies, getStudiesLoaded],
  (studies, studiesLoaded) => {
    return { studies, studiesLoaded };
  }
);
const getUserProfileEmail = state => state.userProfile.email;
const getUserCustomerId = state => state.userProfile.customerId;

export const getEmailAndCustomer = createSelector(
  [getUserProfileEmail, getUserCustomerId],
  (email, customerId) => {
    return { email, customerId };
  }
);

export const getStudyInviteState = state => state.studyInvite;

export const getCreateStudyState = state => state.createStudy;