import * as actions from "../actions";
import {isAdminPermissionModalOpen, getPermissionDetail} from '../selectors/storeselectors';

import {
  takeLatest,
  call,
  put,
  getContext,
  select,
} from "redux-saga/effects";


export function* adminAassignStudyPermissions() {
  let originalPermissions = [];
  try {
    const api = yield getContext("api");
    const permissionDetail = yield select(getPermissionDetail);
    originalPermissions = permissionDetail.permissions;
    const params = {
      AssignToUserId: permissionDetail.customer.customerId,
      StudyId: permissionDetail.studyId,
      permissions: permissionDetail.permissions
    };
    yield call(api.post, "StudyPermission/AssignStudyPermissions", params);
   
  } catch (error) {
    // set back original permissions if api fails
    yield put(actions.permissionReceive(originalPermissions));
    yield put(actions.onApiError());
  }
}
export function* adminGetStudyPermissions(action) {
  try {
    const api = yield getContext("api");
    
    const isModalOpen = yield select(isAdminPermissionModalOpen);
    if (isModalOpen) {
      
      const { customer, studyId } = action.payload;  
      const res = yield call(api.get, `StudyPermission/AssignStudyPermissions/${studyId}/${customer.customerId}`);
      const permissionDetail = yield select(getPermissionDetail);
      
      if (permissionDetail.studyId && permissionDetail.studyId === studyId && permissionDetail.customer && permissionDetail.customer.customerId === res.assignToUserId) {
         yield put(actions.permissionReceive(res.permissions));
      }
     
    }
  } catch (error) {
    // should write en error occured
    console.log("adminGetStudyPermissions error in resp ", error);
    yield put(actions.onApiError());
  }
}

export function* adminPermissionsSaga() {
  yield takeLatest(actions.PERMISSION_TOGGLE_MODAL, adminGetStudyPermissions);
  yield takeLatest([actions.PERMISSION_TOGGLE, actions.PERMISSION_TOGGLE_ALL], adminAassignStudyPermissions);
}
