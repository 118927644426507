//export const PERMISSION_TOGGLE = 'PERMISSION_TOGGLE';
//export const PERMISSION_TOGGLE_ALL = 'PERMISSION_TOGGLE_ALL';
//export const PERMISSION_RECEIVE = 'PERMISSION_RECEIVE';
export const ISSUE_TOGGLE_MODAL = 'ISSUE_TOGGLE_MODAL';
export const ISSUE_TOGGLE = 'ISSUE_TOGGLE'
export const ISSUE_TOGGLE_MODAL_EMPTY = 'ISSUE_TOGGLE_MODAL_EMPTY'

export const issueToggleModal = (studyId, issue) => ({
    type: ISSUE_TOGGLE_MODAL,
    payload: { studyId, issue }
});

export const issueToggleModalEmpty = () => ({
    type: ISSUE_TOGGLE_MODAL_EMPTY,
    payload: {}
});

/*
export const permissionReceive = permissions => ({
    type: PERMISSION_RECEIVE,
    payload: { permissions }
});
*/
export const issueToggle = id => ({
    type: ISSUE_TOGGLE,
    payload: { id }
});
