import React from "react";
import "./EditableTextField.scss";
import EdiText from 'react-editext'

//https://codesandbox.io/s/pjyx2wq3mm?file=/src/EditableTextField.js
export default class EditableTextField extends React.PureComponent {

  render() {
    const {
      value,
      onChange,
      placeholder
    } = this.props;

    //https://alioguzhan.github.io/react-editext/
    return (
      <EdiText
        type='text'
        viewContainerClassName='my-custom-view-wrapper'
        onSave={(v) => onChange(v)}
        hint={placeholder}
        validationMessage="Please type at least 2 characters."
        validation={val => (val.length >= 2)}
        onCancel={v => v}
        value={value}
        inputProps={{
          style: {
            fontWeight: 500,
            width: 210,
            minWidth: 210
          }
        }}
        viewProps={{
          style: {
            /*border: '1px solid rgb(212, 212, 212)',
            padding: '0.6em',
            outline: 'none',*/
            color: ((value && value.length < 2) || !value) ? "red" : "black",
            width: 240,
            minWidth: 240
          }
        }}
      />
    );
  }
}
