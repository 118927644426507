import * as actions from "../actions";

export const DEFAULT_STATE = {
  loading: true,
  studyId: undefined,
  customer: undefined,
  errorMessage: undefined,
  cnt: 0,
  allSelected: false,
  open: false,
  permissions: []
};


export const root = (state = DEFAULT_STATE, action) => {
  switch (action.type) {

    case actions.PERMISSION_TOGGLE_MODAL: {

      if (state.open) {
        return DEFAULT_STATE;
      }
      const { studyId, customer } = action.payload;
      return {
        ...state,
        open: true,
        studyId,
        customer
      }
    }

    case actions.PERMISSION_RECEIVE: {
      const { permissions } = action.payload;
      if (state.open) {
        return {
          ...state,
          permissions: permissions,
          loading: false
        }
      }

      return DEFAULT_STATE;
    }

    case actions.PERMISSION_TOGGLE: {
      // make generic to also work for downloads
      const { id } = action.payload;
      let cnt = 0;
      const transformedPermissions = state.permissions.map(item => {
        let base = { ...item };
        if (item.permission === id) {
          if (item.selected) {
            base.selected = false;
          } else {
            base.selected = true;
          }
        }
        if (base.selected === true) {
          cnt++;
        }
        return base;
      });
      // on route change reset selectedDate to new Date
      return {
        ...state,
        permissions: transformedPermissions,
        cnt: cnt,
        allSelected: cnt === transformedPermissions.length
      };
    }
    case actions.PERMISSION_TOGGLE_ALL: {
      const resolveSelection = () => {
        if (state.allSelected) {
          return false;
        }
        if (state.cnt === 0) {
          return true;
        }
        return true;
      };

      const transformedPermissions = state.permissions.map(item => {
        let base = { ...item, selected: resolveSelection() ? true : false };
        return base;
      });
      return {
        ...state,
        permissions: transformedPermissions,
        cnt: resolveSelection() ? transformedPermissions.length : 0,
        allSelected: resolveSelection()
      };
    }

    default:
      return state;
  }
};
