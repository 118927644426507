import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import Modal from "./Modal";
import { withStyles } from "@material-ui/core";
import loginStyles from "../../auth/LoginStyles";
import VerifyAttribute from "../../auth/VerifyAttribute";
import ChangePassword from "../../auth/ChangePassword";
// https://github.com/mui-org/material-ui/issues/11517 combine styles

const paperStyles = theme => ({
  ...theme.typography,
  paper: {
    width: 400,
    height: 550,
    borderRadius: "8px"
  },

  spinner: {
    position: "absolute",
    top: "12rem",
    left: 0,
    right: 0,
    bottom: 0,
    margin: "auto"
  },
  createContent: {
    width: "90%",
    position: "relative",
    margin: "1rem auto"
  },
  initialForm: {
    margin: "3rem auto"
  },
  buttonWrapper: {
    background: "#0273b5",
    border: 0,
    color: "white",
    /*height: 32,*/
    padding: "0 2rem",
    height: "44px",
    borderRadius: "2rem",
    top: "1.5rem",
    // minWidth: '10rem',
    "&:hover": {
      boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
      background: "#0273b5"
    },

    width: "100%"
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginTop: "2.5rem",
    /*marginLeft: 0,*/
    width: "100%",
    ...theme.typography
    // "&:hover": {
    //   opacity: "1"
    // }
  },
  icon: {
    position: "absolute",
    textAlign: "center",
    lineHeight: "2rem",
    top: "0%",
    left: "50%",
    fontSize: "4rem",
    color: /*theme.palette.primary.light*/ "#4caf50",
    transform: `translate3d(-50%, -100%, 0)`
  },
  succesLabel: {
    position: "absolute",
    textAlign: "center",
    lineHeight: "2rem",
    top: "80%",
    left: "50%",
    fontSize: "0.9rem",
    transform: `translate3d(-50%, -50%, 0)`
  },
  modalHeader: {
    margin: "0.3rem 0 0 1rem",
    ...theme.typography,
    fontSize: "1rem",
    color: "#9e9e9e"
  }
});

const styles = theme => ({
  ...loginStyles(theme),
  ...paperStyles(theme)
});
class VerifyAndUpdateUserField extends React.PureComponent {
  render() {
    const {
      classes,
      actions,
      title,
      type
    } = this.props;

    return (
      <Modal
        onClose={actions.userProfileCloseAttributeModal}
        classes={classes}
        title={title}
        titleBorder
        maxWidth="lg"
        open={true}
      >
        <div className={classes.createContent}>
          {type === 'password' && <ChangePassword {...this.props} />}
          {(type === 'phoneNumber' || type === 'email') && <VerifyAttribute {...this.props} />}
        </div>
      </Modal>
    );
  }
}

VerifyAndUpdateUserField.propTypes = {
  classes: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
  title: PropTypes.string
};

export default withRouter(withStyles(styles)(VerifyAndUpdateUserField));
