import { createSelector } from "reselect";
//import { studyTableSort } from "../actions";
import { predicate } from "../utils/dataSorting";
import { formatDateTime } from "../utils/date";

const getStudies = state => state.studies;
const getStudyDetail = state => state.studyDetail;
const selectedStudy = state => state.selectedStudy

const resolveAllSelected = (cnt, files) => {
  if (!files || files.length === 0) {
    return false;
  }
  return files.length === cnt;
};
export const uploadtable = createSelector(
  [getStudyDetail, getStudies, selectedStudy],
  (studyDetail, studies, selectedStudy) => {
    let permissions = [],
      rows = [],
      total = 0,
      cnt = 0,
      allSelected = false,
      uploadReceivedDate = null,
      uploadRefreshRequired = false,
      folderLoading = false,
      loading = false,
      studyId = undefined,
      viewMode = undefined,
      tableSort = [],
      selectedDate,
      selected = -1,
      filter = [],
      past = [],
      preview = undefined,
      folders = [],
      fileNames = [],
      statuses = [],
      folderTypes = [],
      uploadfilter = [],
      uploadFolders = [],
      fileTypes = [],
      selectedFileTypes = [],
      metadata = null,
      selectedUploadDownloadfolder = [],
      regexExpression = "";


    // we need filter options to be dynamic, both statuses and folder, and fileNames
    if (studyDetail) {
      preview = studyDetail.preview;

      if (studyDetail.dashboardPermissions) {
        permissions = studyDetail.dashboardPermissions.filter(perm => perm.selected === true).map(perm => perm.permission);
      }
      studyId = studyDetail.id;
      selectedDate = studyDetail.selectedDate;
      selected = selectedStudy;
      cnt = studyDetail.cnt;
      viewMode = studyDetail.viewMode
      if (studyDetail.uploadFiles) {
        total = studyDetail.uploadFiles.length;
      }

      if (studyDetail.uploadMetadata) {
        metadata = studyDetail.uploadMetadata
      }

      if (studyDetail.uploadfilter) {
        uploadfilter = studyDetail.uploadfilter;
      }
      if (studyDetail.filter) {
        filter = studyDetail.filter;
      }
      if (studyDetail.past) {
        past = studyDetail.past;
      }
      if (studyDetail.fileTypefilter) {
        selectedFileTypes = studyDetail.fileTypefilter
      }
      allSelected = resolveAllSelected(
        studyDetail.cnt,
        studyDetail.uploadFiles
      );
      if (studyDetail.uploadFolders) {
        uploadFolders = studyDetail.uploadFolders
      }

      if (studyDetail.loading) {
        loading = studyDetail.loading
      }
      if (studyDetail.folderLoading) {
        folderLoading = studyDetail.folderLoading
      }

      if (studyDetail.uploadRegexExpression) {
        regexExpression = studyDetail.uploadRegexExpression
      }

      if (studyDetail.uploadFiles) {
        folders = 'adam';
        fileNames = 'syvdat';

        rows = studyDetail.uploadFiles;

        if (studyDetail.selectedUploadDownloadfolder) {
          selectedUploadDownloadfolder = studyDetail.selectedUploadDownloadfolder
          if (rows) {
            if (selectedUploadDownloadfolder.length > 0) {
              //rows = rows.filter(item => (selectedUploadDownloadfolder.includes(item.folder))              
              rows = rows.filter(item => (selectedUploadDownloadfolder.includes(item.folder) || selectedUploadDownloadfolder.includes(item.folder.toLowerCase()) || selectedUploadDownloadfolder.includes(item.folder.toUpperCase())))
            }
          }
        }

        if (studyDetail.uploadReceivedDate) {
          uploadReceivedDate = studyDetail.uploadReceivedDate
        }
        if (studyDetail.uploadRefreshRequired) {
          uploadRefreshRequired = studyDetail.uploadRefreshRequired
        }

        fileNames = studyDetail.uploadFiles.map(item => item.fileName.split('.')[1]).reduce((unique, item) =>
          unique.includes(item) ? unique : [...unique, item], []);
        folders = studyDetail.uploadFiles.map(item => item.folder).reduce((unique, item) =>
          unique.includes(item) ? unique : [...unique, item], []);
        statuses = studyDetail.uploadFiles.map(item => item.status).reduce((unique, item) =>
          unique.includes(item) ? unique : [...unique, item], []);

      }
      if (filter.length > 0) {
        if (rows) {
          rows = rows.filter(item => filter.includes(item.status));
        }
      }
      folderTypes = [...new Set(rows.map(item => item.folder))];
      fileTypes = [...new Set(rows.map(item => item.fileName.substring(item.fileName.lastIndexOf('.') + 1, item.fileName.length)))]

      if (uploadfilter.length > 0) {
        if (rows) {
          rows = rows.filter(item => uploadfilter.includes(item.folder))
        }
      }

      if (past.length > 0) {
        if (rows) {
          var todaysDate = new Date();
          if (past[0] === "< 1 week") {
            todaysDate = new Date(todaysDate.getTime() - 7 * 24 * 60 * 60 * 1000);
          }
          else if (past[0] === "< 2 weeks") {
            todaysDate = new Date(todaysDate.getTime() - 2 * 7 * 24 * 60 * 60 * 1000);
          }
          rows = rows.filter(item => Date.parse(item.created) >= todaysDate);
        }
      }

      if (fileTypes.length > 1 && selectedFileTypes.length > 0) {
        if (rows) {
          rows = rows.filter(row => selectedFileTypes.includes(row.fileName.substring(row.fileName.lastIndexOf('.') + 1, row.fileName.length)))
        }
      }

      if (studyDetail.tableSort) {
        tableSort = studyDetail.tableSort;

        if (!folderLoading) {
          rows.sort(
            predicate(
              {
                fileName: tableSort[0],
                reverse: tableSort[1] === "asc" ? true : false
              }
            )
          );
        }
      }
    }
    return {
      studyId,
      studies,
      permissions,
      rows,
      total,
      fileTypes,
      selectedFileTypes,
      folderLoading,
      loading,
      cnt,
      allSelected,
      tableSort,
      selectedDate,
      selected,
      filter,
      past,
      preview,
      folders,
      fileNames,
      statuses,
      viewMode,
      folderTypes,
      uploadfilter,
      uploadFolders,
      selectedUploadDownloadfolder,
      regexExpression,
      uploadReceivedDate,
      uploadRefreshRequired,
      metadata
    };
  }
);

export const downloadtable = createSelector(
  [getStudyDetail, getStudies, selectedStudy],
  (studyDetail, studies, selectedStudy) => {
    let permissions = [],
      rows = [],
      cnt = 0,
      total = 0,
      allSelected = false,
      downloadRefreshRequired = false,
      downloadReceivedDate = null,
      loading = false,
      folderLoading = false,
      studyId = undefined,
      viewMode = undefined,
      tableSort = [],
      selectedDate,
      selected = -1,
      filter = [],
      past = [],
      preview = undefined,
      folders = [],
      fileNames = [],
      statuses = [],
      downloadFiles = [],
      downloadFolders = [],
      fileTypes = [],
      selectedFileTypes = [],
      selectedUploadDownloadfolder = [],
      regexExpression = "",
      payerFilter = undefined,
      payerFile = undefined,
      payerStudy = false,
      metadata = null;

    // we need filter options to be dynamic, both statuses and folder, and fileNames
    if (studyDetail) {
      preview = studyDetail.preview;
      if (studyDetail.dashboardPermissions) {
        permissions = studyDetail.dashboardPermissions.filter(perm => perm.selected === true).map(perm => perm.permission);
      }

      studyId = studyDetail.id;
      selectedDate = studyDetail.selectedDate;
      selected = selectedStudy;
      cnt = studyDetail.cnt;
      viewMode = studyDetail.viewMode
      if (studyDetail.downloadFiles) {
        total = studyDetail.downloadFiles.length;
      }
      if (studyDetail.filter) {
        filter = studyDetail.filter;
      }
      if (studyDetail.fileTypefilter) {
        selectedFileTypes = studyDetail.fileTypefilter
      }
      if (studyDetail.past) {
        past = studyDetail.past;
      }
      if (studyDetail.downloadFolders) {
        downloadFolders = studyDetail.downloadFolders
      }
      if (studyDetail.downloadMetadata) {
        metadata = studyDetail.downloadMetadata
      }

      if (studyDetail.loading) {
        loading = studyDetail.loading
      }
      if (studyDetail.folderLoading) {
        folderLoading = studyDetail.folderLoading
      }

      if (studyDetail.downloadRegexExpression) {
        regexExpression = studyDetail.downloadRegexExpression
      }

      if (studyDetail.downloadReceivedDate) {
        downloadReceivedDate = studyDetail.downloadReceivedDate
      }
      if (studyDetail.downloadRefreshRequired) {
        downloadRefreshRequired = studyDetail.downloadRefreshRequired
      }

      allSelected = resolveAllSelected(
        studyDetail.cnt,
        studyDetail.downloadFiles
      );

      if (studyDetail.downloadFiles) {
        folders = 'adam';
        fileNames = 'syvdat';

        if (studyDetail.payerFilter) {
          payerFilter = studyDetail.payerFilter
        }

        if (studyDetail.payerFile) {
          payerFile = studyDetail.payerFile
        }

        if (studyDetail.payerStudy) {
          payerStudy = studyDetail.payerStudy
        }

        rows = studyDetail.downloadFiles

        if (studyDetail.selectedUploadDownloadfolder) {
          selectedUploadDownloadfolder = studyDetail.selectedUploadDownloadfolder

          if (rows) {
            if (selectedUploadDownloadfolder.length > 0) {
              //rows = rows.filter(item => selectedUploadDownloadfolder.includes(item.folder));
              rows = rows.filter(item => (selectedUploadDownloadfolder.includes(item.folder) || selectedUploadDownloadfolder.includes(item.folder.toLowerCase()) || selectedUploadDownloadfolder.includes(item.folder.toUpperCase())))
            }
          }
        }


        downloadFiles = studyDetail.downloadFiles;
        fileNames = studyDetail.downloadFiles.map(item => item.fileName.split('.')[1]).reduce((unique, item) =>
          unique.includes(item) ? unique : [...unique, item], []);
        folders = studyDetail.downloadFiles.map(item => item.folder).reduce((unique, item) =>
          unique.includes(item) ? unique : [...unique, item], []);
        statuses = studyDetail.downloadFiles.map(item => item.status).reduce((unique, item) =>
          unique.includes(item) ? unique : [...unique, item], []);
      }

      fileTypes = [...new Set(rows.map(item => item.fileName.substring(item.fileName.lastIndexOf('.') + 1, item.fileName.length)))]


      if (past.length > 0) {
        if (rows) {
          var todaysDate = new Date();
          if (past[0] === "< 1 week") {
            todaysDate = new Date(todaysDate.getTime() - 7 * 24 * 60 * 60 * 1000);
          }
          else if (past[0] === "< 2 weeks") {
            todaysDate = new Date(todaysDate.getTime() - 2 * 7 * 24 * 60 * 60 * 1000);
          }
          rows = rows.filter(item => Date.parse(item.created) >= todaysDate);
        }
      }

      if (fileTypes.length > 1 && selectedFileTypes.length > 0) {
        if (rows) {
          rows = rows.filter(row => selectedFileTypes.includes(row.fileName.substring(row.fileName.lastIndexOf('.') + 1, row.fileName.length)))
        }
      }

      if (studyDetail.tableSort) {
        tableSort = studyDetail.tableSort;

        if (!folderLoading) {
          rows.sort(
            predicate(
              {
                fileName: tableSort[0],
                reverse: tableSort[1] === "asc" ? true : false
              }
            )
          );
        }
      }
    }
    return {
      studyId,
      studies,
      permissions,
      rows,
      total,
      folderLoading,
      loading,
      cnt,
      allSelected,
      tableSort,
      selectedDate,
      selected,
      filter,
      past,
      preview,
      folders,
      fileNames,
      statuses,
      viewMode,
      payerStudy,
      payerFile,
      payerFilter,
      downloadFiles,
      downloadFolders,
      fileTypes,
      selectedFileTypes,
      selectedUploadDownloadfolder,
      regexExpression,
      downloadRefreshRequired,
      downloadReceivedDate,
      metadata
    };
  }
);

export const releasetable = createSelector(
  [getStudyDetail, getStudies, selectedStudy],
  (studyDetail, studies, selectedStudy) => {
    let permissions = [],
      rows = [],
      cnt = 0,
      total = 0,
      releaseRefreshRequired = false,
      releaseReceivedDate = null,
      allSelected = false,
      tableLoading = true,
      folderLoading = false,
      pageLoading = false,
      loading = false,
      studyId = undefined,
      viewMode = undefined,
      tableSort = [],
      selectedDate,
      selected = -1,
      filter = [],
      past = [],
      preview = undefined,
      folders = [],
      fileNames = [],
      statuses = [],
      downloadFiles = [],
      payerFilter = undefined,
      fileTypes = [],
      selectedFileTypes = [],
      releaseFolders = [],
      selectedUploadDownloadfolder = [],
      regexExpression = "",
      metadata = null;

    // we need filter options to be dynamic, both statuses and folder, and fileNames
    if (studyDetail) {
      preview = studyDetail.preview;
      if (studyDetail.dashboardPermissions) {
        permissions = studyDetail.dashboardPermissions.filter(perm => perm.selected === true).map(perm => perm.permission);
      }
      studyId = studyDetail.id;
      viewMode = studyDetail.viewMode
      selectedDate = studyDetail.selectedDate;
      selected = selectedStudy;
      cnt = studyDetail.cnt;
      if (studyDetail.releaseFiles) {
        total = studyDetail.releaseFiles.length;
      }
      if (studyDetail.releaseMetadata) {
        metadata = studyDetail.releaseMetadata
      }
      if (studyDetail.filter) {
        filter = studyDetail.filter;
      }
      if (studyDetail.fileTypefilter) {
        selectedFileTypes = studyDetail.fileTypefilter
      }
      if (studyDetail.past) {
        past = studyDetail.past;
      }
      allSelected = resolveAllSelected(
        studyDetail.cnt,
        studyDetail.releaseFiles
      );
      tableLoading = studyDetail.releaseFiles ? false : true;

      if (studyDetail.releaseFolders) {
        releaseFolders = studyDetail.releaseFolders
      }

      if (studyDetail.loading) {
        loading = studyDetail.loading
      }
      if (studyDetail.folderLoading) {
        folderLoading = studyDetail.folderLoading
      }

      if (studyDetail.releaseRegexExpression) {
        regexExpression = studyDetail.releaseRegexExpression
      }

      if (studyDetail.releaseFiles) {
        folders = 'adam';
        fileNames = 'syvdat';

        if (studyDetail.payerFilter) {
          payerFilter = studyDetail.payerFilter
        }

        rows = studyDetail.releaseFiles;

        if (studyDetail.selectedUploadDownloadfolder) {
          selectedUploadDownloadfolder = studyDetail.selectedUploadDownloadfolder
          if (rows) {
            if (selectedUploadDownloadfolder.length > 0) {
              //rows = rows.filter(item => selectedUploadDownloadfolder.includes(item.folder));
              rows = rows.filter(item => (selectedUploadDownloadfolder.includes(item.folder) || selectedUploadDownloadfolder.includes(item.folder.toLowerCase()) || selectedUploadDownloadfolder.includes(item.folder.toUpperCase())))
            }
          }
        }

        if (studyDetail.releaseReceivedDate) {
          releaseReceivedDate = studyDetail.releaseReceivedDate
        }
        if (studyDetail.releaseRefreshRequired) {
          releaseRefreshRequired = studyDetail.releaseRefreshRequired
        }

        fileNames = studyDetail.releaseFiles.map(item => item.fileName.split('.')[1]).reduce((unique, item) =>
          unique.includes(item) ? unique : [...unique, item], []);
        folders = studyDetail.releaseFiles.map(item => item.folder).reduce((unique, item) =>
          unique.includes(item) ? unique : [...unique, item], []);
      }
      fileTypes = [...new Set(rows.map(item => item.fileName.substring(item.fileName.lastIndexOf('.') + 1, item.fileName.length)))]
      if (past.length > 0) {
        if (rows) {
          var todaysDate = new Date();
          if (past[0] === "< 1 week") {
            todaysDate = new Date(todaysDate.getTime() - 7 * 24 * 60 * 60 * 1000);
          }
          else if (past[0] === "< 2 weeks") {
            todaysDate = new Date(todaysDate.getTime() - 2 * 7 * 24 * 60 * 60 * 1000);
          }
          rows = rows.filter(item => Date.parse(item.created) >= todaysDate);
        }
      }

      if (fileTypes.length > 1 && selectedFileTypes.length > 0) {
        if (rows) {
          rows = rows.filter(row => selectedFileTypes.includes(row.fileName.substring(row.fileName.lastIndexOf('.') + 1, row.fileName.length)))
        }
      }

      if (studyDetail.tableSort) {
        tableSort = studyDetail.tableSort;

        if (!tableLoading) {
          rows.sort(
            predicate(
              {
                fileName: tableSort[0],
                reverse: tableSort[1] === "asc" ? true : false
              }
            )
          );
        }
      }
    }
    return {
      studyId,
      studies,
      permissions,
      rows,
      tableLoading,
      pageLoading,
      loading,
      cnt,
      total,
      allSelected,
      tableSort,
      selectedDate,
      selected,
      filter,
      past,
      preview,
      folders,
      fileNames,
      statuses,
      viewMode,
      payerFilter,
      downloadFiles,
      fileTypes,
      selectedFileTypes,
      releaseFolders,
      selectedUploadDownloadfolder,
      regexExpression,
      releaseRefreshRequired,
      releaseReceivedDate,
      folderLoading,
      metadata
    };
  }
);

const getFiles = state => state.files;
const getViewMode = state => state.viewMode;
const getCnt = state => state.cnt;
const getAllSelected = state => state.allSelected;
const getSorting = state => state.tableSort;
const isLoading = state => state.loading;

export const versionsTable = createSelector(
  [getFiles, getViewMode, getCnt, getAllSelected, getSorting, isLoading],
  (
    files,
    viewMode,
    currentCnt,
    currentAllSelected,
    currentSorting,
    isLoading
  ) => {
    let rows = [],
      cnt = currentCnt,
      allSelected = currentAllSelected,
      tableLoading = isLoading,
      tableSort = currentSorting;

    if (!tableLoading && files.length) {
      rows = files;
      if (tableSort && tableSort.length) {
        rows.sort(
          predicate(
            {
              name: tableSort[0],
              reverse: tableSort[1] === "asc" ? true : false
            }
          )
        );
      }
    }
    return {
      rows,
      tableLoading,
      cnt,
      allSelected,
      tableSort,
      viewMode
    };
  }
);

const resolveRunTime = props => {
  const { /*sasJobCreatedDate,*/ sasJobExecutedDate, sasJobStartedDate } = props;
  const now = new Date();
  if (!sasJobStartedDate) {
    return "statusPending";
  }
  if (!sasJobExecutedDate) {
    // nowDate versus sasJobCreatedDate more than two hours
    const hours = Math.floor(
      Math.abs(now - formatDateTime(sasJobStartedDate)) / 36e5
    );
    if (hours < 2) {
      return "statusDone";
    }
    return "statusBroken";
  } else {
    return "statusDone";
  }

  // pending
  // sasComputerId
};
export const sasJobs = createSelector(
  [getStudyDetail, getStudies, selectedStudy],
  (studyDetail, studies, selectedStudy) => {
    let rows = [],
      cnt = 0,
      total = 0,
      selected = -1,
      sasRefreshRequired = false,
      sasReceivedDate = null,
      allSelected = false,
      /*tableLoading = true,*/
      loading = false,
      folderLoading = false,
      /*pageLoading = false,*/
      studyId = undefined,
      past = [],
      tableSort = [],
      folderTypes = [],
      selectedfolders = [],
      selectedSasJobfolders = [],
      selectedrunStatus = [],
      selectedQCStatus = [],
      selectedSasRunStatus = [],
      sasFolders = [],
      sasjobsfolder = "",
      regexExpression = "",
      viewMode = "",
      metadata = null;

    if (studyDetail) {
      studyId = studyDetail.id;
      selectedfolders = studyDetail.sasfilter["folders"]
      selectedrunStatus = studyDetail.sasfilter["runStatus"]
      selectedQCStatus = studyDetail.sasfilter["qcStatus"]
      selectedSasRunStatus = studyDetail.sasfilter["sasRunStatus"]
      selected = selectedStudy;
      if (studyDetail.cnt) {
        cnt = studyDetail.cnt;
      }

      if (studyDetail.viewMode) {
        viewMode = studyDetail.viewMode
      }
      allSelected = studyDetail.allSASSelected;
      if (studyDetail.sasjobsFiles) {
        total = studyDetail.sasjobsFiles.length
      }
      if (studyDetail.sasFolders) {
        sasFolders = studyDetail.sasFolders
      }
      if (studyDetail.sasjobsMetadata) {
        metadata = studyDetail.sasjobsMetadata
      }

      if (studyDetail.loading) {
        loading = studyDetail.loading
      }
      if (studyDetail.folderLoading) {
        folderLoading = studyDetail.folderLoading
      }

      if (studyDetail.sasjobsRegexExpression) {
        regexExpression = studyDetail.sasjobsRegexExpression
      }

      if (studyDetail.past) {
        past = studyDetail.past
      }

      if (studyDetail.sasReceivedDate) {
        sasReceivedDate = studyDetail.sasReceivedDate
      }
      if (studyDetail.sasRefreshRequired) {
        sasRefreshRequired = studyDetail.sasRefreshRequired
      }

      rows =
        studyDetail.sasjobsFiles && studyDetail.sasjobsFiles.length
          ? studyDetail.sasjobsFiles.map(item => {
            return {
              ...item,
              status: (item.sasJobExecutedDate === null) ? "NOT RUN" : (item.sasLogError > 0) ? "ERRORS (" + item.sasLogError + ")" :
                (item.sasLogWarning > 0) ? "WARNINGS (" + item.sasLogWarning + ")" :
                  (item.sasLogUninitialized > 0) ? "UNINITIALIZED (" + item.sasLogUninitialized + ")" :
                    (item.sasLogMerge > 0) ? "MERGE CONFlICTS (" + item.sasLogMerge + ")" :
                      (item.sasLogMETAnote > 0) ? "META NOTE (" + item.sasLogMETAnote + ")" :
                        (item.sasLogError === null) ? "NOT RUN" : "OK",
              runStatus: (formatDateTime(item.sasJobStartedDate) === "") ? "Not started" : (formatDateTime(item.sasJobExecutedDate) === "") ? "Running" : "Run",
            };
          })
          : [];
      folderTypes = [...new Set(rows.map(item => item.folder))];

      if (studyDetail.selectedSasJobfolders) {
        selectedSasJobfolders = studyDetail.selectedSasJobfolders
        if (rows) {
          if (selectedSasJobfolders.length > 0) {
            rows = rows.filter(item => selectedSasJobfolders.includes(item.folder));
          }
          //else {
          //If nothing is selected then leave blank
          //  rows = []
          //}
        }
      }

      if (past.length > 0) {
        if (rows) {
          var todaysDate = new Date();
          if (past[0] === "< 1 week") {
            todaysDate = new Date(todaysDate.getTime() - 7 * 24 * 60 * 60 * 1000);
          }
          else if (past[0] === "< 2 weeks") {
            todaysDate = new Date(todaysDate.getTime() - 2 * 7 * 24 * 60 * 60 * 1000);
          }
          //console.log("filterting by " + past[0] + " with date " + todaysDate)
          rows = rows.filter(item => Date.parse(item.end) >= todaysDate);
          //console.log(rows)
        }
      }

    }
    return {
      studies,
      studyId,
      selected,
      rows,
      total,
      past,
      /*tableLoading,*/
      loading,
      folderLoading,
      /*pageLoading,*/
      cnt,
      allSelected,
      tableSort,
      selectedrunStatus,
      selectedfolders,
      selectedQCStatus,
      selectedSasRunStatus,
      selectedSasJobfolders,
      folderTypes,
      sasFolders,
      sasjobsfolder,
      regexExpression,
      sasReceivedDate,
      sasRefreshRequired,
      metadata,
      viewMode
    };
  }
);

export const sasExec = createSelector(
  [getStudyDetail, getStudies, selectedStudy],
  (studyDetail, studies, selectedStudy) => {
    let rows = [],
      cnt = 0,
      selected = -1,
      sasRefreshRequired = false,
      sasReceivedDate = null,
      tableLoading = true,
      loading = false,
      folderLoading = false,
      pageLoading = false,
      studyId = undefined,
      tableSort = [],
      folderTypes = [],
      sasFolders = [],
      sasjobsfolder = "",
      sasExecutionFolders = [[]],
      validationIssues = [],
      hasCycle = undefined,
      sasExecutionFiles = [[]];

    if (studyDetail) {
      studyId = studyDetail.id;
      selected = selectedStudy;
      if (studyDetail.cnt) {
        cnt = studyDetail.cnt;
      }
      hasCycle = studyDetail.hasCycle ?? undefined;
      if (studyDetail.validationIssues) { validationIssues = studyDetail.validationIssues }
      if (studyDetail.sasFolders) {
        sasFolders = studyDetail.sasFolders
      }

      if (studyDetail.sasExecutionFiles) {
        sasExecutionFiles = studyDetail.sasExecutionFiles
      }

      if (studyDetail.sasExecutionFolders) {
        sasExecutionFolders = studyDetail.sasExecutionFolders
      }

      if (studyDetail.sasjobsfolder) {
        sasjobsfolder = studyDetail.sasjobsfolder
      }

      if (studyDetail.loading) {
        loading = studyDetail.loading
      }
      if (studyDetail.folderLoading) {
        folderLoading = studyDetail.folderLoading
      }
      if (studyDetail.sasReceivedDate) {
        sasReceivedDate = studyDetail.sasReceivedDate
      }

      if (studyDetail.sasRefreshRequired) {
        sasRefreshRequired = studyDetail.sasRefreshRequired
      }

      rows =
        studyDetail.sasjobsFiles && studyDetail.sasjobsFiles.length
          ? studyDetail.sasjobsFiles.map(item => {
            return {
              id: item.sasJobId,
              folderOrder: 0,
              filenameOrder: 0,
              error: (item.sasLogError === null) ? 0 : item.sasLogError,
              executed: resolveRunTime(item)
            };
          })
          : [];
      folderTypes = [...new Set(rows.map(item => item.folder))];

      pageLoading = sasFolders.length > 0 ? false : true;
      tableLoading = (!pageLoading && rows.length > 0) ? false : true;

      if (
        !tableLoading &&
        rows.length &&
        studyDetail.sasTableSort &&
        studyDetail.sasTableSort.length
      ) {
        tableSort = studyDetail.sasTableSort;
        rows.sort(
          predicate(
            {
              folder: studyDetail.sasTableSort[0],
              name: studyDetail.sasTableSort[1]
            }
          )
        );
      }
    }
    return {
      studies,
      studyId,
      selected,
      rows,
      loading,
      folderLoading,
      cnt,
      tableSort,
      folderTypes,
      sasFolders,
      sasReceivedDate,
      sasRefreshRequired,
      sasExecutionFolders,
      sasExecutionFiles,
      sasjobsfolder,
      hasCycle,
      validationIssues
    };
  }
);
