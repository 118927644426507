import React, { Suspense, lazy } from 'react';
import { Switch } from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Route } from "react-router-dom";
import AuthenticatedRoute from "./routecomponents/AuthenticatedRoute";
//import MicroFrontend from './MicroFrontend'

const StudyDownloadsPage = lazy(() => import('./pages/StudyDownloadsPage'));
const StudyReleasePage = lazy(() => import('./pages/StudyReleasePage'));
const Studies = lazy(() => import('./pages/Studies'));
const Study = lazy(() => import('./pages/Study'));
const StudyPermissions = lazy(() => import('./pages/StudyPermissions'));
const SasPage = lazy(() => import('./pages/SasPage'));
const SasJob = lazy(() => import('./pages/SasJob'));
const SasExecution = lazy(() => import('./pages/SasExecution'));
const StudyUploadsPage = lazy(() => import('./pages/StudyUploadsPage'));
const UtilitiesPage = lazy(() => import('./pages/UtilitiesPage'));
const StudyFile = lazy(() => import('./pages/StudyFileV2'));
const Authentication = lazy(() => import('./auth/Authentication'));
const Meta = lazy(() => import('./auth/Meta'));
const Leo = lazy(() => import('./auth/Leo'));
const Products = lazy(() => import('./pages/ProductPage'));

/*
const {
  REACT_APP_EXPORT_HOST: exportHost,
  REACT_APP_COLLECT_HOST: collectHost,
  REACT_APP_SDTM_HOST: sdtmHost
} = process.env;
*/
/*
function Export({ history, jwt, studyId, sponsorGuid, studyName, studyGuid, url }) {
  return <MicroFrontend
    jwt={jwt}
    history={history}
    studyId={studyId}
    sponsorGuid={sponsorGuid}
    studyName={studyName}
    studyGuid={studyGuid}
    url={url}
    host={exportHost}
    name="Export"
  />;
}

function Collect({ history, jwt, studyId, sponsorGuid, studyName, studyGuid, url }) {
  return <MicroFrontend
    jwt={jwt}
    history={history}
    studyId={studyId}
    sponsorGuid={sponsorGuid}
    studyName={studyName}
    studyGuid={studyGuid}
    url={url}
    host={collectHost}
    name="Collect" />;
}
*/
/*
function Sdtm({ history, jwt, studyId, sponsorGuid, studyName, studyGuid, url }) {
  return <MicroFrontend
    jwt={jwt}
    history={history}
    studyId={0}
    sponsorGuid={"NA"}
    studyName={"NA"}
    studyGuid={"NA"}
    url={url}
    host={sdtmHost}
    name="Sdtmtemplate" />;
}*/

export default ({ childProps }) =>
  <Suspense fallback={
    <div
      style={{
        width: "3.125rem",
        height: "3.125rem",
        position: "absolute",
        top: '0.5rem',
        left: '0.5rem',
      }}
    >
      <CircularProgress size={35} />
    </div>}

  >
    <Switch>
      <Route path="/Auth" exact component={Authentication} childProps={childProps} />
      <Route path="/Meta" exact component={Meta} childProps={childProps} />
      <Route path="/Leo" exact component={Leo} childProps={childProps} />
      <Route path="/auth/:email?/:invitationid?" exact component={Authentication} childProps={childProps} />
      <AuthenticatedRoute path="/" exact component={Studies} childProps={childProps} />
      <AuthenticatedRoute path="/studies/:invitationid?" /*exact*/ component={Studies} childProps={childProps} />
      <AuthenticatedRoute path="/study:id" exact component={Study} childProps={childProps} />
      <AuthenticatedRoute path="/study:id/permissions" exact component={StudyPermissions} childProps={childProps} />
      <AuthenticatedRoute path="/study:id/sasexecution" exact component={SasExecution} childProps={childProps} />
      <AuthenticatedRoute path="/study:id/sasjobs" exact component={SasPage} childProps={childProps} />
      <AuthenticatedRoute path="/study:id/sasjob/:id" exact component={SasJob} childProps={childProps} />
      <AuthenticatedRoute path="/study:id/utilities" exact component={UtilitiesPage} childProps={childProps} />
      <AuthenticatedRoute path="/study:id/uploads" exact component={StudyUploadsPage} childProps={childProps} />
      <AuthenticatedRoute path="/study:id/downloads" exact component={StudyDownloadsPage} childProps={childProps} />
      <AuthenticatedRoute path="/study:id/release" exact component={StudyReleasePage} childProps={childProps} />
      <AuthenticatedRoute path="/study:id/studyFile/:fileType/:studyFileId" exact component={StudyFile} childProps={childProps} />
      {/*<AuthenticatedRoute exact path="/utility/collect" component={Collect} childProps={childProps} />*/}
      <AuthenticatedRoute path="/new" exact component={Products} childProps={childProps} />
    </Switch>
  </Suspense>
  ;