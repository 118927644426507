import * as actions from "../actions/";
import {
  takeLatest,
  call,
  put,
  getContext,
  select,
  delay
} from "redux-saga/effects";
import { Auth } from "aws-amplify";

async function signOut() {
  return await Auth.signOut({ global: true })
}
export function* logout(action) {
  try {
    yield put(actions.authenticated(false));
    yield put(actions.clearStudiesStudyDetail());
    yield put(actions.userProfileSignout());
    yield call(signOut);
    const { history } = action.payload;
    history.push("/auth");
  } catch (error) {
    // dispatch a failure action to the store with the error
    console.log("error in resp ", error);
  }
}
function* refreshTokenMonitor() {

  while (true) {
    const state = yield select();
    //const loginService = yield getContext("loginService");
    const { isAuthenticated } = state.root;

    if (isAuthenticated) {
      try {
        //yield call(Auth.currentSession())
        //yield call(loginService.renewToken);
        yield delay(15 * 60 * 1000); // 15 minutes
        yield put(actions.loginInitializedTokenMonitor());

      } catch (error) {
        console.log("error in resp ", error);
      }
    }
  }
}
const resolveApiResponse = res => {
  if ((res.status && res.status === 504) || (res.status && res.status === 404) || (res.status && res.status === 400)) {
    return false;
  }
  return true;
};
function* loginApi(action) {
  const { cognitoUser, id } = action.payload

  try {
    const api = yield getContext("api");
    const res = yield call(api.put, "customer/LoginCustomer");

    //user exists in db so he has been registered
    if (res && !res.status) {
      const user = yield call(api.get, "customer/GetCustomer");

      //console.log(cognitoUser)

      if (resolveApiResponse(user)) {

        yield put(actions.userProfileReceiveCurrentUser(user, cognitoUser));
        yield put(actions.authenticated(true));
        yield put(actions.userSetMfaStatus(cognitoUser.preferredMFA && cognitoUser.preferredMFA !== "NOMFA" ? true : false))

        if (id) {
          yield put(actions.userProfileResolveInvitation(true, id))
        }

        //yield put(actions.getStudies());
        yield put(actions.getStudiesV2());
        yield delay(200);
        yield put(actions.userProfileGetStudyInvitations());
        yield put(actions.loginInitializedTokenMonitor());

        yield put(actions.userAttributesReceive(cognitoUser.attributes));
      } else {
        console.log(user)
        //yield call(delay, 40000);
        //yield put(actions.loginUserApi());
      }
    }
    //user does not exist in db
    else if (res && res.status === 408) {
      //yield put(actions.loginStatusSet("Synchronizing user data, please wait"))
      //console.log("408 recieved waiting 3 seconds")
      yield delay(3000)
      //console.log("3 seconds up calling API again")
      yield put(actions.loginUserApi(cognitoUser, id))
    }
    //user does not exist in db
    else if (res && res.status === 404) {
      const registered = yield call(api.get, "customer/RegisterCustomer");
      //yield put(actions.receiveCurrentUser(registered));

      if (resolveApiResponse(registered)) {
        yield put(actions.userProfileReceiveCurrentUser(registered, cognitoUser));
        yield put(actions.authenticated(true));

        if (id) {
          yield put(actions.userProfileResolveInvitation(true, id))
        }

        //yield put(actions.getStudies());
        yield put(actions.getStudiesV2());
        yield delay(200);
        yield put(actions.userProfileGetStudyInvitations());
        yield put(actions.loginInitializedTokenMonitor());
        yield put(actions.userAttributesReceive(cognitoUser.attributes));
      } else {
        //yield delay(200);
        //yield put(actions.loginUserApi());
        console.log(registered)
      }
    }
  } catch (error) {
    console.log("error in resp ", error);
    // what error messages do we get 401 ,server asleep, or not authenticated 504 Gateway Timeout (504)
    // 403 forbidden
    yield put(actions.authenticated(false)); // bAD way fix, only temporary
  }
}

export function* loginSagas() {
  yield takeLatest(actions.LOGOUT_SELECTED, logout);
  yield takeLatest(actions.LOGIN_INITIALIZED_TOKEN_MONITOR, refreshTokenMonitor);
  yield takeLatest(actions.LOGIN_USER_API, loginApi);
}
