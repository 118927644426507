export const CREATE_STUDY_UPDATE_INPUT = 'CREATE_STUDY_UPDATE_INPUT';
export const CREATE_STUDY_PROJECT_LABELS_LOAD = 'CREATE_STUDY_PROJECT_LABELS_LOAD';
export const CREATE_STUDY_PROJECT_LABELS_RECEIVED = 'CREATE_STUDY_PROJECT_LABELS_RECEIVED';
export const CREATE_STUDY_SUBMIT = 'CREATE_STUDY_SUBMIT';
export const CREATE_STUDY_STUDY_CREATED = 'CREATE_STUDY_STUDY_CREATED';
export const CREATE_STUDY_VALIDATION_ERROR = 'CREATE_STUDY_VALIDATION_ERROR';
export const CREATE_STUDY_RESET = 'CREATE_STUDY_RESET';
export const CREATE_STUDY_TOGGLE_MODAL = 'CREATE_STUDY_TOGGLE_MODAL';

export const createStudyToggleModal = (metaPackage) => ({
    type: CREATE_STUDY_TOGGLE_MODAL,
    payload: { metaPackage }
});
export const createStudyUpdateInput = (prop, value) => ({
    type: CREATE_STUDY_UPDATE_INPUT,
    payload: { prop, value }
});
export const createStudyProjectLabelsLoad = () => ({
    type: CREATE_STUDY_PROJECT_LABELS_LOAD,
    payload: {}
});
export const createStudyProjectLabelsReceived = labels => ({
    type: CREATE_STUDY_PROJECT_LABELS_RECEIVED,
    payload: { labels }
});
export const createStudySubmit = () => ({
    type: CREATE_STUDY_SUBMIT,
    payload: {}
});
export const createStudyStudyCreated = res => ({
    type: CREATE_STUDY_STUDY_CREATED,
    payload: { res }
});
export const createStudyReset = () => ({
    type: CREATE_STUDY_RESET,
    payload: {}
});
export const createStudyValidationError = errorMessage => ({
    type: CREATE_STUDY_VALIDATION_ERROR,
    payload: { errorMessage }
});