import React from 'react';
import {
  Route,
  Redirect,
} from 'react-router-dom';
import { Auth } from 'aws-amplify';

class AuthenticatedRoute extends React.Component {
  state = {
    loaded: false,
    isAuthenticated: false,
    cognitoUser: null,
    jwt: ""
  };

  async componentDidMount() {
    const { history } = this.props.childProps
    try {
      const cognitoUser = await Auth.currentAuthenticatedUser()

      if (!cognitoUser.attributes) {
        let { signInUserSession } = cognitoUser
        let { idToken } = signInUserSession
        let attributes = idToken.payload
        cognitoUser.attributes = attributes
      }

      const jwt = (await Auth.currentSession()).getIdToken().getJwtToken()
      this.setState({ loaded: true, isAuthenticated: true, cognitoUser: cognitoUser, jwt: jwt });
    }
    catch {
      history.push('/Auth')
    }
  }

  render() {
    const { component: Component, childProps, external, ...rest } = this.props;
    const { history, studyId, sponsorGuid, studyName, studyGuid, url } = childProps
    const { location } = history
    const { loaded, isAuthenticated, cognitoUser, jwt } = this.state;
    if (!loaded) return null;
    return (
      <Route
        {...rest}
        render={props => {
          return isAuthenticated ? (
            <>
              {location.pathname !== "/" ?
                <Component
                  cognitoUser={cognitoUser}
                  studyId={studyId}
                  jwt={jwt}
                  sponsorGuid={sponsorGuid}
                  studyName={studyName}
                  studyGuid={studyGuid}
                  url={url}
                  {...props} /> :
                <Redirect
                  to={{
                    pathname: '/Studies'
                  }}
                />
              }
            </>
          ) : (
            <Redirect
              to={{
                pathname: '/Auth'
              }}
            />
          );
        }}
      />
    );
  }
}

export default AuthenticatedRoute;