import * as actions from "../actions/userProfile";
import { createInitials, resolveShowMailNotification } from "../utils/helpers";
const DEFAULT_STATE = {
  loading: false,
  contactName: undefined,
  company: undefined,
  cvr: undefined,
  id: undefined,
  email: undefined,
  userEmail: undefined,
  phone: undefined,
  cognitoUserId: undefined,
  customerId: undefined,
  studyInvites: [],
  open: false,
  inboxOpen: false,
  confirmUserAttributesModal: false,/* holder for password field or phone number field, including verification fields  */
  showMailNotificationBadgde: false,
  errorMessage: [],
  mfaEnabled: false,
  phoneConfirmed: false,
  emailConfirmed: false,
  contactNameSet: false,
  CompanySet: false,
};


export const root = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case actions.USER_PROFILE_SUBMIT: {
      return {
        ...state,
        loading: true
      };
    }
    case actions.USER_SET_MFA_STATUS: {
      const { mfaEnabled } = action.payload;
      return {
        ...state,
        mfaEnabled: mfaEnabled
      };
    }
    case actions.USER_PROFILE_TOGGLE_DRAWER: {
      return {
        ...state,
        open: !state.open
      };
    }
    case actions.USER_PROFILE_VALIDATION_ERROR: {
      return {
        ...state,
        errorMessage: action.payload.errorMessage,
        validateErrors: true
      };
    }
    case actions.USER_PROFILE_SUBMIT_SUCCESS: {
      return {
        ...state,
        loading: false,
        initials: (state.contactName) ? createInitials(state.contactName) : "?"
      };
    }
    case actions.USER_PROFILE_UPDATE_INPUT: {
      const { prop, value } = action.payload;
      let errorMessage = state.errorMessage;
      let isValid = true;
      if (errorMessage.length || state.validateErrors) {
        if (prop === "contactName" || prop === "company") {
          if (!value || value.length < 2) {
            isValid = false;
          }
        } else {
          if (value.length !== 10) {
            isValid = false;
          }
        }
        if (isValid) {
          errorMessage = errorMessage.filter(item => item !== prop);
        } else {
          if (errorMessage.length === 0 || !errorMessage.includes(prop)) {
            errorMessage.push(prop);
          }
        }
      }

      return {
        ...state,
        [prop]: value,
        errorMessage: errorMessage
      };
    }
    case actions.USER_PROFILE_SIGNOUT: {
      return DEFAULT_STATE;
    }

    case actions.USER_PROFILE_RECEIVE_CURRENT_USER: {
      const { user, cognitoUser } = action.payload;
      const {
        cognitoUserId,
        company,
        contactName,
        customerId,
        cvr,
        email
      } = user;

      let provider = "NOTSAML"
      const { email_verified, phone_number_verified, identities } = cognitoUser.attributes

      if (identities) {
        let identity = JSON.parse(identities)[0];
        if (identity.providerType === "SAML") {
          provider = "SAML"
        }
      }

      return {
        ...state,
        id: customerId,
        customerId,
        cognitoUserId,
        cvr,
        contactName,
        email,
        company,
        initials: contactName ? createInitials(contactName) : "?",
        contactNameSet: (contactName) ? true : false,
        CompanySet: (company) ? true : false,
        phoneConfirmed: (provider === "SAML") ? true : phone_number_verified,
        emailConfirmed: (provider === "SAML") ? true : email_verified,
      };
    }

    case actions.USER_PROFILE_RESOLVE_INVITATION: {
      const { studyInvitationId } = action.payload;

      const transformedInvites = state.studyInvites && state.studyInvites.length
        ? state.studyInvites.map(item => {
          let invite = { ...item };

          if (item.studyUserInvitationId === studyInvitationId) {
            invite.loading = true;
          }
          return invite;
        })
        : [];
      return {
        ...state,
        studyInvites: transformedInvites
      };
    }
    case actions.USER_PROFILE_STUDY_INVITATIONS_RECEIVE: {
      const { studyInvites } = action.payload;
      const rejected =
        studyInvites && studyInvites.length ?
          studyInvites.filter(item => item.invitationRejectedDateTime) : [];

      return {
        ...state,
        showMailNotificationBadgde: resolveShowMailNotification(
          studyInvites,
          rejected
        ),
        studyInvites: studyInvites
      };
    }

    case actions.USER_PROFILE_TOGGLE_INBOX: {
      return {
        ...state,
        inboxOpen: !state.inboxOpen
      };
    }
    case actions.USER_PROFILE_ATTRIBUTES_RECEIVE: {
      const { userAttributes } = action.payload;
      return {
        ...state,
        userAttributes: userAttributes
      };
    }

    case actions.USER_PROFILE_EDIT_FIELD: {
      const { field } = action.payload;

      let title = "Password change";
      let type = 'password';

      if (field === 'phone_number') {
        title = "Verify Phone number";
        type = 'phoneNumber';
      }

      //if (field === 'email') {
      //  title = "Verify email";
      //  type = 'email';
      //}

      return {
        ...state,
        // open: false, /* all depending if sidebar should stay open in the background */
        confirmUserAttributesModal: {
          field: field,
          title: title,
          verificationCodeRequired: false,
          userVerified: false,/* whether to show password again */
          password: '',
          userName: '',
          oldPassword: '',
          mode: undefined,
          phone: state.userAttributes ? state.userAttributes.phone_number : '',
          phoneVerified: state.userAttributes ? state.userAttributes.phone_number_verified === 'true' ? true : false : false,
          type: type,
          email: state.email
        },
      };
    }

    case actions.USER_PROFILE_PASSWORD_ERROR_MESSAGE: {
      const { message } = action.payload;
      return {
        ...state,
        confirmUserAttributesModal: {
          ...state.confirmUserAttributesModal,
          errorMessage: message,
          loading: false
        }
      }
    }
    case actions.USER_PROFILE_ATTRIBUTE_CHANGE_SUCCESS: {
      return {
        ...state,
        confirmUserAttributesModal: {
          ...state.confirmUserAttributesModal,
          changeSuccess: true,
          errorMessage: null,
          loading: false
        }
      }
    }

    case actions.USER_PROFILE_CLOSE_ATTRIBUTE_MODAL: {
      return {
        ...state,
        confirmUserAttributesModal: null
      }
    }

    case actions.USER_PROFILE_UPDATE_SUBMITTED: {
      return {
        ...state,
        confirmUserAttributesModal: {
          ...state.confirmUserAttributesModal,
          loading: true
        }
      }
    }

    case actions.USER_PROFILE_VERIFY_PHONE_NUMBER: {
      return {
        ...state,
        confirmUserAttributesModal: {
          ...state.confirmUserAttributesModal,
          loading: true,
          mode: 'verify'
        }
      }
    }

    default:
      return state;
  }
};
