import React from "react";
import PropTypes from "prop-types";
import * as actions from "../../actions/";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import ReactDiffViewer from 'react-diff-viewer';
import { highlight, languages } from "prismjs/components/prism-core";
import "prismjs/components/prism-sas";
import Modal from "./Modal";
import { timeDifference } from "../../utils/date";

const styles = theme => ({
  container: {
    border: "1px solid #e0dddd",
    marginTop: "1.5rem"
  },
  modalHeader: {
    margin: "0 0 0 1rem",
    ...theme.typography,
    fontSize: "1rem",
    color: "#FFE000"
  },
  paper: {
    borderRadius: "8px"
  },
  diffViewer: {
    maxEidth: "90%",
    margin: "0 auto",
    borderRadius: "8px",
    overflowX: "auto",
    overflowY: "hidden",
    whiteSpace: "nowrap",
    boxShadow: "0 0 30px #1c1e25",
    fontSize: 10,
    fontFamily: "'Courier New', monospace"
  }
});

//

// reload current view oíf delete files or update
class FileComparerModal extends React.PureComponent {

  highlightSyntax = (str) => {
    if (str !== undefined) {
      return (
        <pre style={{ display: 'inline' }}
          dangerouslySetInnerHTML={{ __html: highlight(str, languages.sas) }}
        />
      )
    }
  }

  render() {
    const { classes, sasJobroot, root, actions } = this.props;
    let newCode, newTitle, oldCode, oldTitle, type;
    const { sasJob, Comparer } = sasJobroot
    const { studyDetail } = root

    if (studyDetail && studyDetail.Comparer) {
      type = "studyFile"
      newCode = (studyDetail) ? studyDetail.fileContent : ""
      newTitle = (studyDetail) ? studyDetail.file.folder + "/" + studyDetail.file.fileName + " - pushed " + timeDifference(studyDetail.file.uploadDateTime) : ""
      oldCode = (studyDetail) ? studyDetail.Comparer : ""
      oldTitle = (studyDetail) ? studyDetail.compareFile.type + "/" + studyDetail.compareFile.name + " - pushed " + timeDifference(studyDetail.compareFile.created) : ""
    }

    if (sasJob && Comparer) {
      type = "sasJob"
      newCode = (sasJob) ? sasJob.sasCode : ""
      newTitle = (sasJob) ? sasJob.folder + "/" + sasJob.fileName + " - pushed " + timeDifference(sasJob.sasJobCreatedDate) : ""
      oldCode = (Comparer) ? Comparer.sasCode : ""
      oldTitle = (Comparer) ? Comparer.folder + "/" + Comparer.fileName + " - pushed " + timeDifference(Comparer.sasJobCreatedDate) : ""
    }

    return (
      <Modal
        fullScreen
        titleBorder
        onClose={(type === "sasJob") ? actions.fileComparerSasReset : actions.fileComparerStudyFileReset}
        classes={classes}
        title={"File compare"}
        titleBackground
        /*titleDark*/
        maxWidth="lg"
        open={true}
      >
        <div className={classes.container}>
          <div className={classes.diffViewer}>
            {(type === "sasJob") ?
              <ReactDiffViewer
                oldValue={newCode}
                newValue={oldCode}
                splitView={true}
                showDiffOnly={false}
                renderContent={this.highlightSyntax}
                leftTitle={newTitle}
                rightTitle={oldTitle}
              />
              :
              <ReactDiffViewer
                oldValue={newCode}
                newValue={oldCode}
                splitView={true}
                showDiffOnly={false}
                leftTitle={newTitle}
                rightTitle={oldTitle}
              />
            }
          </div>
        </div>
      </Modal>
    );
  }
}

// we need a selector, route change should kill all modals
FileComparerModal.propTypes = {
  classes: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired
};

function mapStateToProps(state) {
  return {
    sasJobroot: state.sasJob,
    root: state.root
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles)(FileComparerModal))
);
