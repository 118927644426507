export const UPLOAD_INITIATED = 'UPLOAD_INITIATED';
export const UPLOAD_PROGRESS = 'UPLOAD_PROGRESS';
export const UPLOAD_FAILED = 'UPLOAD_FAILED';
export const UPLOAD_SUCCESS = 'UPLOAD_SUCCESS';
export const TOASTS_CLOSE = 'TOASTS_CLOSE';
export const UPDATE_MESSAGE = 'UPDATE_MESSAGE'
export const INITIATE_MESSAGE = 'INITIATE_MESSAGE'
export const FINAL_MESSAGE = 'FINAL_MESSAGE'
export const INITIATE_AUTH_MESSAGE = 'INITIATE_AUTH_MESSAGE'

export const API_ERROR = 'API_ERROR';
export const toastClose = (id) => ({
    type: TOASTS_CLOSE,
    payload: { id }
});

export const onApiError = () => ({
    type: API_ERROR,
    payload: {}
});
// could be upload download, change naming
export const uploadInitiated = (id, studyId, type) => ({
    type: UPLOAD_INITIATED,
    payload: { id, studyId, type }
});

export const uploadProgress = (id, progress) => ({
    type: UPLOAD_PROGRESS,
    payload: { id, progress }
});

export const updateMessage = (id, message) => ({
    type: UPDATE_MESSAGE,
    payload: { id, message }
});
export const initiateMessage = (id, studyId, message) => ({
    type: INITIATE_MESSAGE,
    payload: { id, studyId, message }
})

export const initiateAuthMessage = (id, message, variant) => ({
    type: INITIATE_AUTH_MESSAGE,
    payload: { id, message, variant }
})

export const finalMessage = (id, message, variant) => ({
    type: FINAL_MESSAGE,
    payload: { id, message, variant }
})

export const uploadSuccess = id => ({
    type: UPLOAD_SUCCESS,
    payload: { id }
});

export const uploadFailed = id => ({
    type: UPLOAD_FAILED,
    payload: { id }
});


