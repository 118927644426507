export const USER_PROFILE_UPDATE_INPUT = "USER_PROFILE_UPDATE_INPUT";
export const USER_PROFILE_SUBMIT = "USER_PROFILE_SUBMIT";
export const USER_PROFILE_VALIDATION_ERROR = "USER_PROFILE_VALIDATION_ERROR";
export const USER_PROFILE_SUBMIT_SUCCESS = "USER_PROFILE_SUBMIT_SUCCESS";
export const USER_PROFILE_RECEIVE_CURRENT_USER =
  "USER_PROFILE__RECEIVE_CURRENT_USER";
export const USER_PROFILE_GET_STUDY_INVITATIONS =
  "USER_PROFILE_GET_STUDY_INVITATIONS";
export const USER_PROFILE_STUDY_INVITATIONS_RECEIVE =
  "USER_PROFILE_STUDY_INVITATIONS_RECEIVE";
export const USER_PROFILE_RESOLVE_INVITATION =
  "USER_PROFILE_RESOLVE_INVITATION";
export const USER_PROFILE_VIEW_INBOX = "USER_PROFILE_VIEW_INBOX";
export const USER_PROFILE_TOGGLE_DRAWER = "USER_PROFILE_TOGGLE_DRAWER";
export const USER_PROFILE_TOGGLE_INBOX = "USER_PROFILE_TOGGLE_INBOX";

export const USER_TOGGLE_MFA = "USER_TOGGLE_MFA";
export const USER_SET_MFA_STATUS = "USER_SET_MFA_STATUS";
export const USER_PROFILE_SIGNOUT = "USER_PROFILE_SIGNOUT";

export const USER_PROFILE_EDIT_FIELD = "USER_PROFILE_EDIT_FIELD";
export const USER_PROFILE_ATTRIBUTES_RECEIVE =
  "USER_PROFILE_ATTRIBUTES_RECEIVE";

export const USER_PROFILE_UPDATE_PASSWORD = "USER_PROFILE_UPDATE_PASSWORD";
export const USER_PROFILE_UPDATE_OLD_PASSWORD =
  "USER_PROFILE_UPDATE_OLD_PASSWORD";

export const USER_PROFILE_PASSWORD_UPDATE_SUBMIT =
  "USER_PROFILE_PASSWORD_UPDATE_SUBMIT";

export const USER_PROFILE_PASSWORD_ERROR_MESSAGE =
  "USER_PROFILE_PASSWORD_ERROR_MESSAGE";
export const USER_PROFILE_PHONE_NUMBER_ERROR_MESSAGE =
  "USER_PROFILE_PHONE_NUMBER_ERROR_MESSAGE";
export const USER_PROFILE_ATTRIBUTE_CHANGE_SUCCESS =
  "USER_PROFILE_ATTRIBUTE_CHANGE_SUCCESS";

export const USER_PROFILE_CLOSE_ATTRIBUTE_MODAL =
  "USER_PROFILE_CLOSE_ATTRIBUTE_MODAL";

export const USER_PROFILE_UPDATE_SUBMITTED = 'USER_PROFILE_UPDATE_SUBMITTED'
//Phone verification
export const USER_PROFILE_VERIFY_PHONE_NUMBER = 'USER_PROFILE_VERIFY_PHONE_NUMBER';
export const USER_PROFILE_PHONE_NUMBER_VERIFIED = 'USER_PROFILE_PHONE_NUMBER_VERIFIED'

//Email verification
export const USER_PROFILE_VERIFY_EMAIL = 'USER_PROFILE_VERIFY_EMAIL'
export const USER_PROFILE_EMAIL_VERIFIED = 'USER_PROFILE_EMAIL_VERIFIED'


export const USER_PROFILE_CHANGE_PASSWORD = 'USER_PROFILE_CHANGE_PASSWORD'
//export const USER_PROFILE_PHONE_NUMBER_VERIFIED = 'USER_PROFILE_PHONE_NUMBER_VERIFIED'
//export const USER_PROFILE_EMAIL_PASSWORD_VERIFIED = 'USER_PROFILE_EMAIL_PASSWORD_VERIFIED'
//export const USER_PROFILE_CHANGE_PASSWORD_SUBMIT = 'USER_PROFILE_CHANGE_PASSWORD_SUBMIT'
//export const USER_PROFILE_CHANGE_PASSWORD_INITIATE = 'USER_PROFILE_CHANGE_PASSWORD_INITIATE'

export const userProfileUpdateSubmitted = () => ({
  type: USER_PROFILE_UPDATE_SUBMITTED,
  payload: {}
})

export const userProfileCloseAttributeModal = () => ({
  type: USER_PROFILE_CLOSE_ATTRIBUTE_MODAL,
  payload: {}
});

export const userProfileAttributeChangeSuccess = () => ({
  type: USER_PROFILE_ATTRIBUTE_CHANGE_SUCCESS,
  payload: {}
});


export const userProfilePasswordErrorMessage = message => ({
  type: USER_PROFILE_PASSWORD_ERROR_MESSAGE,
  payload: { message }
});


export const userProfilePasswordSubmit = () => ({
  type: USER_PROFILE_PASSWORD_UPDATE_SUBMIT,
  payload: {}
});

export const userUpdatePassword = password => ({
  type: USER_PROFILE_UPDATE_PASSWORD,
  payload: { password }
});
export const userUpdateOldPassword = password => ({
  type: USER_PROFILE_UPDATE_OLD_PASSWORD,
  payload: { password }
});

export const userAttributesReceive = userAttributes => ({
  type: USER_PROFILE_ATTRIBUTES_RECEIVE,
  payload: { userAttributes }
});
export const userProfileEditField = field => ({
  type: USER_PROFILE_EDIT_FIELD,
  payload: { field }
});

/* MFA Toggle actions */
export const userSetMfaStatus = mfaEnabled => ({
  type: USER_SET_MFA_STATUS,
  payload: { mfaEnabled }
});
export const userToggleMFA = (mfaEnabled, phoneConfirmed) => ({
  type: USER_TOGGLE_MFA,
  payload: { mfaEnabled, phoneConfirmed }
});

export const userProfileToggleDrawer = () => ({
  type: USER_PROFILE_TOGGLE_DRAWER,
  payload: {}
});
export const userProfileToggleInbox = () => ({
  type: USER_PROFILE_TOGGLE_INBOX,
  payload: {}
});

export const userProfileUpdateInput = (prop, value) => ({
  type: USER_PROFILE_UPDATE_INPUT,
  payload: { prop, value }
});
export const userProfileSubmit = (type, value) => ({
  type: USER_PROFILE_SUBMIT,
  payload: { type, value }
});
export const userProfileValidationError = errorMessage => ({
  type: USER_PROFILE_VALIDATION_ERROR,
  payload: { errorMessage }
});
export const userProfileSubmitSuccess = () => ({
  type: USER_PROFILE_SUBMIT_SUCCESS,
  payload: {}
});
export const userProfileReceiveCurrentUser = (user, cognitoUser) => ({
  type: USER_PROFILE_RECEIVE_CURRENT_USER,
  payload: { user, cognitoUser }
});

export const userProfileGetStudyInvitations = () => ({
  type: USER_PROFILE_GET_STUDY_INVITATIONS,
  payload: {}
});
export const userProfileStudyInvitationsReceive = studyInvites => ({
  type: USER_PROFILE_STUDY_INVITATIONS_RECEIVE,
  payload: { studyInvites }
});
export const userProfileResolveInvitation = (
  accept = true,
  studyInvitationId
) => ({
  type: USER_PROFILE_RESOLVE_INVITATION,
  payload: { accept, studyInvitationId }
});
export const userProfileViewInbox = () => ({
  type: USER_PROFILE_VIEW_INBOX,
  payload: {}
});
export const userProfileSignout = () => ({
  type: USER_PROFILE_SIGNOUT,
  payload: {}
});

/* Phone number actions */
export const userProfileVerifyPhoneNumber = (verificationCode) => ({
  type: USER_PROFILE_VERIFY_PHONE_NUMBER,
  payload: { verificationCode }
});

export const userProfilePhoneNumberVerified = () => ({
  type: USER_PROFILE_PHONE_NUMBER_VERIFIED,
  payload: {}
});

export const userProfilePhoneNumberErrorMessage = message => ({
  type: USER_PROFILE_PHONE_NUMBER_ERROR_MESSAGE,
  payload: { message }
});

/* Email actions */
export const userProfileVerifyEmail = (verificationCode) => ({
  type: USER_PROFILE_VERIFY_EMAIL,
  payload: { verificationCode }
})

export const userProfileEmailVerified = () => ({
  type: USER_PROFILE_EMAIL_VERIFIED,
  payload: {}
});

/* password actions */
export const userProfileChangePassword = (verificationCode, password) => ({
  type: USER_PROFILE_CHANGE_PASSWORD,
  payload: { verificationCode, password }
});