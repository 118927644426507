import "./app.scss";
import React from "react";
import * as actions from "../actions/";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Sidebar from "../navigation/Sidebar";
import Navigation from "../navigation/Navigation";
import Bottombar from "../navigation/Bottombar";
import Routes from "../routes";
//import CircularProgress from "@material-ui/core/CircularProgress";
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import createTheme from "@material-ui/core/styles/createTheme";
import IdleTimer from "react-idle-timer";
import blueGrey from "@material-ui/core/colors/blueGrey";
import pink from "@material-ui/core/colors/pink";
import CssBaseline from "@material-ui/core/CssBaseline";
import ToastWrapper from "../pages/components/ToastWrapper";
import UserProfile from "../pages/UserProfile";
import Inbox from "../pages/components/Inbox";
import FileVersionsModal from "../pages/modals/FileVersionsModal";
import FileReviewModal from "../pages/modals/FileReviewModal";
import FileComparerModal from "../pages/modals/FileComparerModal";
import IdleModal from "../pages/modals/IdleModal";
import VerifyAndUpdateUserField from "../pages/modals/VerifyAndUpdateUserField";
import Grid from '@material-ui/core/Grid';
import { Amplify } from 'aws-amplify';
//import { Auth } from "aws-amplify";
import awsExports from './aws-exports';
//3.
//Polyfill IE
import "core-js";
// https://stackoverflow.com/questions/46016415/wheres-hashhistory-in-react-router-v4
// https://medium.com/front-end-hacking/webpack-and-dynamic-imports-doing-it-right-72549ff49234
// progresd to dhow on how far they are with translation, section based
// notifications, collaborative, sockets ?
const timer = 1000 * 60 * 15; //1000 * 10; //1000 * 60 * 15;
const theme = createTheme({
  typography: {
    useNextVariants: true,
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(","),
    fontSize: 10
  },
  palette: {
    primary: {
      lightest: "#91d1ec",
      lighter: "#0273b5" /*blueGrey[100]*/ /*'#91d1ec'*/,
      light: "#0273b5" /*'#91ecd3'/*blueGrey[300]*/,
      main: "#0273b5" /*teal[500]*/,
      dark: blueGrey[700]
    },
    secondary: {
      light: blueGrey[300],
      main: blueGrey[500],
      dark: blueGrey[700]
    },
    error: {
      main: pink[500],
      light: "pink"
    },
    warning: {
      main: pink[500],
      light: "red"
    },
    type: "light"
  },
  overrides: {
    MuiTableCell: {
      root: {
        borderBottom: "1px solid rgba(224, 224, 224, 0.5)"
      }
    },
    MuiList: {
      padding: {
        paddingTop: 0,
        paddingBottom: "10px"
      }
    },
    MuiFormHelperText: {
      root: {
        position: "absolute",
        top: "2.55rem",
        left: 0,
        width: "100%",
        color: "red",
        "&$error": {
          color: "red"
        }
      }
    },
    MuiFilledInput: {
      root: {
        padding: 0,
        "&:hover": {
          backgroundColor: "white" /*"rgba(0, 0, 0, 0)"*/
          // boxShadow: "0 1px 4px 4px rgba(222, 220, 221, 0.1)"
        },
        "&$focused": {
          padding: 0,
          "&$focused": {
            color: "black",
            boxShadow: "0 1px 4px 4px rgba(222, 220, 221, 0.1)",
            backgroundColor: "rgba(205, 240, 247, 0.2)"
          }
          /*color: "black",
          backgroundColor: "rgba(205, 240, 247, 0.2)"*/
        },
        "&$error": {
          border: "1px solid red"
        }
      },
      input: {
        //boxSizing: "border-box",
        boxSizing: "content-box",
        // backgroundColor: "white",
        "&:focus": {
          backgroundColor: "rgba(0, 0, 0, 0)"
        }
      },
      /*// this is the outer frame
      focused: {
        padding: 0,
        "&$focused": {
          color: "black",
          boxShadow: "0 1px 4px 4px rgba(222, 220, 221, 0.1)",
          backgroundColor: "rgba(205, 240, 247, 0.2)"
        }
      }*/
    }
  }
});

Amplify.configure(awsExports)

class App extends React.PureComponent {
  state = { /*timer: 0,*/ amplifyConfigured: false }

  constructor(props) {
    super(props);
    this.idleTimer = null;
    this.onIdle = this._onIdle.bind(this);
    this.intervalID = null;
  }

  async componentDidMount() {
    const { actions } = this.props;
    //const baseConfig = `${window.__webpack_public_path__}config.json`;

    try {
      const ApiURL = awsExports["API"].endpoints.filter(endpoint => endpoint.name === "MetawebserviceCore")[0].endpoint
      //const response = await window.fetch(baseConfig)
      //const result = await response.json()
      actions.storeApiURL(ApiURL)
      /*
      Amplify.configure({
        Auth: {
          // REQUIRED - Amazon Cognito Identity Pool ID
          identityPoolId: result.IdentityPoolId,
          // REQUIRED - Amazon Cognito Region
          region: result.Region,
          // OPTIONAL - Amazon Cognito User Pool ID
          userPoolId: result.UserPoolId,
          // OPTIONAL - Amazon Cognito Web Client ID
          userPoolWebClientId: result.AppClientId,
          oauth: {
            domain: result.UserPoolDomain,
            scope: ['phone', 'email', 'openid', 'aws.cognito.signin.user.admin'],
            redirectSignIn: (result.Environment === "dev" && (window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1" || window.location.hostname === "")) ? 'http://localhost:3000' :
              (result.Environment === "dev") ? "https://dev.metawebservice.com" :
                (result.Environment === "test") ? "https://test.metawebservice.com" : "https://metawebservice.com",
            redirectSignOut: (result.Environment === "dev" && (window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1" || window.location.hostname === "")) ? 'http://localhost:3000' :
              (result.Environment === "dev") ? "https://dev.metawebservice.com" :
                (result.Environment === "test") ? "https://test.metawebservice.com" : "https://metawebservice.com",
            responseType: 'code' // or 'token', note that REFRESH token will only be generated when the responseType is code
          },
          //See https://docs.amplify.aws/lib/client-configuration/configuring-amplify-categories/q/platform/js/#scoped-configuration
          cookieStorage: {
            domain: (result.Environment === "dev" && (window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1" || window.location.hostname === "")) ? 'localhost' :
              "metawebservice.com",
            secure: (window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1" || window.location.hostname === "") ? false : true,
            path: '/',
            sameSite: "strict",
            expires: 365,
          },
        },
        API: {
          endpoints: [
            {
              name: "MetawebserviceCore",
              endpoint: result.ApiURL,
              custom_header: async () => {
                return { Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}` }
              }
            }
          ]
        }
      })
      */
      //Auth.configure(awsconfig);
      this.setState({ amplifyConfigured: true })

      //https://rchavesferna.medium.com/building-microfrontends-part-v-communication-between-apps-34ae3649d610
      window.addEventListener('utilityActions', (event) => {
        actions.utilityActionsReceived(event.detail)
      }, false);

      if (!this.intervalID) {
        this.intervalID = setInterval(actions.refreshEveryXminutes, /*24 * 60 * 60 3 */ 60 * 1000)
      }
    }

    catch (e) {
      console.log(e)
      this.setState({ amplifyConfigured: false })
    }
  }

  componentWillUnmount() {
    // you need to unbind the same listener that was binded.
    window.removeEventListener('utilityActions', actions.utilityActionsReceived, false);
    clearInterval(this.intervalID)
  }


  handleSignoutClick = () => {
    const { history, actions } = this.props;
    actions.showIdleModal(false);
    actions.logoutSelected(history);
  };

  _onIdle(e) {
    const { isAuthenticated, actions } = this.props;

    if (isAuthenticated) {
      actions.showIdleModal(true);
    }
  }

  render() {
    const {
      actions,
      history,
      confirmUserAttributesModal,
      open,
      inboxOpen,
      toasts,
      fileVersionsOpen,
      fileReviewOpen,
      studyDetailComparer,
      sasJobComparer,
      isIdle,
      studies,
      id,
      apiURL
    } = this.props;
    const { amplifyConfigured } = this.state

    const { location } = history;
    const { pathname } = location;

    const selectedStudy = studies.filter(study => study.studyId === id)

    const childProps = {
      studies: studies,
      history: history,
      studyId: id,
      sponsorGuid: (selectedStudy.length > 0) ? selectedStudy[0].sponsorGuid : "",
      studyName: (selectedStudy.length > 0) ? selectedStudy[0].studyName : "",
      studyGuid: (selectedStudy.length > 0) ? selectedStudy[0].studyGuid : "",
      url: apiURL + "Utils/initiateUtilLambda"
    }

    const showToolBar = () => {
      return (
        (amplifyConfigured && pathname.indexOf("/study") !== -1) ||
        (amplifyConfigured && pathname.indexOf("/studies") !== -1)
      );
    };

    return (
      <MuiThemeProvider theme={theme}>
        <>
          <CssBaseline />

          {amplifyConfigured && (
            <IdleTimer
              ref={ref => {
                this.idleTimer = ref;
              }}
              element={document}
              onActive={this.onActive}
              onIdle={this.onIdle}
              onAction={this.onAction}
              debounce={250}
              timeout={timer}
            />
          )}

          {isIdle && (
            <IdleModal
              stayLoggedIn={() => actions.showIdleModal(false)}
              logout={this.handleSignoutClick}
            />
          )}

          <Grid item xs={12} style={{ top: "2.5rem", position: "relative", paddingBottom: "3.2rem" }}>

            {amplifyConfigured && (
              <Sidebar
                studiesSelected={showToolBar()}
                history={history} />
            )}

            {amplifyConfigured && (
              <Routes childProps={childProps} />
            )}

            <Navigation
              actions={actions}
              history={history}
              signout={this.handleSignoutClick}
              isAuthenticated={amplifyConfigured}
              pathname={pathname}
            />

            {amplifyConfigured && open && (
              <UserProfile onClose={actions.userProfileToggleDrawer} />
            )}

            {amplifyConfigured && inboxOpen && (
              <Inbox />
            )}

            {amplifyConfigured &&
              confirmUserAttributesModal && (
                <VerifyAndUpdateUserField
                  actions={actions}
                  {...confirmUserAttributesModal}
                />
              )}
            {amplifyConfigured && fileReviewOpen && <FileReviewModal />}
            {amplifyConfigured && fileVersionsOpen && <FileVersionsModal />}
            {amplifyConfigured && sasJobComparer && <FileComparerModal />}
            {amplifyConfigured && studyDetailComparer && <FileComparerModal />}
            {/*isAuthenticated &&*/ toasts.length > 0 && (
              <ToastWrapper toasts={toasts} onClose={actions.toastClose} />
            )}

            <Bottombar />

          </Grid>
        </>
      </MuiThemeProvider>
    );
  }
}

function mapStateToProps(state) {
  /*
  return state;
  */

  return {
    /*isAuthenticated: state.root.isAuthenticated,
    authenticating: state.root.authenticating,*/
    apiURL: state.root.apiURL,
    id: state.root.studyDetail?.id,
    studyDetailComparer: state.root.studyDetail?.Comparer,
    isIdle: state.root.isIdle,
    toasts: state.toastsWrapper.toasts,
    /*userProfile: state.userProfile,*/
    open: state.userProfile?.open,
    fileReviewOpen: state.fileReview?.open,
    fileVersionsOpen: state.fileVersions?.open,
    inboxOpen: state.userProfile?.inboxOpen,
    confirmUserAttributesModal: state.userProfile?.confirmUserAttributesModal,
    sasJobComparer: state.sasJob?.Comparer,
    fileVersions: state.fileVersions,
    fileReview: state.fileReview,
    studies: state.root.studies,
    /*dashboardPermissions: state.root.studies[0].permissions*/
  }
}
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(App)
);
