import React from "react";
import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";


const ModalTitle = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    zIndex: 10,
    color: "#fff"
  },
  border: {
    borderBottom: '1px solid #9e9e9e2b',
    background: 'transparent',
  },
  background: {
    background: '#328cc8',
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: "rgba(0, 0, 0, 0.54)"
  },
  yellowIcon: {
    color: '#FFE000',
    top: '4px'
  }
}))(props => {
  const { children, classes, onClose, border, background, dark } = props;
  return (
    <DialogTitle disableTypography className={classNames(classes.root, border ? classes.border : '', background ? classes.background : {})}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="Close"
          className={classNames(classes.closeButton, dark ? classes.yellowIcon : '')}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
});
export default ModalTitle;