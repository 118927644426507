import * as actions from "../actions";
//import { getStudyFileStatusByState } from "../utils/studyFileHelpers";
//import { formatDateTime } from "../utils/date";

export const DEFAULT_STATE = {
  loading: true,
  open: false,
  studyFileId: undefined,
  studyId: undefined,
  viewMode: undefined,
};

export const root = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case actions.FILE_REVIEW_RESET: {
      return DEFAULT_STATE;
    }
    case actions.FILE_REVIEW_OPEN_MODAL: {
      const { studyId, studyFileId, viewMode } = action.payload;
      return {
        ...state,
        open: true,
        studyFileId,
        studyId,
        viewMode
      };
    }

    default:
      return state;
  }
};
