import React from "react";
import PropTypes from "prop-types";
import { Provider } from "react-redux";
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
//import createMuiTheme from "@material-ui/core/styles/createMuiTheme";
import createTheme from "@material-ui/core/styles/createTheme";
import { HashRouter, Route } from "react-router-dom";
import App from "./app/app";
import { Authenticator } from '@aws-amplify/ui-react';

// stable react version  "react": "^16.5.2",
//  "react-dom": "^16.5.2",
const theme = createTheme({
  typography: {
    useNextVariants: true,

    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(","),
    fontSize: 10
  },
  overrides: {
    MuiFormLabel: {
      root: {
        color: "black",
        fontSize: 10
      }
    }
    // MuiTableCell: {
    //   root: {
    //     borderBottom: '1px solid rgba(224, 224, 224, 0.1)'
    //   }
    // }
  }
  // overrides: {
  //   MuiTableCell: {
  //     root: {
  //       borderBottom: '1px solid rgba(224, 224, 224, 0.1)'
  //     }
  //   }
  // }
});

const Root = ({ store }) => (

  <Authenticator.Provider>
    <Provider store={store}>
      <MuiThemeProvider theme={theme}>
        <HashRouter>
          <Route path="/" component={App} />
        </HashRouter>
      </MuiThemeProvider>
    </Provider>
  </Authenticator.Provider>

);

Root.propTypes = {
  store: PropTypes.object.isRequired
};

export default Root;
