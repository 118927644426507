import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from "../../ui/Button";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import classNames from "classnames";
import PropTypes from "prop-types";
import { DataGrid } from '@mui/x-data-grid';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Chip from "@material-ui/core/Chip";
import WarningIcon from '@material-ui/icons/Warning';
import AddIcon from '@material-ui/icons/Add';
import DynamicFeedIcon from '@material-ui/icons/DynamicFeed';
import ErrorIcon from '@material-ui/icons/Error';
import { red, green, blue, grey } from "@material-ui/core/colors";
import CircularProgress from "@material-ui/core/CircularProgress";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { RowingRounded } from '@material-ui/icons';

const useStyles = makeStyles({
  root: {
    marginTop: "0.5rem"
  },
  /*paper: {
    color: theme.palette.text.secondary,
    width: "80%",
    position: 'relative',
    boxShadow: '0px 1px 0px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 0px 0px rgba(0, 0, 0, 0.12)',
    [theme.breakpoints.down("md")]: {
      width: 800,
    },
    [theme.breakpoints.down("xs")]: {
      width: 280,
    },
    borderRadius: '8px'
  },*/
  list: {
    maxHeight: "500px",
    overflow: "hidden",
    overflowY: "scroll"
  },
  listItem: {
    height: "2.5rem",
    marginBottom: "0.1rem",
    width: "100%",
    position: "relative",
    color: "grey",
    borderBottom: "1px dotted lightgrey",
    "&:last-of-type": {
      border: 0
    },
    "&:hover": {
      cursor: "pointer",
      /*background: theme.palette.primary.lightest,*/
    },
    transition: "all 180ms ease-out",
    "&:nth-child(odd)": {
      background: "#91d1ec0d",
      "&:hover": {
        cursor: "pointer",
        /*background: theme.palette.primary.lightest,*/
      },
      transition: "all 180ms ease-out",
    }
  },
  listItemSuccess: {
    backgroundColor: "RGB(198,239,206) !important",
    color: "RGB(0,97,83)",
    borderBottom: "1px dotted lightgrey",
    "&:last-of-type": {
      border: 0
    },
    "&:hover": {
      cursor: "pointer",
    },
  },
  MuiAppBarColor: {
    color: /*"#000000"*/ "#FFFFFF" /*"rgb(0, 72, 132)"*/,
    backgroundColor: /*"#f5f5f5"*/ "#328cc8",
    borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
    position: "static",
    width: "100%",
    display: "flex",
    zIndex: 1100,
    boxSizing: "border-box",
    flexShrink: 0,
    flexDirection: "column",
    boxShadow: "none",
    borderRadius: '0px'
  },
  iconSection: {
    display: 'none',
    flex: 1,
    flexDirection: "row-reverse",
    /*[theme.breakpoints.up('md')]: {
      display: 'flex',
    },*/
  },
  primary: {
    color: "#000 !important",
    fontSize: "1rem !important",
    fontWeight: "400",
    lineHeight: 1.5,
    letterSpacing: "0.00938em !important"
  },
  chBox: {
    color: "#0273b5"
  },
  autoSuggestRoot: {
    width: "95%",
    position: "relative",
    flexGrow: 1,
    marginLeft: "0.3rem"
  },
  inputMargins: {
    marginTop: "-8px",
    marginBottom: "8px"
  },
  uploadButton: {
    flex: 1,
    marginTop: "0.2rem"
  },
  formControlRoot: {
    float: "left"
  },
  rootDataGrid: {
    '& .MuiDataGrid-cell--editing': {
      backgroundColor: 'rgb(255,215,115, 0.19)',
      color: '#1a3e72',
    },
    '& .Mui-error': {
      backgroundColor: `rgb(126,10,15)`,
      color: '#750f0f',
    },
  },
  /*...theme.typography*/
});

function CircularProgressWithProgress(props) {
  return (
    <div>
      <Box position="relative" display="inline-flex" style={{ top: "10px" }}>
        <CircularProgress variant="determinate" value={100} size={45} />
        <Box
          top={0}
          left={0}
          bottom={0}
          right={0}
          position="absolute"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Typography variant="caption" component="div" color="textSecondary">
            {props.content}
          </Typography>
        </Box>
      </Box>
    </div>
  );
}

function loadingStatus(params) {
  if (params.value === "LOADING") {
    if (params.row.progress) {
      return <CircularProgressWithProgress content={`${params.row.progress} %`} />
    }
    else {
      return <CircularProgress />
    }
  }
  else if (params.value === "FINISHED") {
    return <CheckCircleIcon style={{
      borderColor: green[500],
      color: green[500]
    }} />
  }
  else if (params.value === "ERROR") {
    return <ErrorIcon style={{
      borderColor: red[500],
      color: red[500]
    }} />
  }
  else {
    return ""
    //return <CircularProgressWithProgress content={`${0} %`} />
  }
}

function getChipProps(params) {
  if (params.value === "New file. Not on server") {
    return {
      icon: <AddIcon style={{ fill: green[500] }} />,
      label: params.value,
      style: {
        borderColor: green[500],
        color: green[500]
      }
    };
  } else if (params.value === "Update to server version") {
    return {
      icon: <DynamicFeedIcon style={{ fill: blue[500] }} />,
      label: params.value,
      style: {
        borderColor: blue[500],
        color: blue[500]
      }
    };
  } else if (params.value === "Same as on server") {
    return {
      icon: <WarningIcon style={{ fill: grey[500] }} />,
      label: params.value,
      style: {
        borderColor: grey[500],
        color: grey[500]
      }
    };
  }
  else {
    return {
      icon: <ErrorIcon style={{ fill: red[500] }} />,
      label: params.value,
      style: {
        borderColor: red[500],
        color: red[500]
      }
    };
  }
}

const columns = [
  {
    field: 'id',
    headerName: 'ID',
    width: 90,
    hide: true
  },
  {
    field: 'name',
    headerName: 'Name',
    width: 150,
    editable: false,
  },
  {
    field: 'type',
    headerName: 'Type',
    width: 100,
    editable: false,
  },
  {
    field: 'folder',
    headerName: 'Folder',
    width: 200,
    editable: true,
    preProcessEditCellProps: (params) => {
      const hasError = params.props.value.length < 2;
      return { ...params.props, error: hasError };
    },
  },
  {
    field: 'status',
    headerName: 'Status',
    minWidth: 200,
    editable: false,
    renderCell: (params) => {
      return <Chip variant="outlined" {...getChipProps(params)} />;
    },
  },
  {
    field: 'loading',
    headerName: 'Progress',
    minWidth: 70,
    editable: false,
    customHeadRender: () => '',
    renderCell: (params) => {
      return loadingStatus(params);
    },
  }
];

const UploadFileWidget = (props) => {
  const classes = useStyles();
  const { files, permissions, actions, potentialUploadsWithStatus, viewMode } = props;
  const initialType = (viewMode === "download") ? viewMode : "upload"

  //State variables
  const [rows, setRows] = useState([])
  const [selection, setSelection] = useState([])
  const [type, setType] = useState(initialType)
  const [uploading, setUploading] = useState(false)
  const [noFinished, setNoFinished] = useState(0)
  const [noAll, setNoAll] = useState(0)

  //Permissions
  const userPermissions = permissions.filter(perm => perm.selected === true && (perm.permission === "UPLOAD" || perm.permission === "SASUPLOAD"))
  const sasUpload = userPermissions.filter(perm => perm.permission === "SASUPLOAD").length > 0

  React.useEffect(() => {
    let rows = (potentialUploadsWithStatus) ? potentialUploadsWithStatus : []
    rows = rows.map(item => {
      if (item.type !== initialType) {
        return { ...item, type: initialType }
      }
      return { ...item }
    })

    const selected = (rows) ? rows.filter(item => item.status !== "Same as on server").map(item => item.id) : []

    //const selectedRows = rows.filter(row => selection.includes(row.id))
    const finished = (rows) ? rows.filter(item => item.loading === "FINISHED" || item.loading === "ERROR").length : 0

    setNoFinished(finished)
    setSelection(selected)
    setRows(rows);
  }, [potentialUploadsWithStatus])

  const handleRadioChange = (event) => {
    const updateRows = rows.map(file => { return { ...file, type: event.target.value } })
    setType(event.target.value);
    setRows(updateRows);
  };

  const handleClick = () => {
    const selectedRows = rows.filter(row => selection.includes(row.id))
    setNoAll(selectedRows.length)
    const selectedFiles = selectedRows.map(row => {
      const file = files.filter(fileInfo => fileInfo.path === row.id)[0]
      return { id: row.id, folder: row.folder, type: row.type, file: file }
    })
    setUploading(true)
    actions.studyUploadFilesV2(selectedFiles);
  };

  const handleCellEditCommit = React.useCallback(
    ({ id, field, value }) => {

      //We need to poll the server to check if the file exists      
      actions.editFolderOnPotentialUpload(id, value)

      const uploadFile = (potentialUploadsWithStatus) ? potentialUploadsWithStatus.filter(f => f.id.toUpperCase() === id.toUpperCase() && f.folder === value) : []

      let updatedRows = [...rows]
      let newSelection = [...selection]

      if (uploadFile.length === 0) {
        if (!newSelection.includes(id)) {
          newSelection.push(id);
        }

        updatedRows = rows.map(row => {
          if (row.id === id) {
            return { ...row, folder: value, status: "New file. Not on server" }
          }
          return row;
        });
      }

      else {
        updatedRows = rows.map(row => {
          if (row.id === id && uploadFile[0].md5 === rows.md5) {
            return { ...row, folder: value, status: "Same as on server" }
          }
          else if (row.id === id && uploadFile[0].md5 !== rows.md5) {
            return { ...row, folder: value, status: "Update to server version" }
          }
          return row;
        });
      }

      setSelection(newSelection)
      setRows(updatedRows);
    },
    [rows]
  );

  return (
    <Grid style={{ textAlign: "right" }}>
      <div style={{ height: 320, width: '100%' }}>
        <DataGrid
          classes={{ root: classes.rootDataGrid }}
          rows={rows}
          columns={columns}
          onCellEditCommit={handleCellEditCommit}
          selectionModel={selection}
          onSelectionModelChange={setSelection}
          checkboxSelection
          disableSelectionOnClick
          disableColumnFilter
          disableColumnMenu
          hideFooterPagination
          hideFooter
        />
      </div>
      {!uploading ?
        <div>
          {sasUpload && (
            <FormControl classes={{ root: classes.formControlRoot }} component="fieldset">
              <RadioGroup row aria-label="position" name="position" defaultValue="top" value={type} onChange={handleRadioChange}>
                <FormControlLabel
                  value="upload"
                  control={<Radio color="primary" />}
                  label="Upload"
                  labelPlacement="start"
                />
                <FormControlLabel
                  value="download"
                  control={<Radio color="primary" />}
                  label="Download"
                  labelPlacement="start"
                />
              </RadioGroup>
            </FormControl>
          )}
          <Button
            id="uploadFiles"
            className={classNames(classes.buttonWrapper, classes.uploadButton)}
            disabled={selection.length === 0}
            onClick={handleClick}
          >
            {`Upload ${selection.length} files`}
          </Button>
        </div>
        :
        <div>
          Uploaded {`${noFinished} of ${noAll}`}
        </div>
      }
    </Grid>
  );
}

UploadFileWidget.propTypes = {
  classes: PropTypes.object,
  files: PropTypes.array,
  permissions: PropTypes.array,
  potentialUploadsWithStatus: PropTypes.array,
  viewMode: PropTypes.string
};

export default UploadFileWidget;
