import * as actions from "../actions/";
import {
  takeLatest,
  /*call,
  put,*/
  getContext,
  /*select,
  delay*/
} from "redux-saga/effects";

function* performActions(action) {
  const payload = action.payload
  console.log(payload.actions)
  try {

    const api = yield getContext("api");
    console.log(api)
    //yield put(actions.utilityActionsStarted());

    //for (let action of actions) {
    //  console.log(action)
    //}


    //yield put(actions.utilityActionsEnded());
  } catch (error) {
    console.log("error in resp ", error);
  }
}

export function* UtilitySagas() {
  yield takeLatest(actions.UTILITY_ACTIONS_RECEIVED, performActions);
}
