import * as actions from "../actions/";
// import { viewModes, fileStatusActions } from "../constants";
// import { getStudyFileStatusByState } from "../utils/studyFileHelpers";
// import { formatIsoDate, formatDateTime } from "../utils/date";

const DEFAULT_STATE = {
  loading: false,
  open: false
};

export const root = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case actions.SAS_JOB_RESET: {
      return DEFAULT_STATE;
    }
    case actions.CREATE_SASJOB_TOGGLE_MODAL: {
      return {
        ...state,
        open: !state.open
      };
    }
    case actions.SAS_JOB_PLACE_INITIAL_JOB: {
      const { studyId, sasJob, versions, latest } = action.payload;
      const previousVersions = versions.slice(1);

      return {
        ...state,
        loading: false,
        studyId,
        sasJob,
        previousVersions: previousVersions,
        sasJobNotLatest: latest
      };
    }

    case actions.SAS_JOB_PLACE_COMPARER_JOB: {
      const { studyId, sasJob } = action.payload;
      return {
        ...state,
        loading: false,
        ComparerStudyId: studyId,
        Comparer: sasJob
      };
    }

    case actions.FILE_COMPARER_SAS_RESET: {
      return {
        ...state,
        loading: false,
        ComparerStudyId: undefined,
        Comparer: undefined
      }
    }

    case actions.SAS_JOB_INIT: {
      const { studyId, sasJobId } = action.payload;
      return {
        ...state,
        studyId,
        sasJobId
      }
    }
    case actions.SAS_JOB_UPDATE_PROGRAM: {
      return {
        ...state,
        loading: true
      };
    }
    case actions.SAS_JOB_ERROR: {
      return {
        ...state,
        loading: false
      };
    }

    case actions.SAS_JOB_RECEIVE: {
      const { sasJob } = action.payload;
      return {
        ...state,
        loading: false,
        sasJob: sasJob
      };
    }

    default:
      return state;
  }
};
