import { fileStatusActions } from "../constants";
export const getStudyFileStatusByState = state => {
  const { reviewDateTime, finalDateTime, sponsorFinalDateTime } = state;
  if (sponsorFinalDateTime) {
    return fileStatusActions.SPONSOR_FINAL;
  }
  if (finalDateTime) {
    return fileStatusActions.FINAL;
  }
  if (reviewDateTime) {
    return fileStatusActions.REVIEW;
  }
  return fileStatusActions.DRAFT;
};
export const hasStatusPermission = permissions => {
  if (permissions && permissions.length) {
    const perms = permissions.filter(item => item === "STATUS");
    if (perms.length) {
      return true;
    }
  }
  return false;
};
