import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import UploadFilesV2 from "../UploadFilesV2";
import { withRouter } from "react-router-dom";
import Modal from "./Modal";

const styles = theme => ({
  paper: {
    width: 855,
    height: 445,
    borderRadius: "8px"
  },
  dialog: {
    overflow: "hidden"
  }
});

class UploadDialog extends React.PureComponent {
  render() {
    const {
      viewMode,
      actions,
      onClose,
      permissions,
      classes,
      uploading,
      uploadSucces,
      potentialUploads,
      potentialUploadsWithStatus,
      selectedUploadDownloadfolder,
      uploadFolders
    } = this.props;

    return (
      <Modal
        onClose={onClose}
        classes={classes}
        maxWidth="lg"
        open={true}
        title={"Upload files"}
        titleBackground
      >

        <UploadFilesV2
          viewMode={viewMode}
          uploading={uploading}
          uploadSucces={uploadSucces}
          permissions={permissions}
          actions={actions}
          potentialUploads={potentialUploads}
          potentialUploadsWithStatus={potentialUploadsWithStatus}
          selectedUploadDownloadfolder={selectedUploadDownloadfolder}
          uploadFolders={uploadFolders}
        />
      </Modal>
    );
  }
}

UploadDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  viewMode: PropTypes.string,
  selectedUploadDownloadfolder: PropTypes.array,
  uploading: PropTypes.bool,
  uploadSucces: PropTypes.bool,
  uploadFolders: PropTypes.array,
  downloadFolders: PropTypes.array,
  potentialUploads: PropTypes.array,
  selectedUploadDownloadfolder: PropTypes.array,
  uploadFolders: PropTypes.array,
  viewMode: PropTypes.string
};

export default withRouter(withStyles(styles)(UploadDialog));
