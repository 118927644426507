import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom'
import classNames from "classnames";

const styles = theme => ({
  root: {
    background: '#2196F3',
    border: 0,
    color: 'white',
    padding: '0 2rem',
    height: '44px',
    borderRadius: '2rem',
    "&:hover": {
      boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
      background: 'black',
    },
    ...theme.typography,
  },
  label: {
    textTransform: 'none',
    fontSize: '0.6rem',
    [theme.breakpoints.down("xs")]: {
      fontSize: "0.8rem",
    }
  },
});

function Btn(props) {
  // we might have overrides
  const { classes, children, disabled, onClick, className, to, fullWidth, id } = props;
  if (to) {
    return (
      <Button
        id={id}
        component={Link}
        to={to}
        fullWidth={false}
        disabled={disabled}
        size={'small'}
        classes={{
          root: classNames(classes.root, className), // class name, e.g. `classes-nesting-root-x`
          label: classes.label, // class name, e.g. `classes-nesting-label-x`
        }}
      >
        {children}
      </Button>
    );
  }
  return (
    <Button
      id={id}
      fullWidth={fullWidth}
      disabled={disabled}
      size={'small'}
      classes={{
        root: classNames(classes.root, className), // class name, e.g. `classes-nesting-root-x`
        label: classes.label, // class name, e.g. `classes-nesting-label-x`
      }}
      onClick={onClick}
    >
      {children}
    </Button>
  );
}

Btn.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Btn);