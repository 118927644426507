import TextField from "@material-ui/core/TextField";
import React from "react";
import classNames from "classnames";
const basicType = "text";

export default class BasicTextField extends React.PureComponent {
  render() {
    const {
      classes,
      customClasses,
      value,
      onChange,
      id,
      placeholder,
      label,
      error,
      type,
      autoFocus,
    } = this.props;

    // if e-mail field the auto auto
    // accept multiple classes userårpfile textField overwrite
    /* eslint-disable */
    return (
      <TextField
        autoFocus={autoFocus? autoFocus : false}
        autoComplete="off"
        autoCorrect="off"
        autoCapitalize="none"
        placeholder={placeholder}
        id={id}
        label={label}
        variant="filled"
        margin="normal"
        InputProps={{
          autoCapitalize: "none",
          classes: {
            input: classes.inputPadding,
            root: classes.input
          }
        }}
        inputProps={{
          autoCapitalize: "none"
        }}
        InputLabelProps={{
          required: false,
          error: false,
          shrink: false,
          classes: {
            root: classes.label,
            focused: classes.cssFocused
          }
        }}
        className={customClasses ? classNames(customClasses) : classes.textField}
        error={error}
        helperText={error}
        value={value}
        type={type || basicType}
        onChange={(e) => onChange(e.target.value)}
      />
    );
     /* eslint-enable */
  }
}
