import React from "react";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/";
import CircularProgress from "@material-ui/core/CircularProgress";
import classNames from "classnames";
import loginStyles from "../auth/LoginStyles";
import Button from "../ui/Button";
import BasicTextField from "../ui/BasicTextField";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
const styles = loginStyles;

const resolveVerificationCodeError = errorMessage => {
  if (errorMessage && errorMessage.length && errorMessage.includes("confirmationCode")) {
    return "Please type in the 6 digits you received on SMS";
  } else if (errorMessage && errorMessage.length && errorMessage.includes("Invalid code provided")) {
    return errorMessage;
  }
  else if (errorMessage && errorMessage.length && errorMessage.includes("verification")) {
    return errorMessage;
  }
  else if (errorMessage && errorMessage.length && errorMessage.includes("password")) {
    return errorMessage;
  }
  return false;
};

class ChangePassword extends React.PureComponent {
  state = { verificationCode: '', password: '' }

  handleKeyPress = e => {
    const { actions, loading } = this.props;
    const { verificationCode, password } = this.state

    if (!loading) {
      if (e.key === "Enter") {
        e.preventDefault();
        actions.userProfileChangePassword(verificationCode, password)
      }
    }
  };

  userUpdateVerificationCode = (value) => {
    this.setState({
      verificationCode: value
    })
  }

  userUpdatePassword = (value) => {
    this.setState({
      password: value
    })
  }

  handleSubmitBtn = () => {
    const {
      loading,
      actions
    } = this.props;

    const { verificationCode, password } = this.state

    if (!loading) {
      actions.userProfileChangePassword(verificationCode, password)
    }
  };

  render() {
    const {
      actions,
      classes,
      loading,
      errorMessage,
      changeSuccess,
    } = this.props;

    const { verificationCode, password } = this.state

    if (changeSuccess) {
      return (
        <div style={{ marginTop: "4rem" }}>
          <CheckCircleIcon className={classes.icon} />
          <p className={classes.description}>Password changed successfully</p>
          <div className={classNames(classes.wrapper, classes.center)}>
            <Button
              className={classNames(classes.buttonWrapper, classes.clearTop)}
              onClick={actions.userProfileCloseAttributeModal}
              id="userProfileCloseAttributeModal"
            >
              Close
            </Button>
          </div>
        </div>
      );
    }


    return (
      <div className={classes.base}>
        <Grid container direction="column">
          {errorMessage &&
            errorMessage.length &&
            errorMessage.includes(
              "Attempt limit exceeded, please try after some time."
            ) && <p className={classes.description}>{errorMessage}</p>}
          {/* Text in the top*/}
          <Grid item>
            <p style={{ marginLeft: "0.5rem" }} className={classes.innerText}>
              A verification code has been send to your email. Please enter the verification code and a new password.
            </p>
          </Grid>
          {/* Verification code field*/}
          <Grid item>
            <form autoComplete="off" onKeyPress={this.handleKeyPress}>
              <BasicTextField
                placeholder={"Enter verification code"}
                id="verificationCode"
                label="Verification code"
                classes={classes}
                error={resolveVerificationCodeError(errorMessage)}
                value={verificationCode}
                onChange={this.userUpdateVerificationCode}
              />
            </form>
          </Grid>
          {/* Password field */}
          <Grid item>
            {" "}
            <form autoComplete="off" onKeyPress={this.handleKeyPress}>
              <BasicTextField
                placeholder={"Enter new password"}
                id="password"
                label="New password"
                type="password"
                classes={classes}
                error={
                  errorMessage &&
                    errorMessage.length &&
                    errorMessage.includes("password")
                    ? "Please enter a valid password, including a special charater, a capital letter, a lower case letter and a number."
                    : false
                }
                value={password}
                onChange={this.userUpdatePassword}
              />
            </form>
          </Grid>
          {/* submit button */}
          <Grid item className={classes.base}>
            <Grid item className={classes.lastElm}>
              <div className={classes.wrapperCta}>
                <Button
                  id="passwordChangeSubmit"
                  disabled={loading}
                  className={classNames(
                    classes.buttonWrapper,
                    classes.clearTop
                  )}
                  onClick={this.handleSubmitBtn}
                >
                  Change
                  {loading && (
                    <CircularProgress
                      size={24}
                      className={classes.buttonProgress}
                    />
                  )}
                </Button>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }
}

ChangePassword.propTypes = {
  classes: PropTypes.object.isRequired,
  errorMessage: PropTypes.string,
  loading: PropTypes.bool,
  changeSuccess: PropTypes.bool,
};

export default withStyles(styles)(ChangePassword);
