import React from "react";
import PropTypes from "prop-types";
import * as actions from "../actions/";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Drawer from "@material-ui/core/Drawer";
import IconButton from "@material-ui/core/IconButton";
import EditableTextField from "../ui/EditableTextField";
import CloseIcon from "@material-ui/icons/Close";
import PhoneIcon from "@material-ui/icons/Smartphone";
import LockIcon from "@material-ui/icons/Lock";
import CheckIcon from "@material-ui/icons/Check";
import Tooltip from "@material-ui/core/Tooltip";
import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import loginStyles from "../auth/LoginStyles";
import LinkBtn from "../ui/LinkButton";
import Button from "../ui/Button";
import Switch from '@material-ui/core/Switch';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from "@material-ui/core/FormLabel";
import { Auth } from '@aws-amplify/auth';
import WarningIcon from '@material-ui/icons/Warning';
import SecureIcon from '@material-ui/icons/VerifiedUser';
import TextField from "@material-ui/core/TextField";
import { green, red } from '@material-ui/core/colors';
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import { ListItemAvatar, List, ListItem, ListItemText, Avatar } from '@material-ui/core'
const paperStyles = theme => ({
  paper: {
    width: 360
  },
  root: {
    flexGrow: 1,
    width: '100%'
  },
  buttonWrapper: {
    background: "#0273b5",
    border: 0,
    color: "white",
    height: "44px",
    borderRadius: "2rem",
    top: "-1rem",
    // minWidth: '10rem',
    "&:hover": {
      boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
      background: "#0273b5"
    },

    width: "100%"
  },
  btnLabel: {
    fontSize: "0.5rem",
    color: "#333"
  },
  avatar: {
    color: "#fff",
    backgroundColor: "#333" /*deepPurple[500]*/,
    fontSize: "11px",
    width: "2rem",
    height: "2rem",
    marginTop: "1rem",
    marginBottom: "2.5rem"
  },
  content: {
    width: "90%",
    margin: "5rem auto"
  },
  textFieldOverwrite: {
    margin: 0,
    [theme.breakpoints.down("sm")]: {
      width: "100%"
    }
  },
  grid: {
    marginBottom: "1.8rem"
  },
  m1: {
    marginBottom: "1rem"
  },
  tLeft: {
    textAlign: "left",
    display: "inline-block",
    verticalAlign: "top",
    marginLeft: "0.3rem"
  },
  innerSpan: {
    position: "relative",
    marginLeft: "1.3rem",
    top: "-1.2rem",
    display: "inline-block",
    verticalAlign: "top",
    lineHeight: "1rem"
  },
  link: {
    marginTop: "1rem",
    display: "block",
    "&:hover": {
      color: "#0273b5"
    }
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  },
  profileCompletion: {
    position: "absolute",
    left: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  },
  labelroot: {
    color: "#333",
    fontSize: "0.5rem",
    padding: 0,
    /*fontWeight: 500,*/
    marginBottom: "0.4rem",
    textTransform: "uppercase"
  },
  inputPadding: {
    borderRadius: 0
  },
  danger: {
    color: "red",
  },
  successGreen: {
    color: green[500]
  },
  secure: {
    color: "#fff"
  },
  percentage: {
    fontWeight: 700,
  },
  listItem: {
    height: "2.5rem",
    marginBottom: "0.5rem",
    width: "100%",
    position: "relative",
    border: 0,
    cursor: "pointer",
    padding: 0,
    /*borderBottom: "1px dotted lightgrey",
    "&:last-of-type": {
      border: 0
    }*/
  },
  avatar: {
    background: "#fff",
    ...theme.typography,
    fontSize: "0.6rem"
  },
});


const styles = theme => ({
  ...loginStyles(theme),
  ...paperStyles(theme)
});

function CircularProgressWithLabel(props) {
  return (
    <Box style={{ top: "8px" }} position="absolute" display="inline-flex">
      <CircularProgress size={75} thickness={5} variant="determinate" value={props.value} />
      <Box
        top={15}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="block;"
        /*alignItems="center"
        justifyContent="center"*/
        textAlign='center'
      >
        <Typography className={props.classes.percentage} variant="caption" component="div" color="textPrimary">{`${Math.round(
          props.value,
        )}%`}</Typography>
        {props.mfaEnabled &&
          <Tooltip
            title="You have two facor authentication enabled"
            aria-label="You have two facor authentication enabled"
            classes={{
              tooltip: classNames(
                props.classes.lightTooltip,
                props.classes.tooltipStudy
              )
            }}
          >
            <SecureIcon className={props.classes.secure} style={{ color: green[500] }} />
          </Tooltip>
        }
        <Box
          top={-10}
          left={90}
          bottom={0}
          right={0}
          position="absolute"
          display="block;"
          /*alignItems="center"
          justifyContent="center"*/
          textAlign='center'
        >
          <Typography variant="h6" component="h6">
            Profile
          </Typography>
          <Typography variant="h6" component="h5">
            {props.value > 30 && !props.emailConfirmed ?
              "Not Confirmed" :
              props.value < 100 && props.value >= 30 && props.emailConfirmed ?
                "Confirmed" :
                props.value === 100 && props.emailConfirmed ?
                  "Complete" :
                  props.value === 100 && props.emailConfirmed && props.mfaEnabled ?
                    "Complete" : "Incomplete"}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}

CircularProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate variant.
   * Value between 0 and 100.
   */
  value: PropTypes.number.isRequired,
};


class UserProfile extends React.PureComponent {
  state = { open: false };

  componentDidMount() {
    setTimeout(() => {
      this.setState({ open: true });
    }, 10);
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      const { onClose } = this.props;
      onClose();
    }
  }

  handleClick = () => {
    const { actions } = this.props;
    actions.userProfileSubmit();
  };

  toggleDrawer = () => {
    const { onClose } = this.props;
    this.setState({ open: false });
    setTimeout(() => {
      onClose();
    }, 150);
  };

  handleMFAChange = (event) => {
    const { actions, phoneConfirmed } = this.props;
    actions.userToggleMFA(event.target.checked, phoneConfirmed);
  };

  signOut = () => {
    const { history, actions } = this.props;
    actions.showIdleModal(false);
    actions.clearStudiesStudyDetail();
    actions.logoutSelected(history);
  };


  VerifyPhoneNumber = async () => {

    const { actions } = this.props;

    try {
      const user = await Auth.currentAuthenticatedUser();
      await Auth.currentSession()
      await Auth.verifyUserAttribute(user, 'phone_number')
      actions.userProfileEditField("phone_number")
    }
    catch (e) {
      console.log(e)
    }
  }

  /*VerifyEmail = async () => {

    const { actions } = this.props;

    try {
      const user = await Auth.currentAuthenticatedUser();
      await Auth.currentSession()
      await Auth.verifyUserAttribute(user, 'email')
      actions.userProfileEditField("email")
    }
    catch (e) {
      console.log(e)
    }
  }*/

  changePassword = async () => {

    const { actions } = this.props;

    try {
      const user = await Auth.currentAuthenticatedUser();
      await Auth.currentSession()
      await Auth.forgotPassword(user.username);
      actions.userProfileEditField("password")
    }
    catch (e) {
      console.log(e)
    }
  }

  render() {
    const { open } = this.state;
    const {
      id,
      classes,
      actions,
      contactName,
      email,
      company,
      mfaEnabled,
      phone,
      emailConfirmed,
      phoneConfirmed,
      contactNameSet,
      companySet,
      dashboardPermissions } = this.props;

    const percentageComplete = 20 + /*Email and phone number entered*/
      (phoneConfirmed * 10) +       /*Phone confirmed*/
      (emailConfirmed * 30) +          /*Email confirmed*/
      (contactNameSet * 20) +       /*Contact name is set*/
      (companySet * 20)             /*Company name is set*/
    /*(mfaEnabled * 20)*/


    const permissionList =
      id &&
        dashboardPermissions &&
        dashboardPermissions.length > 0
        ? dashboardPermissions.filter(item => item.selected === true).map(item => item.permission)
        : [];


    return (
      <Drawer
        anchor="right"
        open={open}
        onClose={this.toggleDrawer}
        classes={{
          paper: classes.paper
        }}
      >
        <div className={classes.content}>

          <CircularProgressWithLabel
            classes={{ colorPrimary: classes.successGreen, percentage: classes.percentage }}
            value={percentageComplete}
            mfaEnabled={mfaEnabled}
            emailConfirmed={emailConfirmed} />

          <IconButton
            aria-label="Close"
            className={classes.closeButton}
            onClick={this.toggleDrawer}
          >
            <CloseIcon />
          </IconButton>

          <Grid container direction="column">
            {/*Contact Name*/}
            <Grid item className={classes.grid}>
              <FormGroup row>
                <FormLabel classes={{ root: classes.labelroot }} component="legend">Contact name (required)</FormLabel>
                <EditableTextField
                  placeholder="Please fill in your name (displayed on overview)"
                  value={contactName}
                  onChange={val =>
                    //actions.userProfileUpdateInput("contactName", val)
                    actions.userProfileSubmit("contactName", val)
                  }
                />
              </FormGroup>
            </Grid>
            {/*company*/}
            <Grid item className={classes.grid}>
              <FormGroup row>
                <FormLabel classes={{ root: classes.labelroot }} component="legend">Company (required)</FormLabel>
                <EditableTextField
                  value={company}
                  placeholder="Please fill in company name"
                  onChange={val =>
                    //actions.userProfileUpdateInput("company", val)
                    actions.userProfileSubmit("company", val)
                  }
                />
              </FormGroup>
            </Grid>
            {/* email field - emails are non-mutable*/}
            <Grid item className={classes.grid}>
              <TextField
                disabled
                id="email"
                label="Email (required)"
                classes={{ root: classes.root }}
                value={email}
                onChange={val => console.log("email can not be changed!")}
              />
              {emailConfirmed &&
                <div>
                  <span
                    style={{
                      position: "relative",
                      fontSize: "0.6rem",
                      color: "green"
                    }}
                  >
                    Verified
                  </span>
                  <CheckIcon fontSize="small" style={{ color: green[500] }} />
                </div>
              }
              {!emailConfirmed &&
                <LinkBtn id="verify-email" onClick={this.VerifyEmail}>
                  <WarningIcon fontSize="small" style={{ color: red[500] }} />
                  <span
                    style={{
                      position: "relative",
                      fontSize: "0.6rem",
                      color: "red"
                    }}
                  >
                    Not verified. Click here to verify!
                  </span>
                </LinkBtn>
              }
            </Grid>
            {/* Phone field - phone numbers are non-mutable*/}
            <Grid item className={classes.grid}>
              <TextField
                disabled
                id="phoneNumber"
                label="Phone number (required)"
                classes={{ root: classes.root }}
                value={phone}
                onChange={val => console.log("Phone number can not be changed!")}
              />
              {phoneConfirmed &&
                <div>
                  <span
                    style={{
                      position: "relative",
                      fontSize: "0.6rem",
                      color: "green"
                    }}
                  >
                    Verified
                  </span>
                  <CheckIcon fontSize="small" style={{ color: green[500] }} />
                </div>
              }
              {!phoneConfirmed &&
                <LinkBtn id="verify-phone" onClick={this.VerifyPhoneNumber}>
                  <WarningIcon fontSize="small" style={{ color: red[500] }} />
                  <span
                    style={{
                      position: "relative",
                      fontSize: "0.6rem",
                      color: "red"
                    }}
                  >
                    Not verified. Click here to verify!
                  </span>
                </LinkBtn>
              }
            </Grid>
          </Grid>

          {/*Notice to verify email if not confirmed*/}
          {(!emailConfirmed) &&
            <p className={classes.danger}>You have not confirmed your user!. You need to verify your email to confirm.</p>
          }

          {/*Change password button*/}
          <div className={classNames(classes.wrapper)}>
            <LinkBtn id="password-change" onClick={this.changePassword}>
              <LockIcon />
              <span
                style={{
                  position: "relative",
                  fontSize: "0.7rem",
                  marginLeft: "0.4rem"
                }}
              >
                Change password
              </span>
            </LinkBtn>
          </div>

          {/*MFA switch */}
          <div className={classNames(classes.wrapper)}>
            <Grid item className={classes.grid}>
              <FormGroup row>
                <FormControlLabel
                  control={<Switch disabled={!phoneConfirmed} checked={mfaEnabled} onChange={this.handleMFAChange} name="mfaEnabled" color="primary" />}
                  label="Multi Factor Authentication" />
              </FormGroup>
              {!phoneConfirmed &&
                <span
                  style={{
                    position: "relative",
                    fontSize: "0.6rem",
                    color: "red"
                  }}
                >
                  Verified phone required before you can enable MFA!
                </span>
              }
            </Grid>
          </div>

          {/* RESET ROS */}
          {(permissionList.includes("SASUPLOAD") && permissionList.includes("ADMIN")) &&
            <div className={classNames(classes.wrapper)}>
              <Grid item className={classes.grid}>
                <List>
                  <ListItem
                    key={"studyFileRO"}
                    className={classNames(
                      classes.listItem,
                    )}
                    onClick={() => actions.resetStudyFileRO(id)}
                  >
                    <ListItemAvatar>
                      <Avatar
                        className={classNames(
                          classes.avatar,
                          classes.inline
                        )}
                      >
                        <WarningIcon style={{ color: "red" }} />
                      </Avatar>
                    </ListItemAvatar>

                    <ListItemText
                      classes={{ primary: classes.primary }}
                      primary={"Reset studyFileRO"}
                    />
                  </ListItem>
                  <ListItem
                    key={"sasJobRO"}
                    className={classNames(
                      classes.listItem,
                    )}
                    onClick={() => actions.resetSasJobRO(id)}
                  >
                    <ListItemAvatar>
                      <Avatar
                        className={classNames(
                          classes.avatar,
                          classes.inline
                        )}
                      >
                        <WarningIcon style={{ color: "red" }} />
                      </Avatar>
                    </ListItemAvatar>

                    <ListItemText
                      classes={{ primary: classes.primary }}
                      primary={"Reset sasJobRO"}
                    />
                  </ListItem>
                </List>
              </Grid>
            </div>
          }

          {/*Help for anything else*/}
          <a
            className={classNames(classes.wrapper, classes.link)}
            href="mailto:contact@me-ta.dk"
            target="_top"
          >
            <p className={classNames(classes.description, classes.tLeft)}>
              <PhoneIcon />
              <span className={classes.innerSpan}>
                If you need support you can always contact us by clicking here.
              </span>
            </p>
          </a>

          <div className={classes.relative}>
            <Button
              className={classes.buttonWrapper}
              onClick={this.signOut}
              id="logout"
            >
              Log out
            </Button>
          </div>
        </div>
      </Drawer >
    );
  }
}

UserProfile.propTypes = {
  classes: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  phoneConfirmed: PropTypes.bool,
  contactName: PropTypes.string,
  company: PropTypes.string,
  errorMessage: PropTypes.array,
  phone: PropTypes.string,
  email: PropTypes.string,
  mfaEnabled: PropTypes.bool,
  emailConfirmed: PropTypes.bool,
  contactNameSet: PropTypes.bool,
  companySet: PropTypes.bool,
  dashboardPermissions: PropTypes.array
};

function mapStateToProps(state) {
  return {
    id: state.root.studyDetail?.id,
    phoneConfirmed: state.userProfile.phoneConfirmed,
    loading: state.userProfile.loading,
    contactName: state.userProfile.contactName,
    company: state.userProfile.company,
    errorMessage: state.userProfile.errorMessage,
    mfaEnabled: state.userProfile.mfaEnabled,
    phone: state.userProfile.userAttributes?.phone_number,
    email: state.userProfile.email,
    emailConfirmed: state.userProfile.emailConfirmed,
    contactNameSet: state.userProfile.contactNameSet,
    companySet: state.userProfile.CompanySet,
    dashboardPermissions: state.root.studyDetail?.dashboardPermissions,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles)(UserProfile))
);
