import {
  parsePhoneNumberFromString
} from "libphonenumber-js";

export const isValidEmail = inp => {
  if (/(.+)@(.+){2,}\.(.+){2,}/.test(inp)) {
    return true;
  } else {
    return false;
  }
};

export const isValidPhone = inp => {
  if (!inp) {
    return false;
  }
  const ph = parsePhoneNumberFromString(inp);
  return ph ? ph.isValid() : false; 
};

export const parsePhoneNumber = value => {
  return parsePhoneNumberFromString("+" + value.replace(/\D/g, ""));
};
