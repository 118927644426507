import React from "react";
import PropTypes from "prop-types";
import {
  withStyles,

} from "@material-ui/core";
import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';
import classNames from "classnames";

const styles = {
  avatar: {
    margin: 10
  },
  paper: {
    // backgroundColor: 'red',
    boxShadow: "none",
    width: 230
  },
  purpleAvatar: {
    color: "#fff",
    marginLeft: "1.5rem",
    backgroundColor: "#333" /*deepPurple[500]*/,
    fontSize: "10px",
    width: "1.5rem",
    height: "1.5rem",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#0273b5"
    }
  },
  redWarningAvatar: {
    color: "#fff",
    marginLeft: "1.5rem",
    backgroundColor: "red" /*deepPurple[500]*/,
    fontSize: "12px",
    fontWeight: 600,
    width: "1.5rem",
    height: "1.5rem",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#8b0000"
    }
  },
  btn: {
    "&:hover": {
      backgroundColor: "none"
    },
    "& purpleAvatar": {
      backgroundColor: "#0273b5"
    }
  },
  inline: {
    display: "inline-flex"
  },
  menuItem: {
    textTransform: "uppercase",
    fontSize: "0.55rem"
  },
  icon: {
    textAlign: "center",
    lineHeight: "15px",
    fontSize: "15px",
    color: "#fff"
  },
};

class UserMenu extends React.PureComponent {
  state = { open: false };

  render() {
    const { open } = this.state;
    const { initials, actions, classes, emailConfirmed } = this.props;

    return (
      <Grid className={classes.inline}>
        <Button
          id="userMenuClose"
          size="small"
          className={classes.btn}
          style={{ backgroundColor: "transparent" }}
          disableRipple
          //buttonRef={node => {
          //  this.anchorEl = node;
          //}}
          ref={node => {
            this.anchorEl = node;
          }}
          aria-owns={open ? "menu-list-grow" : undefined}
          aria-haspopup="true"
          onClick={actions.userProfileToggleDrawer}
        >
          <Avatar id="avatar" className={classNames(classes.inline, { [classes.redWarningAvatar]: (!initials || initials === "?" || !emailConfirmed), [classes.purpleAvatar]: (initials && initials !== "?" && emailConfirmed) })}>
            {(!initials || initials === "?") ? "!" : initials}
          </Avatar>
        </Button>

      </Grid>
    );
  }
}

UserMenu.propTypes = {
  classes: PropTypes.object.isRequired,
  user: PropTypes.object,
  emailConfirmed: PropTypes.bool,
  initials: PropTypes.string
};

export default withStyles(styles)(UserMenu);
