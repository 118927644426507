import './index.scss';
import './polyfills';
import React from 'react';
import { render } from 'react-dom'
//import registerServiceWorker from './registerServiceWorker';
import { composeWithDevTools } from 'redux-devtools-extension';
import { createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import Root from './root'
import { reducer } from './reducers';
import { rootSaga } from './sagas/index';
import APIService from './common/api';


//const _config = new Config();
const saga = createSagaMiddleware({
  context: {
    api: new APIService(),
  }
})
//const reduxDevTools = window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__() ;
// multiple sagas and multiple reduceres with shared state
// https://medium.com/@js_tut/the-saga-continues-magic-in-react-44da8d134285

const store = createStore(reducer, composeWithDevTools(
  applyMiddleware(saga)
  // other store enhancers if any
));

// run the saga   try out this https://github.com/GuillaumeCisco/redux-sagas-injector
saga.run(rootSaga);

render(
  <Root store={store} />,
  document.getElementById('root')
)

//registerServiceWorker();
