import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ErrorIcon from "@material-ui/icons/Error";
import InfoIcon from "@material-ui/icons/Info";
import CloseIcon from "@material-ui/icons/Close";
import CircularProgress from "@material-ui/core/CircularProgress";
//import amber from "@material-ui/core/colors/amber";
import IconButton from "@material-ui/core/IconButton";
import Snackbar from "@material-ui/core/Snackbar";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import WarningIcon from "@material-ui/icons/Warning";
import { withStyles } from "@material-ui/core/styles";
import Warning from "@material-ui/icons/Warning";

const variantIcon = {
  success: CheckCircleIcon,
  successDownload: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon
};

const styles1 = theme => ({
  root: {
    position: "relative",
    maxWidth: "100%"
  },
  success: {
    backgroundColor: "#4caf50" /*lightgreen" //green[600]*/,
    color: "white"
  },
  successDownload: {
    backgroundColor: "#4caf50" /*theme.palette.primary.dark*/,
    color: "white"
  },
  error: {
    backgroundColor: "#f44336" /*theme.palette.error.dark*/,
    color: "white"
  },
  info: {
    backgroundColor: "#2196f3" /*theme.palette.primary.dark*/,
    color: "white"
  },
  warning: {
    backgroundColor: "#ff9800" /*amber[700]*/,
    color: "white"
  },
  icon: {
    fontSize: 20
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1)
  },
  message: {
    display: "flex",
    alignItems: "center"
  },
  progress: {
    color: "white"
  },
  displayText: {
    marginLeft: "1rem"
  }
});
// make this generic
const resolveTextsByType = type => {
  let displayText;
  let finishText;
  if (type === "upload") {
    displayText = "Uploading";
    finishText = "Upload complete!";
  }
  if (type === "download") {
    displayText = "Downloading";
    finishText = "Download complete!";
  }
  if (type === "security") {
    displayText = "Enabling security";
    finishText = "Security enabled, logout to see it in action!";
  }
  if (type === "usercreate") {
    displayText = "User profile updated";
    finishText = "User profile updated!";
  }
  if (type === "error") {
    displayText = "Ooops!";
    finishText = "An error occured";
  }
  if (type === "browser") {
    displayText = "Browser compatibility";
    finishText = "Metawebservice is best viewed in Google Chrome. Some aspects of the site might cause issues in other browsers.";
  }
  if (type === "delete") {
    displayText = "Page refresh";
    finishText = "Refresh page to get the lastest updates.";
  }
  if (type === "collect") {
    displayText = "Collect initiated";
    finishText = "File will be availeble in Uploads soon.!";
  }
  if (type === "SasExport") {
    displayText = "Exporting SAS files";
    finishText = "Go to Uploads to get the sasexport zip file.!";
  }
  if (type === "refresh") {
    displayText = "Refresh page to see the lastest updates";
    finishText = "Refresh page to get the lastest updates.";
  }
  return { displayText, finishText };
};

const progressLoader = props => {
  const { classes, progress } = props;

  if (progress && progress.lengthComputable) {
    return (
      <div>{`${Math.round((progress.loaded / progress.total) * 100)} %`}</div>
    );
  }
  return <CircularProgress size={20} className={classes.progress} />;
};

const progressNoLoader = props => {
  const { classes } = props;
  return <CircularProgress size={20} className={classes.progress} />;
}

const canStartTimer = props => {
  if (props.state === "finished") {
    return true;
  }

  return false;
}
class MySnackbarContent extends React.Component {

  constructor(props) {
    super(props);
    this.state = { timerStarted: false };
  }

  initiateTimer() {
    // only used for error messages
    this.setState({ timerStarted: true });
    this.timer = setTimeout(() => this.closeItem(), 5000);
  }
  componentDidMount() {
    const { timerStarted } = this.state;
    if (!timerStarted && canStartTimer(this.props)) {
      this.initiateTimer();
    }
  }

  closeItem = () => {
    const { onClose, id } = this.props;
    clearTimeout(this.timer);
    onClose(id);
  }
  render() {
    const {
      classes,
      className,
      message,
      state,
      type,
      onClose,
      variant,
      progress,
      id,
      studyId,
      ...other
    } = this.props;
    const Icon = variantIcon[variant];
    const { finishText, displayText } = resolveTextsByType(type);

    return (
      <SnackbarContent
        className={classNames(classes[variant], classes.root, className)}
        aria-describedby="client-snackbar"
        message={
          <span id="client-snackbar" className={classes.message}>
            {/*Initiate*/}
            {state === "initiate" &&
              <span className={classes.displayText}>{message}</span>
            }
            {state === "initiateAuthError" &&
              <>
                <Warning /><span className={classes.displayText}>{message}</span>
              </>
            }
            {state === "progress" && progressLoader(this.props)}
            {state === "progress" && (
              <span className={classes.displayText}>{displayText}</span>
            )}

            {state === "finished" && (
              <Icon className={classNames(classes.icon, classes.iconVariant)} />
            )}
            {state === "finished" && (
              <span className={classes.displayText}>{finishText}</span>
            )}

            {/*Update*/}
            {state === "update" && progressNoLoader(this.props)}
            {state === "update" && (
              <span className={classes.displayText}>{message}</span>
            )}

            {/*Final*/}
            {state === "final" && (
              <Icon className={classNames(classes.icon, classes.iconVariant)} />
            )}
            {state === "final" && (
              <span className={classes.displayText}>{message}</span>
            )}
          </span>
        }
        action={[
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            className={classes.close}
            onClick={this.closeItem}
          >
            <CloseIcon className={classes.icon} />
          </IconButton>
        ]}
        {...other}
      />
    );
  }
}

MySnackbarContent.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  message: PropTypes.node,
  onClose: PropTypes.func,
  variant: PropTypes.oneOf([
    "success",
    "warning",
    "error",
    "info",
    "successDownload",
    "delete",
    "collect",
  ]).isRequired
};

const MySnackbarContentWrapper = withStyles(styles1)(MySnackbarContent);

function SnackbarList(props) {
  const {
    /*classes,
    className,
    message,
    state,*/
    onClose,
    /*variant,
    id,*/
    notifications
    /*...other*/
  } = props;

  return (
    <div key="da-wraper">
      {notifications.map((item, index) => (
        <MySnackbarContentWrapper key={index}
          variant={
            item.type === "upload"
              ? "success"
              : item.type === "error"
                ? "error"
                : item.type === "browser"
                  ? "error"
                  : item.type === "collect"
                    ? "success"
                    : "successDownload"
          }
          {...item}
          onClose={onClose}
        />
      ))}
    </div>
  );
}

const styles2 = theme => ({
  margin: {
    margin: theme.spacing(1)
  },
  wrapper: {
    position: "absolute",
    zIndex: 2000
  }
});

class ToastWrapper extends React.PureComponent {
  render() {
    const { classes, toasts, onClose } = this.props;

    return (
      <div className={classes.wrapper}>
        <Snackbar
          key="snackbar-wrap"
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right"
          }}
          open={true}
          autoHideDuration={6000}
        >
          <SnackbarList notifications={toasts} onClose={onClose} />
        </Snackbar>
      </div>
    );
  }
}

ToastWrapper.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles2)(ToastWrapper);
