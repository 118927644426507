import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core";
import classNames from "classnames";
import Button from "../../ui/Button";
import loginStyles from "../../auth/LoginStyles";
import Modal from "./Modal";

const paperStyles = theme => ({
  ...theme.typography,
  paper: {
    width: 400,
    height: 300,
    borderRadius: "8px"
  },

  createContent: {
    width: "90%",
    position: "relative",
    margin: "1rem auto"
  },
  initialForm: {
    margin: "3rem auto"
  },
  buttonWrapper: {
    background: "#0273b5",
    border: 0,
    color: "white",
    padding: "0 2rem",
    height: "44px",
    borderRadius: "2rem",
    top: "1.5rem",
    // minWidth: '10rem',
    "&:hover": {
      boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
      background: "#0273b5"
    },
    display: 'inline',
    width: "45%"
  },
  btnInbox: {
    background: "#e91e63",
    "&:hover": {
      boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
      background: "#e91e63"
    },
    float: 'right',
  },

  icon: {
    position: "absolute",
    textAlign: "center",
    lineHeight: "2rem",
    top: "50%",
    left: "50%",
    fontSize: "4rem",
    color: theme.palette.primary.light,
    transform: `translate3d(-50%, -50%, 0)`
  },

  modalHeader: {
    margin: "0.3rem 0 0 1rem",
    ...theme.typography,
    fontSize: "1rem",
    color: "#9e9e9e"
  },
  m2: {
    marginTop: '1rem'
  },
  infoText: {
    ...theme.typography,
    fontSize: "0.8rem",
    color: "#9e9e9e"
  }
});

const styles = theme => ({
  ...loginStyles(theme),
  ...paperStyles(theme)
});

class IdleModal extends React.PureComponent {
  render() {
    const { classes, logout, stayLoggedIn } = this.props;

    return (
      <Modal
        classes={classes}
        title={"Are you still there?"}
        maxWidth="lg"
        open={true}
      >
        <div className={classes.createContent}>
          <p className={classes.infoText}>Your presence have been away for more than 15 minutes, do you want to stay logged in?</p>
          <Grid container direction="column">
            <Grid item>
              <div className={classes.relative}>
                <Button
                  className={classes.buttonWrapper}
                  onClick={stayLoggedIn}
                >
                  Stay in
                </Button>
                <Button
                  className={classNames(
                    classes.buttonWrapper,
                    classes.btnInbox
                  )}
                  onClick={logout}
                >
                  Sign out
                </Button>
              </div>
            </Grid>
            <Grid item>
              <div className={classNames(classes.relative, classes.m2)} />
            </Grid>
          </Grid>
        </div>
      </Modal>
    );
  }
}

IdleModal.propTypes = {
  classes: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired
};

export default withRouter(withStyles(styles)(IdleModal));
