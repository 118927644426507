
import './PointingArrow.scss';
import React from 'react';

function PointingArrow(props) {
    return (
        <div className="pointing-arrow">
            <span></span>
            <span></span>
            <span></span>
        </div>
    );
}

export default PointingArrow;