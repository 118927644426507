
export const SAS_JOB_UPDATE_PROGRAM = 'SAS_JOB_UPDATE_PROGRAM';
export const SAS_JOB_CREATE_PROGRAM = 'SAS_JOB_CREATE_PROGRAM';
export const SAS_JOB_RECEIVE = 'SAS_JOB_RECEIVE';
export const SAS_JOB_ERROR = 'SAS_JOB_ERROR';
export const SAS_JOB_RESET = 'SAS_JOB_RESET';
export const SAS_JOB_INIT = 'SAS_JOB_INIT';
export const SAS_JOB_PLACE_INITIAL_JOB = 'SAS_JOB_PLACE_INITIAL_JOB';
export const SAS_JOB_PLACE_COMPARER_JOB = 'SAS_JOB_PLACE_COMPARER_JOB';
export const SAS_JOB_DELETE_PROGRAM = 'SAS_JOB_DELETE_PROGRAM';
export const SAS_JOB_DELETE_ALL = 'SAS_JOB_DELETE_ALL';
export const EXPORT_ALL_SASJOBS = 'EXPORT_ALL_SASJOBS'
export const EXPORT_PROGRAMMING_PLAN = 'EXPORT_PROGRAMMING_PLAN'
export const GET_EXECUTION_FOLDERS = 'GET_EXECUTION_FOLDERS'
export const GET_EXECUTION_FOLDERS_INITIATED = 'GET_EXECUTION_FOLDERS_INITIATED'
export const VALIDATE_EXECUTION_FOLDERS_RECEIVED = 'VALIDATE_EXECUTION_FOLDERS_RECEIVED'
export const VALIDATE_EXECUTION_FILES_RECEIVED = 'VALIDATE_EXECUTION_FILES_RECEIVED'
export const EXECUTION_FOLDERS_RECEIVED = 'EXECUTION_FOLDERS_RECEIVED'
export const DELETE_EXECUTION_FOLDER = 'DELETE_EXECUTION_FOLDER'
export const GET_EXECUTION_FILES = 'GET_EXECUTION_FILES'
export const APPLY_EXECUTION_ORDER = 'APPLY_EXECUTION_ORDER'
export const GET_EXECUTION_FILES_INITIATED = 'GET_EXECUTION_FILES_INITIATED'
export const EXECUTION_FILES_RECEIVED = 'EXECUTION_FILES_RECEIVED'
export const UPDATED_EXECUTION_ORDER_RECEIVED = 'UPDATED_EXECUTION_ORDER_RECEIVED'
export const DELETE_EXECUTION_FILE = 'DELETE_EXECUTION_FILE'
export const UPDATE_DEPENDENCIES = 'UPDATE_DEPENDENCIES'
export const CREATE_SASJOB_TOGGLE_MODAL = 'CREATE_SASJOB_TOGGLE_MODAL'
export const FILE_COMPARER_SAS_RESET = 'FILE_COMPARER_SAS_RESET'
export const SET_SASJOB_REFRESH_REQUIRED = 'SET_SASJOB_REFRESH_REQUIRED'
export const FILE_COMPARER_OPEN_MODAL = 'FILE_COMPARER_OPEN_MODAL'

export const setSasJobRefreshRequired = (id) => ({
    type: SET_SASJOB_REFRESH_REQUIRED,
    payload: { id }
})

export const createSasJobToggleModal = () => ({
    type: CREATE_SASJOB_TOGGLE_MODAL,
    payload: {}
});

export const GetExecutionFolders = () => ({
    type: GET_EXECUTION_FOLDERS,
    payload: {}
})

export const GetExecutionFoldersInitiated = () => ({
    type: GET_EXECUTION_FOLDERS_INITIATED,
    payload: {}
})

export const GetExecutionFilesInitiated = () => ({
    type: GET_EXECUTION_FILES_INITIATED,
    payload: {}
})

export const executionFoldersReceived = (folders) => ({
    type: EXECUTION_FOLDERS_RECEIVED,
    payload: { folders }
})

export const ValidateExecutionFoldersReceived = (sasExecutionFolders, resTopologicSort) => ({
    type: VALIDATE_EXECUTION_FOLDERS_RECEIVED,
    payload: { sasExecutionFolders, resTopologicSort }
})

export const ValidateExecutionFilesReceived = (sasExecutionFiles, resTopologicSort) => ({
    type: VALIDATE_EXECUTION_FILES_RECEIVED,
    payload: { sasExecutionFiles, resTopologicSort }
})

export const deleteExecutionFolder = (folder) => ({
    type: DELETE_EXECUTION_FOLDER,
    payload: { folder }
})

export const ApplyExecutionOrder = (study, folder) => ({
    type: APPLY_EXECUTION_ORDER,
    payload: { study, folder }
})

export const GetExecutionFiles = (folder) => ({
    type: GET_EXECUTION_FILES,
    payload: { folder }
})

export const UpdateDependencies = (studyId, folder) => ({
    type: UPDATE_DEPENDENCIES,
    payload: { studyId, folder }
})

export const executionFilesReceived = (files) => ({
    type: EXECUTION_FILES_RECEIVED,
    payload: { files }
})

export const updatedExecutionOrderReceived = (rowsUpdated) => ({
    type: UPDATED_EXECUTION_ORDER_RECEIVED,
    payload: { rowsUpdated }
})

export const deleteExecutionFile = (file) => ({
    type: DELETE_EXECUTION_FILE,
    payload: { file }
})

export const sasJobPlaceInitialJob = (studyId, sasJob, versions, latest) => ({
    type: SAS_JOB_PLACE_INITIAL_JOB,
    payload: { studyId, sasJob, versions, latest }
});

export const sasJobPlaceComparerJob = (studyId, sasJob) => ({
    type: SAS_JOB_PLACE_COMPARER_JOB,
    payload: { studyId, sasJob }
});

export const fileComparerSasReset = () => ({
    type: FILE_COMPARER_SAS_RESET,
    payload: {}
})

export const sasJobInit = (studyId, sasJobId) => ({
    type: SAS_JOB_INIT,
    payload: { studyId, sasJobId }
});

export const fileComparerOpenModal = (sasJobId, studyId) => ({
    type: FILE_COMPARER_OPEN_MODAL,
    payload: { sasJobId, studyId }
});

export const sasJobReset = () => ({
    type: SAS_JOB_RESET,
    payload: {}
});

export const exportAllSasJobs = () => ({
    type: EXPORT_ALL_SASJOBS,
    payload: {}
});
export const exportProgrammingPlan = () => ({
    type: EXPORT_PROGRAMMING_PLAN,
    payload: {}
})

export const sasJobReceive = sasJob => ({
    type: SAS_JOB_RECEIVE,
    payload: { sasJob }
});
export const sasJobError = () => ({
    type: SAS_JOB_ERROR,
    payload: {}
});
export const sasJobUpdateProgram = sasCode => ({
    type: SAS_JOB_UPDATE_PROGRAM,
    payload: { sasCode }
});
export const sasJobCreateProgram = (studyId, program, folder, history) => ({
    type: SAS_JOB_CREATE_PROGRAM,
    payload: { studyId, program, folder, history }
});
export const sasJobDeleteProgram = (studyId, sasJobId /*, history*/) => ({
    type: SAS_JOB_DELETE_PROGRAM,
    payload: { studyId, sasJobId /*, history*/ }
});
export const sasJobDeleteAll = (id = undefined) => ({
    type: SAS_JOB_DELETE_ALL,
    payload: { id: id || undefined }
});