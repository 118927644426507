import React from "react";
import PropTypes from "prop-types";
import * as actions from "../../actions/";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress";

import { withStyles } from "@material-ui/core/styles";
// import ModalTitle from "../components/ModalTitle";
import { viewModes } from "../../constants";
import { withRouter } from "react-router-dom";
//import DownloadFilesTable from "../components/DownloadFilesTable";
//import SasJobsListTable from "../components/SasJobsListTable";
//import { columnsDownload, columnsUpload } from "../../theme/TableStyles";
import { versionsTable } from "../../selectors/tableSelector";
import Modal from "./Modal";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import classNames from "classnames";
import { CloudDownload } from '@material-ui/icons';
import IconButton from "@material-ui/core/IconButton";
import { Scrollbars } from "react-custom-scrollbars";

const styles = theme => ({
  container: {
    border: "1px solid #e0dddd",
    marginTop: "1.5rem"
  },
  modalHeader: {
    margin: "0 0 0 1rem",
    ...theme.typography,
    fontSize: "1rem",
    color: "#FFE000"
  },
  paper: {
    borderRadius: "8px"
  },
  table: {
    minWidth: 600,
    fontFamily: theme.typography.fontFamily,
    borderRadius: '8px'
  },
  tableHeader: {
    background: "white",
    color: "black",
    "&:focus": {
      outline: 0
    },
    fontSize: "0.5rem",
    textTransform: "uppercase",
    borderTopRightRadius: '8px',
    borderRadius: '8px'

  },
  tableRowHead: {
    borderRadius: '8px',
    borderTopLeftRadius: '8px',
    borderTopRightRadius: '8px'
  },
  tableCellHeader: {
    fontSize: "0.5rem",
    color: "black",
    textTransform: "uppercase",
    borderTopLeftRadius: '8px',
    borderTopRightRadius: '8px',
    border: '1px'
  },
  tableCellBody: {
    fontSize: "0.6rem"
  },
  minWidth: {
    minWidth: "4.5rem"
  },
  row: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.background.default
    },
    height: "2.5rem",
    marginBottom: "0.5rem",
    width: "100%",
    position: "relative",
    borderTop: "1px dotted lightgrey",
    "&:last-of-type": {
      border: 0
    },
  },
});

//

// reload current view oíf delete files or update
class FileVersionsModal extends React.PureComponent {
  render() {
    const { classes } = this.props;
    const {
      actions,
      rows,
      tableLoading,
      /*cnt,
      allSelected,
      tableSort,*/
      viewMode
    } = this.props;

    return (
      <Modal
        /*fullScreen*/
        titleBorder
        fullWidth={true}
        maxWidth={"lg"}
        onClose={actions.fileVersionsReset}
        classes={classes}
        title={"File versions"}
        titleBackground
        /*titleBackground*/
        /*titleDark*/
        /*maxWidth="lg"*/
        open={true}
      >
        {tableLoading && <CircularProgress size={24} />}
        {(!tableLoading && viewMode !== viewModes.SASJOBS) && (
          <div className={classes.container}>
            <Scrollbars style={{ height: 300 }}>
              <Table className={classes.table}>
                <TableHead className={classes.tableHeader}>
                  <TableRow className={classes.tableRowHead}>
                    <TableCell className={classes.tableCellHeader} style={{ width: "350px" }}>
                      Name
                    </TableCell>
                    <TableCell className={classes.tableCellHeader} style={{ width: "100px" }}>
                      Version
                    </TableCell>
                    <TableCell className={classes.tableCellHeader} style={{ width: "180px" }}>
                      Created Date
                    </TableCell>
                    <TableCell className={classes.tableCellHeader} align="center" style={{ width: "80px" }}>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map(row => {
                    return (
                      <TableRow key={row.id} className={classes.row}>
                        <TableCell
                          className={classes.tableCellBody}
                          component="th"
                          scope="row"
                        >
                          <b>{row.fileName}</b>
                        </TableCell>
                        <TableCell className={classes.tableCellBody}>
                          {row.version}
                        </TableCell>
                        <TableCell className={classes.tableCellBody}>
                          {row.created}
                        </TableCell>
                        <TableCell className={classNames(classes.tableCellBody)}>
                          <IconButton aria-label="Download" onClick={() => actions.studyDownloadAllFiles(row.id)}>
                            <CloudDownload className="download--small"></CloudDownload>
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    )
                  }
                  )}
                </TableBody>
              </Table>
            </Scrollbars>
          </div>
        )}
        {/*(!tableLoading && viewMode === viewModes.SASJOBS) && (
          <div className={classes.container}>
            <SasJobsListTable
              actions={actions}
              rows={rows}
              sortColumns={actions.studySasTableSort}
              tableSort={tableSort}
              onAllToggled={actions.studyAllSASFilesToggles}
              onSasDownloadAll={actions.studyDownloadAllSasFiles}
              onSasJobToggled={actions.sasJobToggled}
              cnt={cnt}
              allSelected={allSelected}
            />
          </div>
        )*/}
      </Modal>
    );
  }
}

// we need a selector, route change should kill all modals
FileVersionsModal.propTypes = {
  classes: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired
};

function mapStateToProps(state) {
  return versionsTable(state.fileVersions);
}
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}
export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles)(FileVersionsModal))
);
