import React from 'react';
import PropTypes from 'prop-types';
// import classNames from "classnames";
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import {Link} from 'react-router-dom'

const styles = theme => ({
    root: {
      border: 0,
      color: '#333',
      height: 16,
      "&:hover": {
        color: '#0273b5'/*'#2196F3'*/,
        background: 'none',
      },
      ...theme.typography,
    },
    rootBackground: {
      background: 'white',
      color: '#91d1ec'/*theme.palette.primary.light*/,
      padding: '0 1rem',
      borderRadius: '32px',
      boxShadow: 'none',
      height: '1.8rem',
      minWidth: '5rem',
      border: '1px solid #91d1ec',
      "&:hover": {
        color: 'white',
        background: '#91d1ec'
      },
      ...theme.typography,
    },
    label: {
      textTransform: 'none',
      fontSize: '0.6rem',
      [theme.breakpoints.down("sm")]: {
        fontSize: "0.8rem",
      }

    },
    labelBackground: {
      textTransform: 'none',
      fontSize: '0.9rem',
      whiteSpace: 'nowrap',
    },
    selected: {
      padding: '0 1rem',
      borderRadius: '32px',
      boxShadow: 'none',
      height: '1.8rem',
      minWidth: '5rem',
      "&:hover": {
        color: 'white',
        background: '#0273b5'/*theme.palette.primary.light*/,
      },
      ...theme.typography,
      background: '#0273b5'/*theme.palette.primary.light,*/,
      color: 'white',
      border: '1px solid #91d1ec',
      cursor: 'default',
    }
});

function LinkBtn(props) {
    // we might have overrides
    const { classes, children, disabled, to, background, selected, onClick} = props;
    
    const getClasses = {
      root: !background ? classes.root : selected ? classes.selected : classes.rootBackground,
      label: !background ? classes.label : classes.labelBackground,
    }
    if (to) {
      return (
        <Button
          component={Link}
          disableRipple
          fullWidth={false}
          disabled={disabled}
          color="primary"
          size={'small'}
          to={to}
          classes={getClasses}
        >
          {children}
        </Button>
      );
    }
    return <Button
          fullWidth={false}
          disabled={disabled}
          color="primary"
          size={'small'}
          classes={getClasses}
          onClick={onClick}
        >
          {children}
        </Button>
    
  }
  
  LinkBtn.propTypes = {
    classes: PropTypes.object.isRequired,
  };
  
  export default withStyles(styles)(LinkBtn);