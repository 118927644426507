import React from "react";
import PropTypes from "prop-types";
import * as actions from "../../actions/";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { formatDateTime } from "../..//utils/date";
import CircularProgress from "@material-ui/core/CircularProgress";
import Drawer from "@material-ui/core/Drawer";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import loginStyles from "../../auth/LoginStyles";

const paperStyles = theme => ({
  paper: {
    width: 550
  },
  requestBtn: {
    display: "inline-block",
    background: "#0273b5",
    "&:hover": {
      cursor: "pointer",
      background: "black"
    },
    transition: "all 180ms ease-out",
    padding: "0 0.8rem"
  },
  rejectBtn: {
    display: "inline-block",
    background: "#e91e63",
    "&:hover": {
      cursor: "pointer",
      background: "black"
    },
    transition: "all 180ms ease-out",
    padding: "0 0.8rem",
    marginRight: "0.25rem"
  },
  statusItem: {
    color: "white",
    textAlign: "center",
    borderRadius: "18px",
    height: "1.2rem",
    lineHeight: "1.2rem",
    fontSize: "0.6rem",
    fontWeight: 500,
    top: "0.2rem",
    minHeight: "1rem",

    ...theme.typography
  },
  content: {
    width: "90%",
    margin: "0 auto"
  },
  h3: {
    ...theme.typography,
    fontSize: "1rem",
    color: theme.palette.grey[500],
    marginTop: "0.7rem"
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  },
  maillist: {
    marginTop: "2rem"
  },
  mailItem: {
    ...theme.typography,
    height: "4rem",
    width: "100%",
    padding: "0.5rem",
    borderBottom: "1px dotted #424141",
    background: "white",
    position: "relative",
    "&:hover": {
      background: "#f9f9f9"
    }
  },
  mailDate: {
    ...theme.typography,
    fontSize: "0.6rem",
    color: theme.palette.grey[500],
    position: "absolute",
    right: "0.5rem",
    top: "0.5rem"
  },
  mailFrom: {
    ...theme.typography,
    fontSize: ".6rem",
    color: theme.palette.grey[500]
  },
  mailTitle: {
    ...theme.typography,
    fontSize: "0.7rem",
    color: theme.palette.grey[800],
    marginTop: "0.2rem",
    maxWidth: "70%"
  },
  mailActions: {
    position: "absolute",
    right: "0.5rem",
    top: "2rem"
  },
  tabs: {
    position: 'absolute',
    top: '1.75rem',
  },
  tab: {
    ...theme.typography,
    fontSize: "0.7rem",
    position: "relative",
    display: "inline-block",
    color: theme.palette.grey[500],
    marginRight: "1rem",
    "&:after": {
      content: "''",
      position: "absolute",
      top: "0.05rem",
      right: "-0.5rem",
      height: "0.5rem",
      width: "1px",
      background: "#673ab7"
    }
  },
  tab2: {
    ...theme.typography,
    color: theme.palette.grey[500],
    fontSize: "0.7rem",
    position: "relative",
    display: "inline-block",
    marginRight: "0.5rem"
  },
  tabHover: {
    cursor: "pointer",
    fontSize: "0.6rem",
    "&:hover": {
      color: theme.palette.primary.light
    }
  },
  tabSelected: {
    color: theme.palette.grey[800]
  },
  spinner: {
    position: 'absolute',
    top: '-0.5rem',
    right: '2.5rem'
  }
});
const styles = theme => ({
  ...loginStyles(theme),
  ...paperStyles(theme)
});

class Inbox extends React.PureComponent {
  state = { open: false, showRejected: false };

  componentDidMount() {
    setTimeout(() => {
      this.setState({ open: true });
    }, 10);
  }
  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      const { actions } = this.props;
      actions.userProfileToggleInbox();
    }
  }

  toggleDrawer = () => {
    const { actions } = this.props;
    this.setState({ open: false });
    setTimeout(() => {
      actions.userProfileToggleInbox();
    }, 150);
  };
  handleTabClick = evt => {
    this.setState({ showRejected: evt.target.id === 'rejected' })
  };
  render() {
    const { open, showRejected } = this.state;
    const { classes, studyInvites, actions } = this.props;
    const pending = studyInvites && studyInvites.length ? studyInvites.filter(item => !item.invitationRejectedDateTime) : [];
    const rejected = studyInvites && studyInvites.length ? studyInvites.filter(item => item.invitationRejectedDateTime) : [];
    const invites = showRejected ? rejected : pending;

    return (
      <Drawer
        anchor="right"
        open={open}
        onClose={this.toggleDrawer}
        classes={{
          paper: classes.paper
        }}
      >
        <div className={classes.content}>
          <IconButton
            aria-label="Close"
            className={classes.closeButton}
            onClick={this.toggleDrawer}
          >
            <CloseIcon />
          </IconButton>
          <h3 className={classes.h3}>Inbox</h3>
          <div className={classes.tabs}><span
            id="pending"
            className={classNames(
              classes.tab,
              classes.tabHover,
              !showRejected ? classes.tabSelected : ""
            )}
            onClick={this.handleTabClick}
          >
            Pending ({pending.length})
          </span>
            <span
              id="rejected"
              className={classNames(
                classes.tab2,
                classes.tabHover,
                showRejected ? classes.tabSelected : ""
              )}
              onClick={this.handleTabClick}
            >
              Rejected ({rejected.length})
          </span>
          </div>
          {studyInvites && studyInvites.length > 0 && (
            <ul className={classes.maillist}>
              {invites.map(item => {
                return (
                  <li
                    key={item.studyUserInvitationId}
                    className={classes.mailItem}
                  >
                    <div className={classes.mailFrom}>
                      {item.contactName}
                    </div>
                    <div className={classes.mailDate}>
                      {formatDateTime(item.invitationSendDateTime)}
                    </div>
                    <div className={classes.mailTitle}>
                      Invite to: {item.studyName},{" "}
                      {item.studyProject}
                    </div>

                    <div className={classes.mailActions}>
                      {item.loading && <CircularProgress size={20} className={classes.spinner} />}
                      {!item.loading && <span
                        className={classNames(
                          classes.rejectBtn,
                          classes.statusItem
                        )}
                        onClick={() =>
                          actions.userProfileResolveInvitation(
                            false,
                            item.studyUserInvitationId
                          )
                        }
                      >
                        Reject
                      </span>}
                      {!item.loading && <span
                        className={classNames(
                          classes.requestBtn,
                          classes.statusItem
                        )}
                        onClick={() =>
                          actions.userProfileResolveInvitation(
                            true,
                            item.studyUserInvitationId
                          )
                        }
                      >
                        Accept
                      </span>}
                    </div>
                  </li>
                );
              })}
            </ul>
          )}
        </div>
      </Drawer>
    );
  }
}

Inbox.propTypes = {
  classes: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired
};

function mapStateToProps(state) {
  return state.userProfile;
}
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles)(Inbox))
);
