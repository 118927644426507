import { eventChannel, END } from "redux-saga";

export function uploadChannel(s3, S3PutObjectParams) {
  return eventChannel(emitter => {
    const s = s3
      .putObject(S3PutObjectParams)
      .on('httpUploadProgress', progress => {
        emitter({ progress });
        //console.log("s3 progress ", progress);
      })
      .send((error, data) => {
        if (error) {
          //console.log(error);
          emitter({ err: error });
        } else {
          emitter({ success: data });
          emitter(END);
        }
      });
    return () => s;
  });
}
