import * as actions from '../actions';
import { takeLatest, call, put, getContext, select } from "redux-saga/effects";
import { getCreateStudyState } from "../selectors/storeselectors";

const emptyArray = [];

export function* loadProjectLabels() {
  try {
    const api = yield getContext('api');
    const res = yield call(api.get, 'study/GetStudyProjects');
    yield put(actions.createStudyProjectLabelsReceived(res || emptyArray));

  } catch (error) {
    // die silent not important call
    console.log('error in resp ', error);
    //yield put(actions.onApiError());
  }
}

export function* createStudy() {
  try {
    //const state = yield select();
    const { studyName, studyProject, studyMetaPackage } = yield select(getCreateStudyState);


    if (studyName && studyName.length > 1 && studyProject && studyProject.length > 1) {
      const api = yield getContext('api');

      const params = {
        StudyMetaPackage: (studyMetaPackage) ? studyMetaPackage : 'ADHOC',
        StudyName: studyName,
        StudyProject: studyProject
      }

      const res = yield call(api.post, 'Study/CreateStudy', params);
      if (res && !res.status) {
        yield put(actions.createStudyStudyCreated(res));
        yield put(actions.hideOnboarding())
        const studies = yield call(api.get, "Study/GetStudiesV2");
        //console.log(studies)
        yield put(actions.studiesReceived(studies ? studies : []));
      }
      else if (res && res.status === 404) {
        let errorMessage = [];
        errorMessage.push('notConfirmed');
        yield put(actions.createStudyValidationError(errorMessage));
      }
    }
    else {

      let errorMessage = [];
      if (!studyName || studyName.length.length < 2) {
        errorMessage.push('studyName');
      }
      if (!studyProject || studyProject.length < 2) {
        errorMessage.push('studyProject');
      }
      yield put(actions.createStudyValidationError(errorMessage));
    }

  } catch (error) {

    console.log('error in resp ', error);
    yield put(actions.onApiError());
  }
}


export function* createStudySagas() {
  yield takeLatest(actions.CREATE_STUDY_PROJECT_LABELS_LOAD, loadProjectLabels);
  yield takeLatest(actions.CREATE_STUDY_SUBMIT, createStudy);
}