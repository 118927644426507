const permissionRequestKey = 'permissionsRequestKey';

const getRequestId = (studyId, permission) => {
    return (studyId + '-' + permission).toString();
}
export const setPermissionRequest = (studyId, permission) => {
    let list = JSON.parse(localStorage.getItem(permissionRequestKey));
    const id = getRequestId(studyId, permission);

    if (list && list.length) {
        if (!list.includes(id)) {
            list.push(id);
        }
    }
    else {
        list = [id];
    }
  
    localStorage.setItem(permissionRequestKey, JSON.stringify(list));
}

export const getPermissionRequestItem = (studyId, permission) => {
    let list = JSON.parse(localStorage.getItem(permissionRequestKey));
    if (list && list.length) {
        if (list.includes(getRequestId(studyId, permission))) {
            return true;
        }
    }
    return false;
   
}

