import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { isValidEmail } from "../../utils/validation";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import CircularProgress from "@material-ui/core/CircularProgress";
import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import loginStyles from "../../auth/LoginStyles";
import Button from "../../ui/Button";
import PermissionTable from "../components/PermissionsTable";
import AutoCompleteField from "../../ui/AutoCompleteField";
import ModalTitle from "../components/ModalTitle";
import HorizontalStepper from "./HorizontalStepper";
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import { Scrollbars } from "react-custom-scrollbars";

const paperStyles = theme => ({
  ...theme.typography,
  paper: {
    width: 800,
    height: 350,
    borderRadius: '8px'
  },
  paperLarge: {
    width: 1000,
    height: 700,
    borderRadius: '8px'
  },
  dialog: {
    padding: 0,
    overflow: "hidden"
  },
  createContent: {
    width: "60%",
    position: "relative",
    margin: "2rem auto"
  },
  buttonWrapper: {
    position: "absolute",
    right: "0",
    top: "1rem",
    background: "#0273b5",
    border: 0,
    color: "white",
    padding: "0 1rem",
    height: "2rem",
    borderRadius: "2rem",
    minWidth: "5rem",
    "&:hover": {
      boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
      background: "#0273b5"
    },
    ...theme.typography
  },
  buttonWrapperBack: {
    position: "absolute",
    top: "1rem",
    right: '130px',
    background: "pink",
    border: 0,
    color: "white",
    padding: "0 1rem",
    height: "2rem",
    borderRadius: "2rem",
    minWidth: "5rem",
    "&:hover": {
      boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
      background: "pink"
    },
    ...theme.typography
  },
  content: {
    width: "90%",
    margin: "1rem auto"
  },
  permissionTable: {
    margin: "2.2rem auto"
  },
  userSelect: {
    position: "relative",
    top: "2.5rem",
    left: '4.5rem',
    display: "inline-block",
    verticalAlign: "text-top",
    height: "7rem",
    width: "50%",
    boxSizing: "border-box"
  },
  stepper: {
    width: "100%",
    left: "0.5rem",
    top: "1rem"
  },
  startBtn: {
    right: "5rem",
    top: "-3.6rem"
  },
  spinner: {
    position: "absolute",
    top: '4rem',
    left: 0,
    right: 0,
    bottom: 0,
    margin: "auto"
  },
  icon: {
    position: "absolute",
    textAlign: "center",
    lineHeight: "2rem",
    top: "45%",
    left: "50%",
    fontSize: "4rem",
    color: /*theme.palette.primary.light*/ "#4caf50",
    transform: `translate3d(-50%, -0%, 0)`
  },
  succesLabel: {
    position: "absolute",
    textAlign: "center",
    lineHeight: "2rem",
    top: "75%",
    left: "50%",
    fontSize: "0.7rem",
    transform: `translate3d(-50%, -50%, 0)`
  },
  errorLabel: {
    ...theme.typography,
    position: 'absolute',
    top: '4rem',
    left: '4rem',
    fontSize: '0.7rem',
    color: 'red',

  },

});
const styles = theme => ({
  ...loginStyles(theme),
  ...paperStyles(theme)
});

class StudyInviteUser extends React.PureComponent {
  state = {
    init: false,
    showUserlist: false,
    showPermissions: false,
    anchorEl: null
  };
  initialStudyFetch = () => {
    const { actions, studyId } = this.props;
    const { init } = this.state;
    if (!init) {
      this.setState({ init: true });
      actions.studyInviteFetchData(studyId);
    }
  };
  componentDidMount() {
    this.initialStudyFetch();
  }
  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      const { onClose } = this.props;
      onClose();
    }
  }
  componentWillUnmount() {
    const { actions } = this.props;
    actions.studyInviteReset();
  }

  handleKeyPress = e => {

    const { actions, userEmail } = this.props;

    if (e.key === "Enter") {
      if (isValidEmail(userEmail)) {
        actions.studyInviteShowPermissions();
      } else {
        this.setState({ emailError: 'Please enter a valid e-mail' })
      }
    }
  };

  handleChange = value => {
    const { emailError } = this.state;
    //const { value, altered } = obj;
    const { actions } = this.props;
    const emailValid = isValidEmail(value);
    if (emailError && emailValid) {
      this.setState({ emailError: undefined });
    }
    actions.studyInviteUpdateInput("userEmail", value);
  };

  handleOpen = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  handleNextClick = () => {
    const { emailError } = this.state;
    const { actions, userEmail } = this.props;

    if (isValidEmail(userEmail)) {
      actions.studyInviteShowPermissions();
    }
    else {
      if (!emailError) {
        this.setState({ emailError: 'Please enter a valid e-mail' });
      }
    }
  }

  handleClick = () => {
    const { actions } = this.props;
    actions.studyInviteSubmit();
  };

  handleUserPopup = () => {
    this.setState({ showUserlist: !this.state.showUserlist });
  };

  render() {
    const { emailError } = this.state;
    const {
      classes,
      actions,
      /*studyId,*/
      permissions,
      /*users,*/
      userEmail,
      /*isAdmin,*/
      loading,
      /*errorMessage,
      showPermissions,*/
      userEmails,
      onClose,
      step,
      open,
      /*...other*/
    } = this.props;

    const hasPermissions = () => {
      let cnt = 0;
      permissions &&
        permissions.length &&
        permissions.forEach(item => {
          if (item.selected) {
            cnt++;
          }
        });
      if (cnt > 0) {
        return true;
      } else {
        return false;
      }
    };

    let selectablePermissions = []
    if (permissions && step === 1) {
      if (userEmail.split("@")[1].toUpperCase() !== "ME-TA.DK") {
        selectablePermissions = permissions.map(perm => {
          let item
          (perm.permission === "SASUPLOAD") ?
            item = { ...perm, disabled: true } :
            item = { ...perm, disabled: false }
          return item
        })
      }
      else {
        selectablePermissions = permissions.map(perm => {
          return { ...perm, disabled: false }
        })
      }
    }

    return (
      <Dialog
        /*disableBackdropClick={false}*/
        disableEscapeKeyDown={false}
        /*onBackdropClick={onClose}*/
        onClose={onClose}
        aria-labelledby="confirmation-dialog-title"
        maxWidth="lg"
        classes={{
          paper: step === 1 ? classes.paperLarge : classes.paper
        }}
        open={open}
      /*{...other}*/
      >
        <ModalTitle id="customized-dialog-title" onClose={onClose} background>
          {/*<h6 className={classes.modalHeader}>*/}
          <GroupAddIcon style={{ color: "#004884", top: "5px", position: "relative" }} /> Invite user
          {/*</h6>*/}
        </ModalTitle>


        <DialogContent classes={{ root: classes.dialog }}>
          <div>
            <HorizontalStepper
              activeStep={step}
              classes={{
                root: classes.stepper
              }}
            />
            {step === 0 && (
              <div className={classes.content}>
                <div
                  className={classes.userSelect}
                  onKeyPress={this.handleKeyPress}
                >
                  <AutoCompleteField
                    initialValue={userEmail}
                    placeholder={'Input e-mail here'}
                    allSuggestions={userEmails.map(item => {
                      return { label: item };
                    })}
                    onSelect={this.handleChange}
                  />
                  {emailError && <p className={classes.errorLabel}>{emailError}</p>}
                </div>

                <div className={classes.relative}>
                  <Button
                    id="inviteNext"
                    className={classNames(
                      classes.buttonWrapper,
                      classes.startBtn
                    )}
                    onClick={this.handleNextClick}
                  >
                    Next
                  </Button>
                </div>
              </div>
            )}
            {step === 1 && permissions && permissions.length > 0 && (
              <div
                className={classNames(
                  classes.content,
                  classes.permissionTable
                )}
              >
                <Scrollbars style={{ height: 300 }}>
                  <PermissionTable
                    permissions={/*permissions*/ selectablePermissions}
                    selectAll={actions.studyInvitePermissionsToggleAll}
                    permissionToggle={actions.studyInvitePermissionsToggle}
                  />
                </Scrollbars>
                <div className={classes.relative}>
                  <Button
                    id="inviteBack"
                    className={classes.buttonWrapperBack}
                    onClick={actions.studyInviteShowUsers}
                  >
                    Back
                  </Button>
                  <Button
                    id="inviteSend"
                    className={classes.buttonWrapper}
                    disabled={loading || !hasPermissions()}
                    onClick={this.handleClick}
                  >
                    Send
                  </Button>
                </div>


              </div>
            )}
            {step === 2 && <div className={classes.content}>
              {loading && (
                <CircularProgress
                  className={classes.spinner}
                  size={48}
                />
              )}
              {!loading &&
                <CheckCircleIcon className={classes.icon} />
              }
              {!loading && <p className={classes.succesLabel}>Invite sent to {userEmail}</p>}
            </div>}

          </div>
        </DialogContent>
      </Dialog>
    );
  }
}

StudyInviteUser.propTypes = {
  classes: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired
};

export default withRouter(withStyles(styles)(StudyInviteUser));
