export const STUDY_FILE_TOGGLED = 'STUDY_FILE_TOGGLED';
export const STUDY_ALL_FILES_TOGGLED = 'STUDY_ALL_FILES_TOGGLED';
export const STUDY_ALL_SAS_FILES_TOGGLED = 'STUDY_ALL_SAS_FILES_TOGGLED'

export const STUDY_PERMISSION_TOGGLED = 'STUDY_PERMISSION_TOGGLED';
export const STUDY_PERMISSIONS_CHANGED = 'STUDY_PERMISSIONS_CHANGED';

export const STUDY_UPLOAD_FILES = 'STUDY_UPLOAD_FILES';
export const STUDY_UPLOAD_FILES_V2 = 'STUDY_UPLOAD_FILES_V2';
export const STUDY_DOWNLOAD_FILES = 'STUDY_DOWNLOAD_FILES';
export const STUDY_DOWNLOAD_ALL_FILES = 'STUDY_DOWNLOAD_ALL_FILES';
export const STUDY_DELETE_ALL_FILES = 'STUDY_DELETE_ALL_FILES';
export const STUDY_COLLECT_ALL_FILES = 'STUYD_COLLECT_ALL_FILES'

export const STUDY_MIGRATE_SAS_FILES = 'STUDY_MIGRATE_SAS_FILES'

export const STUDY_DOWNLOAD_ALL_SAS_FILES = 'STUDY_DOWNLOAD_ALL_SAS_FILES'
export const STUDY_RUN_ALL_SAS_FILES = 'STUDY_RUN_ALL_SAS_FILES'

export const STUDY_PREVIEW_FILE = 'STUDY_PREVIEW_FILE';
export const STUDY_PREVIEW_RECEIVE = 'STUDY_PREVIEW_RECEIVE';

export const STUDY_DELETE_FILES = 'STUDY_DELETE_FILES';

export const STUDY_FILE_UPLOADED = 'STUDY_FILE_UPLOADED';
export const STUDY_FILE_UPLOADED_RESET = 'STUDY_FILE_UPLOADED_RESET';

export const STUDY_FILES_CHANGE_DATE = 'STUDY_FILES_CHANGE_DATE';

export const STUDY_OPEN_UPLOAD_MODAL = 'STUDY_OPEN_UPLOAD_MODAL';
export const STUDY_CLOSE_UPLOAD_MODAL = 'STUDY_CLOSE_UPLOAD_MODAL';

export const STUDY_TOGGLE_FILE_TYPE = 'STUDY_TOGGLE_FILE_TYPE';
export const STUDY_SELECT_STUDY_FILE = 'STUDY_SELECT_STUDY_FILE';

export const STUDY_FILE_VERSIONS_RECEIVE = 'STUDY_FILE_VERSIONS_RECEIVE';
export const STUDY_RESET_STUDY_DETAIL = 'STUDY_RESET_STUDY_DETAIL';

export const STUDY_SAVE_PERMISSIONS = 'STUDY_SAVE_PERMISSIONS';

export const STUDY_FILE_STATUS_CHANGE = 'STUDY_FILE_STATUS_CHANGE';
export const STUDY_FILE_STATUS_CHANGED = 'STUDY_FILE_STATUS_CHANGED'

export const STUDY_FILE_TYPE_CHANGE = 'STUDY_FILE_TYPE_CHANGE';
export const STUDY_REQUEST_PERMISSION = 'STUDY_REQUEST_PERMISSION';

export const STUDY_TABLE_SORT = 'STUDY_TABLE_SORT';
export const STUDY_SAS_TABLE_SORT = 'STUDY_SAS_TABLE_SORT';
export const STUDY_CHANGE_FILTER = 'STUDY_CHANGE_FILTER';
export const STUDY_CHANGE_FILTER_INIT = 'STUDY_CHANGE_FILTER_INIT';
export const SAS_CHANGE_FILTER = 'SAS_CHANGE_FILTER'
//export const SAS_RUN_STATUS_CHANGE = 'SAS_RUN_STATUS_CHANGE'
export const SAS_JOB_TOGGLED = 'SAS_JOB_TOGGLED'

export const STUDY_PASTWEEKS_FILTER = 'STUDY_PASTWEEKS_FILTER';
export const STUDY_FILE_TYPE_FILTER = 'STUDY_FILE_TYPE_FILTER';
export const UPLOAD_CHANGE_FILTER = 'UPLOAD_CHANGE_FILTER'

export const STUDY_FILE_ADD_UPLOAD_NOTIFICATION = 'STUDY_FILE_ADD_UPLOAD_NOTIFICATION';
export const SAS_PRIORITY_CHANGE = 'SAS_PRIORITY_CHANGE';

export const LATEST_UPDATE_DATE = 'LATEST_UPDATE_DATE'
export const LATEST_UPDATE_DATE_RECEIVED = 'LATEST_UPDATE_DATE_RECEIVED'
export const LATEST_SAS_UPDATE_DATE = 'LATEST_SAS_UPDATE_DATE'
export const LATEST_SAS_UPDATE_DATE_RECEIVED = 'LATEST_SAS_UPDATE_DATE_RECEIVED'

export const STUDY_DELETE_ALL_VERSIONS = 'STUDY_DELETE_ALL_VERSIONS'
export const STUDY_SHARE_ALL_FILES = 'STUDY_SHARE_ALL_FILES';
export const STUDY_SHARE_ALL_SAS_FILES = 'STUDY_SHARE_ALL_SAS_FILES'
export const STUDY_ACCESS_REPORT = 'STUDY_ACCESS_REPORT'

export const STUDY_COPY_DIRECT_LINK = 'STUDY_COPY_DIRECT_LINK'

export const studyAccessReports = () => ({
    type: STUDY_ACCESS_REPORT,
    payload: {}
})

export const studyTableSort = prop => ({
    type: STUDY_TABLE_SORT,
    payload: { prop }
});

export const studyChangeFilter = (folder, page, numPrPage, value) => ({
    type: STUDY_CHANGE_FILTER,
    payload: { folder, page, numPrPage, value }
})

export const studyChangeFilterInit = (val) => ({
    type: STUDY_CHANGE_FILTER_INIT,
    payload: { val }
});

export const studyFileTypeFilter = (val, type = "filetype") => ({
    type: STUDY_FILE_TYPE_FILTER,
    payload: { val }
});

export const SasChangeFilter = (val, type) => ({
    type: SAS_CHANGE_FILTER,
    payload: { val, type }
});

export const UploadChangeFilter = (val, type = "folder") => ({
    type: UPLOAD_CHANGE_FILTER,
    payload: { val }
});

export const studyPastWeekFilter = (val, type = "changed") => ({
    type: STUDY_PASTWEEKS_FILTER,
    payload: { val }
});

export const studySasTableSort = prop => ({
    type: STUDY_SAS_TABLE_SORT,
    payload: { prop }
});


export const studyRequestPermission = permission => ({
    type: STUDY_REQUEST_PERMISSION,
    payload: { permission }
});

export const studyFileTypeChange = (id, value) => ({
    type: STUDY_FILE_TYPE_CHANGE,
    payload: { id, value }
});

export const studyFileStatusChange = (id, value) => ({
    type: STUDY_FILE_STATUS_CHANGE,
    payload: { id, value }
});

export const StudyFileStatusChanged = (id, value) => ({
    type: STUDY_FILE_STATUS_CHANGED,
    payload: { id, value }
})


export const sasFilePriorityChange = (id, value) => ({
    type: SAS_PRIORITY_CHANGE,
    payload: { id, value }
});

export const studySavePermissions = () => ({
    type: STUDY_SAVE_PERMISSIONS,
    payload: {}
});

export const resetStudyDetail = () => ({
    type: STUDY_RESET_STUDY_DETAIL,
    payload: {}
});
export const studyFileVersionsReceive = (files, id) => ({
    type: STUDY_FILE_VERSIONS_RECEIVE,
    payload: { files, id }
});
export const studySelectFile = id => ({
    type: STUDY_SELECT_STUDY_FILE,
    payload: { id }
});

// depreacted this?
export const studyToggleFileType = fileType => ({
    type: STUDY_TOGGLE_FILE_TYPE,
    payload: { fileType }
});

export const checkLatestUpdateDate = fileType => ({
    type: LATEST_UPDATE_DATE,
    payload: { fileType }
});

export const checkSasLatestUpdateDate = () => ({
    type: LATEST_SAS_UPDATE_DATE,
    payload: {}
});

export const LatestUpdateDateReceived = (datetime, fileType) => ({
    type: LATEST_UPDATE_DATE_RECEIVED,
    payload: { datetime, fileType }

})
export const LatestSasUpdateDateReceived = (datetime) => ({
    type: LATEST_SAS_UPDATE_DATE_RECEIVED,
    payload: { datetime }
})
export const studyOpenUploadModal = () => ({
    type: STUDY_OPEN_UPLOAD_MODAL,
    payload: {}
});
export const studyCloseUploadModal = () => ({
    type: STUDY_CLOSE_UPLOAD_MODAL,
    payload: {}
});

export const studyFileUploadedReset = () => ({
    type: STUDY_FILE_UPLOADED_RESET,
    payload: {}
});

export const updateUploadNotification = (folder) => ({
    type: STUDY_FILE_ADD_UPLOAD_NOTIFICATION,
    payload: { folder }
})

export const studyFileUploaded = () => ({
    type: STUDY_FILE_UPLOADED,
    payload: {}
});

//export const studyFilesChangeDate = date => ({
//    type: STUDY_FILES_CHANGE_DATE,
//    payload: { date }
//});

export const studyDownloadFiles = id => ({
    type: STUDY_DOWNLOAD_FILES,
    payload: { id }
});
export const studyDownloadAllFiles = (id = undefined) => ({
    type: STUDY_DOWNLOAD_ALL_FILES,
    payload: { id: id || undefined }
})
export const studyDeleteAllFiles = (id = undefined) => ({
    type: STUDY_DELETE_ALL_FILES,
    payload: { id: id || undefined }
})

export const studyShareAllFiles = (id = undefined) => ({
    type: STUDY_SHARE_ALL_FILES,
    payload: { id: id || undefined }
})

export const copyDirectLink = (folders) => ({
    type: STUDY_COPY_DIRECT_LINK,
    payload: { folders }
})

export const studyFileDeleteAllVersions = (id = undefined) => ({
    type: STUDY_DELETE_ALL_VERSIONS,
    payload: { id: id || undefined }
})

export const studyCollectAllFiles = (id = undefined) => ({
    type: STUDY_COLLECT_ALL_FILES,
    payload: { id: id || undefined }
})

export const studyMigrateFiles = () => ({
    type: STUDY_MIGRATE_SAS_FILES,
    payload: {}
})

export const studyDownloadAllSasFiles = (id = undefined) => ({
    type: STUDY_DOWNLOAD_ALL_SAS_FILES,
    payload: { id: id || undefined }
})

export const studyDownloadSasFile = (id, name, folder) => ({
    type: STUDY_DOWNLOAD_ALL_SAS_FILES,
    payload: { id, name, folder }
})

export const StudyRunAllSasFiles = (id = undefined) => ({
    type: STUDY_RUN_ALL_SAS_FILES,
    payload: { id: id || undefined }
})

export const StudySasShareAllSasFiles = (id = undefined) => ({
    type: STUDY_SHARE_ALL_SAS_FILES,
    payload: { id: id || undefined }

})

export const studyDeleteFiles = id => ({
    type: STUDY_DELETE_FILES,
    payload: { id }
});

export const studyUploadFiles = (files, contentType, folder) => ({
    type: STUDY_UPLOAD_FILES,
    payload: { files, contentType, folder }
});
export const studyUploadFilesV2 = (files) => ({
    type: STUDY_UPLOAD_FILES_V2,
    payload: { files }
});


export const studyFileToggled = id => ({
    type: STUDY_FILE_TOGGLED,
    payload: { id }
});

export const sasJobToggled = id => ({
    type: SAS_JOB_TOGGLED,
    payload: { id }
});

export const studyAllFilesToggles = (ids, fileType) => ({
    type: STUDY_ALL_FILES_TOGGLED,
    payload: { ids, fileType }
});

export const studyAllSASFilesToggles = (ids, fileType) => ({
    type: STUDY_ALL_SAS_FILES_TOGGLED,
    payload: { ids, fileType }
});

export const studyPermissionToggled = id => ({
    type: STUDY_PERMISSION_TOGGLED,
    payload: { id }
});
export const studyPermissionsChanged = (permissions, studyId) => ({
    type: STUDY_PERMISSIONS_CHANGED,
    payload: { permissions, studyId }
});

export const studyPreviewFile = file => ({
    type: STUDY_PREVIEW_FILE,
    payload: { file }
});

export const studyPreviewReceive = preview => ({
    type: STUDY_PREVIEW_RECEIVE,
    payload: preview
});