//import PayerFilters from "../pages/components/PayerFilters";

//http://www.vnykmshr.com/sort-array-of-objects-by-multiple-fields
export const resolveTableSort = (sortingArray, prop) => {

  if (sortingArray && sortingArray.length) {
    let list = [];
    if (!sortingArray.includes(prop)) {
      //list = sortingArray.filter(item => item !== prop); multiple items in sort?
      list = [prop, 'asc'];
    }
    else {
      if (sortingArray[1] === 'asc') {
        list = [prop, 'desc'];
      }
      else {
        list = [prop, 'asc'];
      }
    }

    return list;
  }
  return [prop, 'asc'];

}

export const resolvePayerFilter = (list, values) => {
  let newPayerFilter = []

  if (list && list.length) {
    if (!values.includes("header")) {
      //console.log(list)
      //console.log(values)
      newPayerFilter = list.map(item =>
        values.includes(item.name) ? { ...item, selected: true } : { ...item, selected: false }
      )
    }
    else {
      const allSelected = (list.length === list.filter(item => item.selected === true).length)
      newPayerFilter = list.map(item =>
        (!allSelected) ? { ...item, selected: true } : { ...item, selected: false }
      )
    }
  }
  return newPayerFilter
}

export const resatPayerFilter = (PayerFilter) => {
  let newPayerFilter = []
  if (PayerFilter) {
    Object.keys(PayerFilter).map(filter => /*{*/
      newPayerFilter[filter] = PayerFilter[filter].map(item =>
        (item.selected === true) ? { ...item, selected: false } : item
      )
    /*}*/)
  }
  return newPayerFilter
}

export const resolveRadioFilter = (list, prop) => {
  let disable = false
  if (list && list.length > 0) {
    if (list.includes(prop)) {
      disable = true
    }
  }
  return (disable) ? [] : [prop]
}

export const resolveFilter = (list, prop) => {
  if (list && list.length > 0) {
    let filters = [];
    //console.log(list)
    if (list.includes(prop)) {
      filters = list.filter(item => item !== prop); //multiple items in sort?
    }
    else {
      filters = list.concat(prop);

    }
    //console.log(filters)
    return filters;
  }
  return [prop];
}
export const resolvePastFilter = (list, prop) => {
  if (list && list.length > 0) {
    let filters = [];
    if (list.includes(prop)) {
      filters = list.filter(item => item !== prop);
    }
    else {
      filters = [prop];
    }
    return filters;
  }
  return [prop];
}


export const resolveNotification = (list, prop) => {
  if (list && list.length) {
    let notifications = [];
    if (list.includes(prop)) {
      notifications = list;
    }
    else {
      notifications = list.concat(prop);
    }
    return notifications;
  }
  return [prop];
}



export function predicate() {
  let fields = [],
    n_fields = arguments.length,
    field, name/*, reverse*/, cmp;

  let default_cmp = function (a, b) {
    if (a === b) return 0;
    return a < b ? -1 : 1;
  },
    getCmpFunc = function (primer, reverse) {
      let dfc = default_cmp,
        // closer in scope
        cmp = default_cmp;
      if (primer) {
        cmp = function (a, b) {
          return dfc(primer(a), primer(b));
        };
      }
      if (reverse) {
        return function (a, b) {
          return -1 * cmp(a, b);
        };
      }
      return cmp;
    };

  // preprocess sorting options
  for (let i = 0; i < n_fields; i++) {
    field = arguments[i];
    if (typeof field === 'string') {
      name = field;
      cmp = default_cmp;
    } else {
      name = field.name;
      cmp = getCmpFunc(field.primer, field.reverse);
    }
    fields.push({
      name: name,
      cmp: cmp
    });
  }

  // final comparison function
  return function (A, B) {
    let /*a, b,*/ name, result;
    for (let i = 0; i < n_fields; i++) {
      result = 0;
      field = fields[i];
      name = field.name;

      result = field.cmp(A[name], B[name]);
      if (result !== 0) break;
    }
    return result;
  };
}