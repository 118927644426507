
import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import Checkbox from "@material-ui/core/Checkbox";
import Paper from "@material-ui/core/Paper";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import classNames from "classnames";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
//import IndeterminateCheckBoxIcon from '@material-ui/icons/IndeterminateCheckBox';
// https://reacttraining.com/react-router/web/example/auth-workflow basic no redux
// width: 200
//https://stackoverflow.com/questions/47804380/styling-the-placeholder-in-a-textfield
// define sm mediaqueri


const styles = theme => ({
  root: {
    width: '100%',
    position: 'relative',
    boxShadow: 'none',
    borderRadius: '10px',
  },
  table: {
    fontFamily: theme.typography.fontFamily
  },

  tableHeader: {
    background: "white",
    color: "black",
    "&:focus": {
      outline: 0
    },
    fontSize: "0.5rem",
    textTransform: 'uppercase',
    borderRadius: '8px',
  },
  tableCellHeader: {
    fontSize: '0.5rem',
    color: 'black',
    padding: '0 1rem',
    textTransform: 'uppercase',
    borderTopLeftRadius: '8px',
    borderTopRightRadius: '8px',
    border: '1px'
  },
  rowHead: {
    height: '48px',

  },
  tableCellBody: {
    fontSize: '0.6rem',
    padding: '0 1rem',
  },
  tableCellDisabled: {
    color: "rgba(0, 0, 0, 0.54)"
  },
  row: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default,
    },
    padding: '0 1rem',
    height: '48px',
    borderBottom: '1px solid rgba(224, 224, 224, 0.5)',
  },
  checkBoxRoot: {
    color: theme.palette.primary.light,
    '&$checked': {
      color: theme.palette.primary.light,
    },
  },
  checkBox: {
    color: theme.palette.primary.light,
    '&$checked': {
      color: theme.palette.primary.light,
    },
  },
  checked: {}
});
//const styles = tableStyles;
class PermissionsTable extends React.PureComponent {


  render() {
    const {
      classes,
      permissionToggle,
      /*selectAll,*/
      permissions,
    } = this.props;
    /*
    const numSelected = () => {
      let cnt = 0;
      permissions.forEach(item => {
        if (item.selected) {
          cnt++;
        }
      });
      return cnt;
    }
    
    const getIndeterminateState = () => {
      const cnt = numSelected();
      if (cnt > 0 && cnt < permissions.length) {
        return true;
      }
      return false;

    }
    
    const getAllSelected = () => {
      const cnt = numSelected();
      return cnt === permissions.length;
    }*/

    return (
      <div style={{ border: '1px solid #e0dddd', borderRadius: '8px' }}>
        <Paper className={classes.root}>
          <Table className={classes.table}>
            <TableHead className={classes.tableHeader}>
              <TableRow className={classes.rowHead}>
                <TableCell className={classes.tableCellHeader}>Permission</TableCell>
                <TableCell className={classes.tableCellHeader} align="right">Description</TableCell>
                <TableCell className={classes.tableCellHeader} align="right">
                  {/*<Checkbox
                    color="secondary"
                    indeterminate={getIndeterminateState()}
                    checked={getAllSelected()}
                    disabled={false}
                    onChange={() => selectAll()}
                    classes={{
                      root: classes.checkBoxRoot,
                      checked: classes.checked,
                    }}
                    icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                    checkedIcon={<CheckBoxIcon fontSize="small" />}
                    indeterminateIcon={<IndeterminateCheckBoxIcon fontSize="small" />}
                  />*/}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {permissions.map(row => (
                <TableRow key={row.permission} className={classes.row}>
                  <TableCell className={row.disabled ? classNames(classes.tableCellBody, classes.tableCellDisabled) : classes.tableCellBody} component="th" scope="row">
                    {row.permission}
                  </TableCell>
                  <TableCell className={row.disabled ? classNames(classes.tableCellBody, classes.tableCellDisabled) : classes.tableCellBody} align="right">{row.description}</TableCell>
                  <TableCell className={classes.tableCellBody} align="right">
                    <Checkbox
                      id={row.permission}
                      indeterminate={false}
                      checked={row.selected}
                      disabled={/*false*/ row.disabled}
                      onChange={() => permissionToggle(row.permission)}
                      classes={{
                        root: classes.checkBox,
                        checked: classes.checked,
                      }}
                      icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                      checkedIcon={<CheckBoxIcon fontSize="small" />}

                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>
      </div>
    );
  }
}

PermissionsTable.propTypes = {
  classes: PropTypes.object.isRequired,
  permissionToggle: PropTypes.func,
  permissions: PropTypes.array
};

export default (withStyles(styles)(PermissionsTable));
