
import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Step from '@material-ui/core/Step';
import Stepper from '@material-ui/core/Stepper';
import StepLabel from '@material-ui/core/StepLabel';

const styles = theme => ({
  root: {
    width: '118%',
    padding: 0,
    fontSize: 10,
    position: 'relative',
    left: '-3.3rem',
    top: '1.5rem',
    background: 'transparent',
    backgroundColor: 'transparent'
  },
  label: {
    fontSize: 10
  },
  bg: {
    backgroundColor: 'transparent',
    padding: 0
  },
  ...theme.typography
  
});

export const getSteps = () => {
  return ['Add recepient e-mail', 'Assign permissions', 'Send invite'];
}

class HorizontalLabelPositionBelowStepper extends React.PureComponent {
  render() {
    const { classes, activeStep } = this.props;
    const steps = getSteps();
    return (
      <div className={classes.root}>
        <Stepper activeStep={activeStep} alternativeLabel className={classes.bg}>
          {steps.map(label => (
            <Step key={label} className={classes.label}>
              <StepLabel className={classes.label}>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </div>
    );
  }
}

HorizontalLabelPositionBelowStepper.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(styles)(HorizontalLabelPositionBelowStepper);