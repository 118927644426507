import React from "react";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/";
import CircularProgress from "@material-ui/core/CircularProgress";
import classNames from "classnames";
import loginStyles from "./LoginStyles";
import Button from "../ui/Button";
import BasicTextField from "../ui/BasicTextField";
const styles = loginStyles;

class VerifyAttribute extends React.PureComponent {
  state = { verificationCode: '' }

  resolveError = type => {
    const { errorMessage } = this.props;

    if (errorMessage && errorMessage.length && errorMessage.includes(type)) {
      return errorMessage;
    }
  };

  handleKeyPress = e => {
    const { actions, loading, type } = this.props;
    const { verificationCode } = this.state

    if (!loading) {
      if (e.key === "Enter") {
        e.preventDefault();
        if (type === "phoneNumber") {
          actions.userProfileVerifyPhoneNumber(verificationCode)
        }
        else if (type === "email") {
          actions.userProfileVerifyEmail(verificationCode)
        }
      }
    }
  };

  userUpdateVerificationCode = (value) => {
    this.setState({
      verificationCode: value
    })
  }

  handleSubmitBtn = () => {
    const {
      loading,
      actions,
      type,
    } = this.props;

    const { verificationCode } = this.state

    if (!loading) {
      if (type === "phoneNumber") {
        actions.userProfileVerifyPhoneNumber(verificationCode)
      }
      else if (type === "email") {
        actions.userProfileVerifyEmail(verificationCode)
      }
    }
  };

  render() {
    const {
      classes,
      loading,
      phone,
      email,
      type,
    } = this.props;
    const { verificationCode } = this.state

    return (
      <div className={classes.base}>
        <Grid container direction="column">
          {/* Information */}
          <Grid item>
            {type === "phoneNumber" &&
              <p style={{ marginLeft: "0.5rem" }} className={classes.innerText}>
                Enter the verification code sent to this phone number: {phone}
              </p>
            }
            {type === "email" &&
              <p style={{ marginLeft: "0.5rem" }} className={classes.innerText}>
                Enter the verification code sent to this email: {email}
              </p>
            }
          </Grid>
          {/* Verification code field*/}
          <Grid item>
            <form autoComplete="off" onKeyPress={this.handleKeyPress}>
              <BasicTextField
                placeholder={"VerificationCode"}
                id="verificationCode"
                label="Verification code"
                classes={classes}
                error={this.resolveError("verification")}
                value={verificationCode}
                onChange={this.userUpdateVerificationCode}
              />
            </form>
          </Grid>
          {/* submit button */}
          <Grid item className={classes.base}>
            <Grid item className={classes.lastElm}>
              <div className={classes.wrapperCta}>
                <Button
                  id="verifyCodeSubmit"
                  disabled={loading}
                  className={classNames(
                    classes.buttonWrapper,
                    classes.clearTop
                  )}
                  onClick={this.handleSubmitBtn}
                >
                  Verify
                  {loading && (
                    <CircularProgress
                      size={24}
                      className={classes.buttonProgress}
                    />
                  )}
                </Button>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }
}

VerifyAttribute.propTypes = {
  classes: PropTypes.object.isRequired,
  phone: PropTypes.string,
  loading: PropTypes.bool,
  email: PropTypes.string,
  type: PropTypes.string
};

export default withStyles(styles)(VerifyAttribute);
